export interface TasksSummary {
  groups: Task[];
  tasks: Task[];
  todoCount: TasksTodoCount;
}

export const isTasksSummary = (value: unknown): value is TasksSummary => {
  return (value as TasksSummary).tasks !== undefined && (value as TasksSummary).todoCount !== undefined && (value as TasksSummary).groups !== undefined;
};

export interface Task {
  action: string;
  actionKey: number;
  description: string;
  due: string;
  id: number;
  links: TaskLink[];
  organisationId: number;
  priority: string;
  reminderSent: boolean;
  sequentialId: number;
  source: string;
  start: string;
  status: string;
  timeRemaining: string;
}

export interface TaskLink {
  rel: string;
  href: string;
}

export interface TasksTodoCount {
  tasksInProgress: number;
  tasksNotStarted: number;
  tasksOverdueAndInProgress: number;
  tasksOverdueAndNotStarted: number;
}

export enum TaskStatus {
  InProgress = 'In Progress',
  NotStarted = 'Not Started',
  OverdueInProgress = 'Overdue (In Progress)',
  OverdueNotStarted = 'Overdue (Not Started)',
}

export const taskOverdueStates = [
  TaskStatus.OverdueInProgress.toString(),
  TaskStatus.OverdueNotStarted.toString(),
];
