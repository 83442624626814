import { RestError } from "@surecloud/api-interfaces";
import { isEmpty } from '@surecloud/utils';
import { DEFAULT_INFO, DEFAULT_MSG } from "../error-constants";

export function createMessageFromError(error: RestError): string {
  let message = DEFAULT_MSG;
  let info = DEFAULT_INFO;
  if (error) {
    message = isEmpty(error.message) ? message : error.message;
    info = isEmpty(error.moreInfo) ? info : error.moreInfo;
  }
  return makeHtmlMessage(message, info);
}

export function makeHtmlMessage(message: string, info: string): string {
  return `<p><strong>${message}</strong><br>${info}</p>`;
}