// @ts-nocheck
/* istanbul ignore file */
// UI Project Dependents: oCompliance.ts, oTabs.ts, surecloud.chart.ts
import { injectToolbarEl, saveToolbarState, TOOLBAR_PAGINATION, TOOLBAR_PAGINATION_ID, TOOLBAR_TOOLS, TOOLBAR_TOOLS_ID } from './toolbar-helper';
import { LegacyLogout, getLocalToken } from '@surecloud/utils';
import { formFocusTracker } from '../modules/focus-tracker';

/* ---------------------------------------- Ajax Object */
export function oAjax() {
  this.queue = [];
}

oAjax.prototype.request = function (
  request,
  responseFunction: any,
  ignoreActive,
  followOnAction
) {
  if (this.active) {
    alert("Please wait, a request is currently being processed.");
    return;
  }
  var followOnActionInner = followOnAction;
  if (!ignoreActive) this.active = true;
  document.body.style.cursor = "wait";
  if (this.start) this.start(true);
  var http = this.createRequestObject(),
    ajax = this;

  function handleResponse() {
    if (http.readyState === 4) {
      if (http.status === 401) {
        // hand control to the angular tokenService
        const legacyLogout = LegacyLogout.getInstance();
        legacyLogout.logout();
        return;
      }
      if (http.status === 200) {
        try {
          ajax.active = false;
          document.body.style.cursor = "auto";
          if (ajax.start) ajax.start(false);
          if (ajax.debug)
            get("debug").innerHTML = http.responseText
              .replace(/\\/g, "\\\\")
              .replace(/\'/g, "\\'")
              .replace(/&amp;/g, "&amp;amp;")
              .replace(/&lt;/g, "&amp;lt;")
              .replace(/&gt;/g, "&amp;gt;")
              .replace(/</g, "&lt;")
              .replace(/>/g, "&gt;")
              .replace(/&quot;/g, "&amp;quot;");
          var responseText = http.responseText.substr(
              http.responseText.indexOf("<json><![CDATA[") + 15
            ),
            jsonString = responseText.substring(0, responseText.indexOf("]]>"));
          var json;
          try {
            json = eval("(" + jsonString + ")");
          } catch (ej) {
            throw (
              "Error: " + ej.toString() + "\nWhile evaluating: " + jsonString
            );
          }
          // @ts-ignore
          if (json.exception === "AJAX") window.onerror("AJAX");
          let $paginateEl;
          let $toolsEl;
          let toAssignShared;
          while (responseText.indexOf('<html assign="') > -1) {
            responseText = responseText.substring(
              responseText.indexOf('<html assign="') + 14
            );
            var toAssign = responseText.substring(0, responseText.indexOf('"'));
            responseText = responseText.substring(
              responseText.indexOf('"><![CDATA[') + 11
            );
            toAssignShared = toAssign;
            var html = responseText.substring(
              0,
              responseText.indexOf("]]></html")
            );
            if (toAssign === TOOLBAR_PAGINATION) {
              $paginateEl = $($.parseHTML(html));
            }
            else if (toAssign === TOOLBAR_TOOLS) {
              $toolsEl = $($.parseHTML(html));
            }
            else {
              eval("(json." + toAssign + "=html)");
              responseText = responseText.substring(
                responseText.indexOf("]]></html>") + 10
              );
            }
          }

          // Load Form (json.responseFunction)
          if (json.responseFunction) {
            json.responseFunction(json);
            if (formFocusTracker) formFocusTracker.reFocusStashedElement();
            // loads new content...
          }
          else if (responseFunction) responseFunction(json);
          else if (oAjax.responseFunction) oAjax.responseFunction(json);
          if (json.tabsFunction) {
            if (typeof json.tabsFunction === "function") {
              json.tabsFunction();
            }
            else {
              eval(json.tabsFunction);
            }
          }

          if (!ignoreActive) ajax.active = false;
          if (json.breadCrumbTitle) {
            tabs.breadCrumb.displayAlt = $("<textarea />")
              .html(json.breadCrumbTitle)
              .text();
            if (tabs.breadCrumb.element) {
              tabs.breadCrumb.element.text(tabs.breadCrumb.displayAlt);
            }
          }

          if (json.href) {
            tabs.breadCrumb.href = json.href;
            if (tabs.breadCrumb.element) {
              tabs.breadCrumb.element.attr("schref", tabs.breadCrumb.href);
            }
          }

          const shouldUpdateToolbar = toAssignShared !== undefined && !json.popup;
          if (shouldUpdateToolbar) {
            const selectedBreadCrumb = tabs.breadCrumb.subTab ? tabs.breadCrumb.subTab : tabs.breadCrumb;
            saveToolbarState(selectedBreadCrumb, { left: $toolsEl, right: $paginateEl });
            injectToolbarEl(selectedBreadCrumb);
          }

          if (json.widgetName) {
            var $pane = json.widgetOptions
              ? $("#" + json.widgetOptions.pane)
              : $("#" + tabs.breadCrumb.arguments.pane);
            var toolbar = {
              left: $(`#${TOOLBAR_TOOLS_ID}`).find('ul'),
              right: $(`#${TOOLBAR_PAGINATION_ID}`).find('ul'),
            };
            var widgetOptions = $.extend({}, json.widgetOptions, {
              toolbar: toolbar,
              breadCrumb: tabs.breadCrumb,
            });
            var $widget = $("<div/>")[json.widgetName](widgetOptions);
            $pane.find("div.table").append($widget);
          }

          if (json.disableReload !== undefined && tabs.breadCrumb) {
            if (tabs.breadCrumb.subTab) {
              tabs.breadCrumb.subTab.disableReload = json.disableReload;
            }
            else {
              tabs.breadCrumb.disableReload = json.disableReload;
            }
          }

          if (followOnActionInner) {
            popup.hide(true);
            followOnActionInner.call(tabs.breadCrumb, tabs.breadCrumb);
          }
          else if (ajax.queue && ajax.queue.length > 0) {
            ajax.queue.shift().call();
          }
        } catch (e) {
          // @ts-ignore
          window.onerror(e);
        }
      }
    }
  }

  // @ts-ignore
  var content = "json=" + encodeURIComponent(oAjax.Json.stringify(request));
  http.onreadystatechange = handleResponse;
  //http.open('POST','ajax' + (this.jsessionid && document.cookie.toUpperCase().indexOf('JSESSIONID')==-1?';jsessionid=' + this.jsessionid:''));
  http.open("POST", "ajax");
  http.setRequestHeader(
    "Content-type",
    "application/x-www-form-urlencoded; charset=utf-8"
  );
  http.setRequestHeader("X-scTOffset", new Date().getTimezoneOffset());
  ;
  http.setRequestHeader("X-csrf", getLocalToken());
  http.setRequestHeader(
    "requestGUID",
    String(new Date().getTime() + Math.random())
  );
  http.send(content);
  if (sc_notice) {
    sc_notice.check();
  }
  return true;
};

oAjax.prototype.createRequestObject = function () {
  try {
    return new ActiveXObject("Msxml2.XMLHTTP");
  } catch (e) {
    try {
      return new ActiveXObject("Microsoft.XMLHTTP");
    } catch (e) {
      return new XMLHttpRequest();
    }
  }
};

/* ---------------------------------------- Catch any javascript error and report back Via Ajax */
window.onerror = function (error: any, file, line) {
  if (exception === "ResizeObserver loop limit exceeded") {
    console.log("ResizeObserver loop limit exceeded");
    return;
  }

  if (!ajax) {
    return;
  }

  ajax.alerts = ajax.alerts ? ajax.alerts + 1 : 1;
  if (ajax.alerts > 30) return;
  var message = "Sorry, there has been an error on this page. ";
  try {
    var jse = error.toString();
    if (error.stack) {
      jse += "\n\nStack Trace: " + error.stack;
    }
    if (error.error && error.error.stack) {
      jse += "\n\nStack Trace: " + error.error.stack;
    }

    if (error.fileName) {
      jse += "\n\nFile Name: " + error.fileName;
    }
    if (error.filename) {
      jse += "\n\nFile Name: " + error.filename;
    }
    if (error.lineNumber) {
      jse += "\nLine Number: " + error.lineNumber;
    }
    if (error.lineno) {
      jse += "\nLine Number: " + error.lineno;
    }
    if (error.columnNumber) {
      jse += "\nColumn Number: " + error.columnNumber;
    }
    if (error.colno) {
      jse += "\nColumn Number: " + error.colno;
    }
    if (error.message) {
      jse += "\nMessage: " + error.message;
    }
    if (error.name) {
      jse += "\nName: " + error.name;
    }
    if (navigator && navigator.userAgent) {
      jse += "\nUser Agent: " + navigator.userAgent;
    }
    // @ts-ignore
    if (error !== "AJAX")
      ajax.ajax(
        "&error=javascript&javascriptError=" +
        encodeURIComponent(jse) +
        "&file=" +
        encodeURIComponent(file) +
        "&line=" +
        line
      );
    message +=
      "An administrator has been notified. Please try to continue. " +
      "If the same problem persists, you will need to wait for us to address the issue. ";
  } catch (err) {
    message +=
      "If the problem persists, please let us know and we will try and rectify the situation as quickly as possible. ";
  }
  if (error !== "AJAX")
    message +=
      "Once again, sorry for any inconvenience.\n\nError: " +
      error +
      "\nFile: " +
      file +
      "\nLine: " +
      line;
  alert(message);
  return true;
};

/* ---------------------------------------- JSON Helper Object */
oAjax.Json = {
  stringify: function stringify(arg: any) {
    var c,
      i,
      l,
      s = "",
      v;
    switch (typeof arg) {
      case "object":
        if (arg) {
          if (arg.constructor === Array) {
            for (i = 0; i < arg.length; ++i) {
              v = stringify(arg[i]);
              if (s) s += ",";
              s += v;
            }
            return "[" + s + "]";
          }
          else if (typeof arg.toString !== "undefined") {
            for (i in arg) {
              v = stringify(arg[i]);
              if (typeof v !== "function") {
                if (s) s += ",";
                s += stringify(i) + ":" + v;
              }
            }
            return "{" + s + "}";
          }
        }
        return "null";
      case "number":
        return isFinite(arg) ? String(arg) : "null";
      case "string":
        l = arg.length;
        s = '"';
        for (i = 0; i < l; i += 1) {
          c = arg.charAt(i);
          if (c >= " ") {
            if (c === "\\" || c === '"') s += "\\";
            s += c;
          }
          else {
            switch (c) {
              case "\b":
                s += "\\b";
                break;
              case "\f":
                s += "\\f";
                break;
              case "\n":
                s += "\\n";
                break;
              case "\r":
                s += "\\r";
                break;
              case "\t":
                s += "\\t";
                break;
              default:
                // @ts-ignore
                c = c.charCodeAt();
                s +=
                  "\\u00" +
                  Math.floor(c / 16).toString(16) +
                  (c % 16).toString(16);
            }
          }
        }
        return s + '"';
      case "boolean":
        return String(arg);
      default:
        return "null";
    }
  },
  parse: function (text: any) {
    var at = 0;
    var ch: any = " ";

    function error(m: any) {
      throw {
        name: "JSONError",
        message: m,
        at: at - 1,
        text: text,
      };
    }

    function next() {
      ch = text.charAt(at);
      at += 1;
      return ch;
    }

    function white() {
      while (ch) {
        if (ch <= " ") next();
        else if (ch === "/") {
          switch (next()) {
            case "/":
              while (next() && ch !== "\n" && ch !== "\r") {
                /* empty */
              }
              break;
            case "*":
              next();
              for (; ;) {
                if (ch) {
                  if (ch === "*") {
                    if (next() === "/") {
                      next();
                      break;
                    }
                  }
                  else next();
                }
                else error("Unterminated comment");
              }
              break;
            default:
              error("Syntax error");
          }
        }
        else break;
      }
    }

    // @ts-ignore
    function string() {
      var i,
        s = "",
        t,
        u;
      if (ch === '"') {
        outer: while (next()) {
          if (ch === '"') {
            next();
            return s;
          }
          else if (ch === "\\") {
            switch (next()) {
              case "b":
                s += "\b";
                break;
              case "f":
                s += "\f";
                break;
              case "n":
                s += "\n";
                break;
              case "r":
                s += "\r";
                break;
              case "t":
                s += "\t";
                break;
              case "u":
                u = 0;
                for (i = 0; i < 4; i += 1) {
                  t = parseInt(next(), 16);
                  if (!isFinite(t)) {
                    break outer;
                  }
                  u = u * 16 + t;
                }
                s += String.fromCharCode(u);
                break;
              default:
                s += ch;
            }
          }
          else s += ch;
        }
      }
      error("Bad string");
    }

    // @ts-ignore
    function array() {
      var a: any[] = [];
      if (ch === "[") {
        next();
        white();
        if (ch === "]") {
          next();
          return a;
        }
        while (ch) {
          a.push(value());
          white();
          if (ch === "]") {
            next();
            return a;
          }
          else if (ch !== ",") break;
          next();
          white();
        }
      }
      error("Bad array");
    }

    function object() {
      var k: string = "",
        o: any = {};
      if (ch === "{") {
        next();
        white();
        if (ch === "}") {
          next();
          return o;
        }
        while (ch) {
          // @ts-ignore
          k = string();
          white();
          if (ch !== ":") break;
          next();
          o[k] = value();
          white();
          if (ch === "}") {
            next();
            return o;
          }
          else if (ch !== ",") break;
          next();
          white();
        }
      }
      error("Bad object");
    }

    function number(): any {
      var n = "",
        v;
      if (ch === "-") {
        n = "-";
        next();
      }
      while (ch >= "0" && ch <= "9") {
        n += ch;
        next();
      }
      if (ch === ".") {
        n += ".";
        while (next() && ch >= "0" && ch <= "9") {
          n += ch;
        }
      }
      v = +n;
      if (!isFinite(v)) {
        error("Bad number");
      }
      else return v;
    }

    function word(): any {
      switch (ch) {
        case "t":
          if (next() === "r" && next() === "u" && next() === "e") {
            next();
            return true;
          }
          break;
        case "f":
          if (
            next() === "a" &&
            next() === "l" &&
            next() === "s" &&
            next() === "e"
          ) {
            next();
            return false;
          }
          break;
        case "n":
          if (next() === "u" && next() === "l" && next() === "l") {
            next();
            return null;
          }
          break;
      }
      error("Syntax error");
    }

    function value(): any {
      white();
      switch (ch) {
        case "{":
          return object();
        case "[":
          return array();
        case '"':
          return string();
        case "-":
          return number();
        default:
          return ch >= "0" && ch <= "9" ? number() : word();
      }
    }

    return value();
  },
};

$.ajaxSetup({
  beforeSend: function (xhr: any) {
    xhr.setRequestHeader(
      "requestGUID",
      String(new Date().getTime() + Math.random())
    );
    xhr.setRequestHeader("X-scTOffset", new Date().getTimezoneOffset());
    xhr.setRequestHeader("X-csrf", getLocalToken());
  },
  error: function (xhr) {
    if (xhr.status === 401) {
      // hand control to the angular tokenService
      const legacyLogout = LegacyLogout.getInstance();
      legacyLogout.logout();
      return;
    }
  },
});


/* ---------------------------------------- Add some debug to Ajax */
oAjax.responseFunction = function (json) {
  if (ajax.debug)
    oSureCloud.debug(
      JSON.stringify(json)
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/\\\"/g, '"')
    );
};

oAjax.prototype.start = function (started) {
  //get('loading').style.width='100%';get('loading').style.height='100%';
  //var h = document.height(), w = document.width();
  //get('loading').css({'height':h,'width':w});
  //alert(get('loading').className)
  if (!(!started && get("loading").className == "loadingPopup"))
    get("loading").style.display = started ? "block" : "none";
  get("loading").start = new Date().getTime();
  if (started) {
    $("#ei-loading").addClass("loading-contents");
  }
  else {
    $("#ei-loading").removeClass("loading-contents");
  }
  //if (get('refreshIcon')) {
  //    get('refreshIcon').src = 'images/icons/' + (started ? 'refreshLoader.gif' : 'refresh_new.png');
  //    get('refreshIcon').title = 'Refresh page.';
  //}
};
