<div
  (click)="onContainerClicked($event)"
  class="modal fade fgp"
  tabindex="-1"
  [ngClass]="{ in: visibleAnimate }"
  [ngStyle]="{
    display: visible ? 'block' : 'none',
    opacity: visibleAnimate ? 1 : 0
  }"
>
  <ng-content select=".app-modal-content"></ng-content>
</div>
