import { Component, ElementRef, HostListener, OnInit } from '@angular/core';

declare var $: any; // JQuery

@Component({
  selector: 'error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent implements OnInit {
  @HostListener('window:resize')
  onWindowResize() {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(
      (() => {
        if (this.modalContent) {
          this.modalContent.resizable(
            'option',
            'maxWidth',
            window.innerWidth - 10
          );
          this.modalContent.resizable(
            'option',
            'maxHeight',
            window.innerHeight - 10
          );
        }
      }).bind(this),
      100
    );
  }

  resizeTimeout: any;
  modalContent: any;
  public visible = false;
  public visibleAnimate = false;
  private resizing: boolean = false;

  constructor(
    private elRef: ElementRef,
    // public context: ContextService,
    // private dashboardsContext: DashboardsContext
  ) {
  }

  public show(): void {
    // setTimeout(() => (this.context.menuModalOpen = false));
    setTimeout(() => (this.visible = true));
    // setTimeout(() => (this.context.modalOpen = true));
    setTimeout(() => {
      this.visibleAnimate = true;
      this.addDragableResiazble();
    }, 100);
    setTimeout(() => {
      this.addDragableResiazble();
    }, 1000); // for direct load
    // this.dashboardsContext.modalOpened.next(true);
  }

  public hide(): void {
    this.visibleAnimate = false;
    this.removeDragable();

    // setTimeout(() => (this.context.modalOpen = false));
    setTimeout(() => (this.visible = false), 300);
  }

  public onContainerClicked(event: MouseEvent): void {
    if ((<HTMLElement>event.target).classList.contains('modal')) {
      if (!this.resizing) {
        // this.dashboardsContext.modalClosed.next(true);
      }
    }
  }

  addDragableResiazble() {
    let modalEl = this.elRef.nativeElement.querySelector('.modal-dialog');
    let $modalEl = $(modalEl);
    var self = this;

    $modalEl
      .position({
        my: 'center',
        at: 'center',
        of: window,
      })
      .draggable({
        handle: '.modal-header',
        containment: 'window',
        scroll: false,
      });

    this.modalContent = $modalEl.find('.modal-content');
    this.modalContent.resizable({
      minHeight: 250,
      minWidth: 450,
      maxHeight: window.innerHeight - 10,
      maxWidth: window.innerWidth - 10,

      start: function (event: any, ui: any) {
        self.resizing = true;
      },
      stop: function (event: any, ui: any) {
        setTimeout(() => {
          self.resizing = false;
        }, 100);
      },
    });
    let h = this.modalContent.outerHeight();
    if (h > 100 && this.modalContent.find('.acc-filter').length) {
      // direct load fix
      this.modalContent.height(h);
    }
  }

  removeDragable() {
    //this.elRef.nativeElement.querySelector('.modal-dialog');
  }

  ngOnInit() {
  }
}
