import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { ServerLocation } from '@surecloud/api-interfaces';
import { LocationsService, OrganisationsService } from '@surecloud/core-data';
import { getPrimaryPathName } from '@surecloud/utils';
import { Observable, of } from 'rxjs';
import { filter, map, shareReplay, startWith, withLatestFrom } from 'rxjs/operators';
import { ROUTE_NAMES } from './constants';
import { InlineLink } from './components/inline-links/inline-links.component';

interface UIServerLocation extends ServerLocation {
  classes: string;
}

const FEATURED_LINK = 'https://www.surecloud.com/capabilities';
const COOKIES_LINK = 'https://www.surecloud.com/surecloud-platform-cookies/';
const PRIVACY_LINK = 'https://www.surecloud.com/privacy-statement/';

@Component({
  selector: 'login-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  currentTitle = 'Login | SureCloud'
  logoSrc: string;
  hasBranding$: Observable<boolean>;
  locations$: Observable<UIServerLocation[]>;
  location$: Observable<ServerLocation | undefined>;
  locationLinks$: Observable<InlineLink[]>;
  today: number = Date.now();
  routePath$: Observable<string>;
  links = {
    featured: FEATURED_LINK,
    privacy: PRIVACY_LINK,
    cookies: COOKIES_LINK,
  };
  isLogin$: Observable<boolean>;
  isReveal$: Observable<boolean>;

  constructor(
    private titleService: Title,
    private orgsService: OrganisationsService,
    private locationService: LocationsService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle(this.currentTitle);
    this.hasBranding$ = this.orgsService.getHasBranding().pipe(shareReplay(1));
    this.logoSrc = this.orgsService.getLogoSrc();
    this.loadLocations();
    this.routePath$ = this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => getPrimaryPathName(event.url))
    );
    this.isLogin$ = this.routePath$.pipe(map(
      (path: string) => ['', ROUTE_NAMES.home].indexOf(path) > -1
    ));
    this.isReveal$ = this.routePath$.pipe(map(
      (path: string) => path === ROUTE_NAMES.reveal
    ));
  }

  loadLocations() {
    this.location$ = this.locationService.getLocation().pipe(shareReplay(1));
    this.locationLinks$ = this.locationService.getLocations()
      .pipe(
        withLatestFrom(this.location$),
        map(([locations, location]: [ServerLocation[], ServerLocation | undefined]) => {
          return locations.map((loc: ServerLocation) => {
            const classes = this.getLocationClass(loc.regionCode, location);
            return {
              url: loc.url,
              itemClass: classes,
              label: loc.regionLabel,
            };
          });
        })
      );
  }

  getLocationClass(regionCode: string, currentLocation?: ServerLocation): string {
    const classes = ['piped'];
    if (currentLocation && currentLocation.regionCode) {
      if (regionCode === currentLocation.regionCode) {
        classes.push('active');
      }
    }
    return classes.join(' ');
  }

  fakeFunctionToForceNewHash2() {
    // nothing
  }
}
