// @ts-nocheck

export function auroraContextMapping() {
}

auroraContextMapping.loadContextMappingsPopup = function (id, name, entityType) {
  let currentContextMappings;
  let $popDiv = $('#load_context_mappings_popup');
  if ($popDiv.length === 0) {
    $popDiv = $('<div/>')
      .attr({ id: 'load_context_mappings_popup' })
      .css({ width: '100%' });
    $(document.body).append($popDiv);
  }
  else {
    $popDiv.empty();
  }

  const contextGrid = $('<div/>')
    .addClass('ag-theme-balham sc-ag-theme')
    .attr({ id: 'contextGrid' })
    .css({ width: '100%', 'min-height': 250, height: 'calc(100% - 60px)' });

  let contextGridApi;

  const contextAddBtn =
    $('<button/>')
      .text('Add')
      .addClass('btn')
      .css({ float: 'right' })
      .click(function () {
        auroraContextMapping.modifyContextMappingPopup(
          id,
          name,
          entityType,
          currentContextMappings,
          undefined,
          function () {
            reloadContextGrid(id);
            menu.refreshData();
          }
        );
      });


  const form = $('<table/>')
    .css({ width: '100%' })
    .append(
      $('<tr/>').append(
        $('<td/>').css({ float: 'right', 'padding-top': '10px' }).addClass('popupControl').append(contextAddBtn))
    );

  $popDiv.append(contextGrid)
    .append(form);


  const reloadContextGrid = function (id) {
    const url = entityType === 'entityMapping'
      ? 'restful/v1/auroraMigration/' + entityType + '/contexts/' + id
      : 'restful/v1/auroraMigration/' + entityType + '/contexts';
    $.ajax(url, {
      error: function (result) {
        $.ajaxSettings.error(result);
        if (result.status != 200) {
          oCompliance.alert(
            'Sorry, an error has occurred.', 'An Error occurred while loading the context mappings for this entity mapping.');
        }
      },
      success: function (data) {
        currentContextMappings = data;
        loadContextGrid(currentContextMappings);
      },
    });
  };

  const getContextGridData = function (data) {
    const gridData = {
      columns: [
        {
          headerName: 'Context',
          field: 'contextName',
          flex: 1,
          filter: false,
          cellRenderer: function (params) {
            const span = $('<span/>');
            span.append(
              $('<a/>')
                .addClass('ei-edit modifyContextMapping')
                .attr({
                  title: 'Modify Context Mapping',
                  contextName: params.data.contextName,
                }));

            if (params.data.order > 0) {
              span.append(
                $('<a/>')
                  .addClass('ei-move-up moveContextMappingUp')
                  .attr({
                    title: 'Move Up',
                    contextName: params.data.contextName,
                  }));
            }

            if (params.data.order < (data.length - 1)) {
              span.append(
                $('<a/>')
                  .addClass('ei-move-down moveContextMappingDown')
                  .attr({
                    title: 'Move Down',
                    contextName: params.data.contextName,
                  }));
            }

            span.append($('<text/>').text(params.data.contextName).css({ 'margin-left': '0.5em' }));

            return span.html();
          },
        },
        {
          headerName: 'Jexl',
          field: 'jexlMapping',
          flex: 1,
          filter: false,
        },
      ],
      rows: [],
    };

    if (data) {
      data.forEach(function (context, index) {
        gridData.rows.push({
          contextName: context.context,
          jexlMapping: context.jexlMapping,
          order: index,
        })
      });
    }
    return gridData;
  };

  const initContextGrid = function () {
    $('.modifyContextMapping').unbind('click');
    $('.modifyContextMapping').click(function (e) {
      auroraContextMapping.modifyContextMappingPopup(
        id,
        name,
        entityType,
        currentContextMappings,
        $(e.target).attr('contextName'),
        function () {
          reloadContextGrid(id);
        });
    });
    $('.moveContextMappingUp').unbind('click');
    $('.moveContextMappingUp').click(function (e) {
      moveContextMapping(id, $(e.target).attr('contextName'), 'UP');
    });
    $('.moveContextMappingDown').unbind('click');
    $('.moveContextMappingDown').click(function (e) {
      moveContextMapping(id, $(e.target).attr('contextName'), 'DOWN');
    });
  };

  const getContextGridOptions = function () {
    const agOptions = {
      overlayNoRowsTemplate: '<span>No Context Mappings<br>Click Add</span>',
      columnTypes: {
        myDefColumn: {
          suppressAutoSize: true,
          suppressMovable: true,
          lockPinned: true,
          sortable: false,
          suppressMenu: true,
        },
      },
      onGridReady: function (params) {
        setTimeout(function () {
          params.api.sizeColumnsToFit();
        }, 100);
      },
      onModelUpdated: function () {
        setTimeout(function () {
          initContextGrid();
        }, 100);
      },
      onBodyScroll: function () {
        setTimeout(function () {
          initContextGrid();
        }, 100);
      },
    };
    return agOptions;
  };

  const moveContextMapping = function (id, contextName, directionToMove) {
    jQuery.ajax({
      url: 'restful/v1/auroraMigration/' + entityType + '/context/move',
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        id: id,
        contextName: contextName,
        directionToMove: directionToMove
      }),
      success: function () {
        reloadContextGrid(id);
      },
      error: function (error) {
        oCompliance.alert('Test Jexl', 'ERROR moving context mapping ' + directionToMove + ' :\n\n' + error);
      },
    });
  }

  const loadContextGrid = function (data) {
    if (contextGridApi) contextGridApi.destroy();

    contextGridApi = agGridController.grid(
      '#contextGrid',
      getContextGridData(data),
      false,
      getContextGridOptions()
    );
  };


  $popDiv.dialog({
    title: 'Context Mappings',
    autoOpen: true,
    closeOnEscape: true,
    width: 1000,
    height: 500,
    modal: true,
    buttons: [
      {
        text: 'Close',
        click: function () {
          $(this).dialog('close');
        },
      },
    ],
    open: function () {
      reloadContextGrid(id);
    },
    close: function () {
      $popDiv.remove();
    },
  });
};


auroraContextMapping.modifyContextMappingPopup = function (id, name, entityType, currentContexts, contextName, onSubmit) {
  let $popDiv;

  $popDiv = $('#modify_context_mapping_popup');
  if ($popDiv.length === 0) {
    $popDiv = $('<div/>')
      .attr({ id: 'modify_context_mapping_popup' })
      .css({ width: '100%' });
    $(document.body).append($popDiv);
  }
  else {
    $popDiv.empty();
  }

  let jexlEditor;
  const contextNameInput = $('<input/>').css({ width: '100%' });
  let jexlDiv = $('<div/>')
    .attr({ id: 'jexlEditor' })
    .css({ width: '100%' })
    .addClass('code wrap scresizable ui-resizable ace_editor ace-chrome');

  const jexlTestBtn =
    $('<button/>')
      .text('Test')
      .addClass('btn')
      .css({ float: 'right' })
      .click(function () {
        if (jexlEditor.getValue()) {
          const jextTestData = {};
          propertiesTestGridApi.forEachNode(
            function (node) {
              if (node &&
                node.data &&
                node.data.responseValue) {
                jextTestData[node.data.responseName] = node.data.responseValue;
              }
            });

          jQuery.ajax({
            url: 'restful/v1/auroraMigration/' + entityType + '/jexlTest',
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({
              id: id,
              jexl: jexlEditor.getValue(),
              responseValues: jextTestData
            }),
            success: function (response) {
              oCompliance.alert('Test Jexl', response.errorMsg ? ('JEXL Error:\n\n' + response.errorMsg) : response.result);
            },
            error: function (error) {
              oCompliance.alert('Test Jexl', 'ERROR:\n\n' + error);
            },
          });
        }
      });

  let propertiesTestGridApi;

  const propertiesTestGrid = $('<div/>')
    .addClass('ag-theme-balham sc-ag-theme')
    .attr({ id: 'propertiesTestGrid' })
    .css({ width: '100%', 'min-height': 250, height: 'calc(100% - 320px)' });

  var formRow = function (label, control) {
    return $('<tr/>').append(
      $('<td/>').css({ width: '5%', 'padding-right': 20 }).addClass('popupLabel').text(label),
      $('<td/>').addClass('popupControl').append(control));
  };

  var form = $('<table/>')
    .css({ width: '100%' })
    .append(
      formRow('Context:', contextNameInput),
      formRow('Jexl:', jexlDiv),
      formRow('', jexlTestBtn),
      $('<tr/>').append(
        $('<td/>').attr({ colspan: 2 }).addClass('popupLabel').text('Properties')));

  $popDiv.append(form)
    .append(propertiesTestGrid);

  const isContextNameUnique = function (prevContextName, currentContextName, currentContexts) {
    let isUnique = true;
    if (currentContextName && currentContexts) {
      if (prevContextName === currentContextName) {
        return true;
      }
      for (let i = 0; i < currentContexts.length; i++) {
        if (currentContexts[i].stage === currentContextName) {
          isUnique = false;
          break;
        }
      }
    }
    return isUnique;
  };

  const submitContextMapping = function (onSuccess) {
    if (contextNameInput.val()) {
      if (isContextNameUnique(contextName, contextNameInput.val(), currentContexts)) {
        jQuery.ajax({
          url: 'restful/v1/auroraMigration/' + entityType + '/context',
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            id: id,
            name: name ? name : '',
            contextName: contextNameInput.val(),
            jexlMapping: jexlEditor.getValue()
          }),
          success: function () {
            onSuccess();
            onSubmit();
          },
          error: function (error) {
            $.ajaxSettings.error(error);
            console.log('ERROR: saving context mapping: ' + error);
          },
        });
      }
      else {
        oCompliance.alert(
          'Context Mapping',
          'This context name is already in use on this entity mapping'
        );
      }
    }
    else {
      oCompliance.alert(
        'Context Mapping',
        'Please Enter a Context Name.'
      );
    }
  };


  $popDiv.dialog({
    title: 'Modify Context Mapping',
    autoOpen: true,
    closeOnEscape: true,
    width: 1000,
    height: 700,
    modal: true,
    buttons: [
      {
        text: 'Submit',
        click: function () {
          submitContextMapping(function () {
            $popDiv.dialog('close');
          });
        },
      },
      {
        text: 'Cancel',
        click: function () {
          $(this).dialog('close');
        },
      },
      {
        text: 'Delete',
        style: 'float:left; background-color: #b9333d; color:white;',
        disabled: true,
        click: function () {
          popup.confirm(
            'Are you sure you want to remove this context mapping?',
            'Remove Context Mapping',
            function () {
              const url = entityType === 'entityMapping'
                ? 'restful/v1/auroraMigration/' + entityType + '/context/' + id + '/' + contextName
                : 'restful/v1/auroraMigration/' + entityType + '/context/' + contextName;
              $.ajax(url, {
                type: 'DELETE',
              }).always(function (result) {
                $popDiv.dialog('close');
                if (result.status >= 400) {
                  oCompliance.alert(
                    'Sorry, an error has occurred.',
                    'An Error occurred while removing this context mapping.'
                  );
                }
                else {
                  if (onSubmit) {
                    onSubmit();
                  }
                  menu.refreshData();
                }
              });
            }
          );
        },
      },
    ],
    open: function () {
      jexlEditor = ace.edit('jexlEditor');
      jexlEditor.setTheme('ace/theme/chrome');
      jexlEditor.setShowPrintMargin(false);
      jexlEditor.setOptions({
        tabSize: 2,
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
      });
      $('#jexlEditor').resizable({
        handles: 'se',
        stop: function () {
          jexlEditor.resize();
        },
      });

      const url = entityType === 'entityMapping'
        ? 'restful/v1/auroraMigration/' + entityType + '/context/' + id + '/' + contextName
        : 'restful/v1/auroraMigration/' + entityType + '/context/' + contextName;
      $.ajax(url, {
        error: function (result) {
          $.ajaxSettings.error(result);
          if (result.status != 200) {
            oCompliance.alert(
              'Sorry, an error has occurred.',
              'An Error occurred while loading this context mapping.');
          }
        },
        success: function (data) {
          if (data) {
            contextNameInput.val(data.contextName);
            contextNameInput.enable(false);
            if (data.jexlMapping) {
              jexlEditor.setValue(data.jexlMapping);
            }
            $(':button:contains("Delete")').prop('disabled', false);
          }
        },
      });

      // propoerties for entity type
      const propertiesUrl = entityType === 'entityMapping'
        ? 'restful/v1/auroraMigration/' + entityType + '/source/' + id
        : 'restful/v1/auroraMigration/' + entityType + '/property';

      $.ajax(propertiesUrl, {
        error: function (result) {
          $.ajaxSettings.error(result);
          if (result.status != 200) {
            oCompliance.alert(
              'Sorry, an error has occurred.',
              'An Error occurred while loading the properties for ' + entityType);
          }
        },
        success: function (data) {
          createPropertiesTestGrid(entityType === 'entityMapping' ? data.responses : data);
        },
      });

    },
    close: function () {
      $popDiv.remove();
    },
  });

  const getPropertiesTestGridData = function (properties) {
    const gridData = {
      columns: [
        {
          headerName: 'Name',
          field: 'responseName',
          flex: 1,
        },
        {
          headerName: 'Test Value',
          field: 'responseValue',
          editable: true,
          flex: 1,
        },
      ],
      rows: [],
    };

    if (properties) {
      properties.forEach(function (property) {
        gridData.rows.push({
          responseName: entityType === 'entityMapping' ? property.name : property,
          responseValue: '',
        });
      });
    }
    return gridData;
  };

  const getPropertiesTestGridOptions = function () {
    const agGridOptions = {
      overlayNoRowsTemplate: '<span>No Properties Configured</span>',
      columnTypes: {
        myDefColumn: {
          suppressAutoSize: true,
          suppressMovable: true,
          lockPinned: true,
        },
      },
      onGridReady: function (params) {
        setTimeout(function () {
          params.api.sizeColumnsToFit();
        }, 100);
      },
    };
    return agGridOptions;
  };

  const createPropertiesTestGrid = function (properties) {
    propertiesTestGridApi = agGridController.grid(
      '#propertiesTestGrid',
      getPropertiesTestGridData(properties),
      false,
      getPropertiesTestGridOptions()
    );
  };
};
