import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export function handleHttpResponse(res: Observable<HttpResponse<any>>): Observable<any> {
  return res.pipe(map((res: HttpResponse<any>) => {
    if (res.status !== 200) {
      return undefined;
    }
    return res.body;
  }));
}

export function getTimezoneOffset() {
  return String(new Date().getTimezoneOffset());
}

export function getHttpHeaders(token: string) {
  return new HttpHeaders({
    'Content-Type': 'application/json',
    'X-csrf': token,
    'X-scTOffset': getTimezoneOffset()
  });
}

export function getAnonymousHttpHeaders() {
  return new HttpHeaders({
    'Content-Type': 'application/json',
    'X-scTOffset': getTimezoneOffset()
  });
}

export function getImageHttpHeaders() {
  return new HttpHeaders({
    'Content-Type': 'image/jpeg',
    'X-scTOffset': getTimezoneOffset()
  });
}
