import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorsService, TokenService } from '@surecloud/core-data';
import { ErrorModalFacade } from '../+state/error-modal.facade';
import { createMessageFromError } from './create-message-from-error';

@Injectable({
  providedIn: 'root',
})
export class ErrorCodeHandler {

  constructor(
    private tokenService: TokenService,
    private errorModalFacade: ErrorModalFacade,
    private errorsService: ErrorsService,
  ) {
  }

  handle(httpError: HttpErrorResponse, isCoreApp = false): HttpErrorResponse {
    const { status, error } = httpError;
    switch (status) {
      case 401: {
        this.handle401(isCoreApp);
        const errorMessage = createMessageFromError(error);
        return this.appendMessageToResponse(httpError, errorMessage);
      }
      case 500:
      case 501:
      case 502:
      case 503:
      case 504: {
        const errorMessage = createMessageFromError(error);
        this.errorModalFacade.dispatch(errorMessage);
        return this.appendMessageToResponse(httpError, errorMessage);
      }
      default: {
        this.errorsService.createHttpError(httpError);
        const errorMessage = createMessageFromError(error);
        return this.appendMessageToResponse(httpError, errorMessage);
      }
    }
  }

  private handle401(isCoreApp: boolean): void {
    if (isCoreApp) {
      this.handleLogout();
    }
  }

  private appendMessageToResponse(httpError: HttpErrorResponse, errorMessage: string): any {
    const body = { errorMessage };
    const res = { ...httpError, ...{ body } };
    return res;
  }

  private handleLogout() {
    this.tokenService.logoutWithReturnUrl();
  }
}
