// @ts-nocheck

export function auroraQuestionListMapping() {
}

auroraQuestionListMapping.loadListMappingsPopup = function (questionId, library, attributeType, attributeName) {
  let currentMappings;
  let $popDiv;

  $popDiv = $('#load_list_mappings_popup');
  if ($popDiv.length === 0) {
    $popDiv = $('<div/>')
      .attr({ id: 'load_list_mappings_popup' })
      .css({ width: '100%' });
    $(document.body).append($popDiv);
  }
  else {
    $popDiv.empty();
  }

  const listsGrid = $('<div/>')
    .addClass('ag-theme-balham sc-ag-theme')
    .attr('id', 'listsGrid')
    .css({ width: '100%', height: 'calc(100% - 5px)' });

  let listsGridApi;

  const form = $('<table/>')
    .css({ width: '100%' });

  $popDiv.append(listsGrid)
    .append(form);


  const reloadListsGrid = function (questionId) {
    const url = 'restful/v1/auroraMigration/questionMapping/lists/' + questionId + '/' + attributeType;

    $.ajax(url, {
      error: function (result) {
        $.ajaxSettings.error(result);
        if (result.status != 200) {
          oCompliance.alert(
            'Sorry, an error has occurred.', 'An Error occurred while loading the list mappings for this question.');
        }
      },
      success: function (data) {
        currentMappings = data;
        loadListsGrid(currentMappings);
      },
    });
  };

  const getListsGridData = function (data) {
    const gridData = {
      columns: [
        {
          headerName: attributeName,
          field: 'name',
          flex: 1,
          filter: false,
          cellRenderer: function (params) {
            const span = $('<span/>');
            span.append(
              $('<a/>')
                .addClass('ei-edit modifyListMapping')
                .attr({
                  title: 'Modify List Mapping',
                  attributeType: params.data.attributeType,
                  attributeName: params.data.attributeName,
                }));

            if (params.data.order > 0) {
              span.append(
                $('<a/>')
                  .addClass('ei-move-up moveListMappingUp')
                  .attr({
                    title: 'Move Up',
                    attributeType: params.data.attributeType,
                    attributeName: params.data.attributeName,
                  }));
            }

            if (params.data.order < (data.length - 1)) {
              span.append(
                $('<a/>')
                  .addClass('ei-move-down moveListMappingDown')
                  .attr({
                    title: 'Move Down',
                    attributeType: params.data.attributeType,
                    attributeName: params.data.attributeName,
                  }));
            }

            span.append($('<text/>').text(params.data.attributeName).css({ 'margin-left': '0.5em' }));

            return span.html();
          },
        },
        {
          headerName: 'Jexl',
          field: 'jexl',
          flex: 1,
          filter: false,
        },
      ],
      rows: [],
    };

    if (data) {
      data.forEach(function (attribute, index) {
        gridData.rows.push({
          attributeType: attribute.attributeType,
          attributeName: attribute.attributeName,
          jexl: attribute.jexl,
          order: index,
        })
      });
    }
    return gridData;
  };

  const initListGrid = function () {
    $('.modifyListMapping').unbind('click');
    $('.modifyListMapping').click(function (e) {
      auroraQuestionListMapping.modifyListMappingPopup(
        questionId,
        library,
        $(e.target).attr('attributeType'),
        $(e.target).attr('attributeName'),
        function () {
          reloadListsGrid(questionId);
        });
    });
    $('.moveListMappingUp').unbind('click');
    $('.moveListMappingUp').click(function (e) {
      moveListMapping(questionId, $(e.target).attr('attributeType'), $(e.target).attr('attributeName'), 'UP');
    });
    $('.moveListMappingDown').unbind('click');
    $('.moveListMappingDown').click(function (e) {
      moveListMapping(questionId, $(e.target).attr('attributeType'), $(e.target).attr('attributeName'), 'DOWN');
    });
  };

  const getListsGridOptions = function () {
    const agOptions = {
      overlayNoRowsTemplate: '<span>Ensure items of this type are configured in aurora</span>',
      columnTypes: {
        myDefColumn: {
          suppressAutoSize: true,
          suppressMovable: true,
          lockPinned: true,
          sortable: false,
          suppressMenu: true,
        },
      },
      onGridReady: function (params) {
        setTimeout(function () {
          params.api.sizeColumnsToFit();
        }, 100);
      },
      onModelUpdated: function () {
        setTimeout(function () {
          initListGrid();
        }, 100);
      },
      onBodyScroll: function () {
        setTimeout(function () {
          initListGrid();
        }, 100);
      },
    };
    return agOptions;
  };

  const moveListMapping = function (questionId, attributeType, attributeName, directionToMove) {
    jQuery.ajax({
      url: 'restful/v1/auroraMigration/questionMapping/list/move',
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        id: questionId,
        attributeType: attributeType,
        attributeName: attributeName,
        directionToMove: directionToMove,
      }),
      success: function () {
        reloadListsGrid(questionId);
      },
      error: function (error) {
        oCompliance.alert('Test Jexl', 'ERROR moving list mapping ' + directionToMove + ' :\n\n' + error);
      },
    });
  }

  const loadListsGrid = function (data) {
    if (listsGridApi) listsGridApi.destroy();

    listsGridApi = agGridController.grid(
      '#listsGrid',
      getListsGridData(data),
      false,
      getListsGridOptions()
    );
  };


  $popDiv.dialog({
    title: 'List Mappings',
    autoOpen: true,
    closeOnEscape: true,
    width: 1000,
    height: 450,
    modal: true,
    buttons: [
      {
        text: 'Close',
        click: function () {
          $(this).dialog('close');
        },
      },
    ],
    open: function () {
      reloadListsGrid(questionId);
    },
    close: function () {
      $popDiv.remove();
    },
  });
};


auroraQuestionListMapping.modifyListMappingPopup = function (questionId, name, attributeType, attributeName, onSubmit) {
  let $popDiv;
  $popDiv = $('#modify_list_mapping_popup');
  if ($popDiv.length === 0) {
    $popDiv = $('<div/>')
      .attr({ id: 'modify_list_mapping_popup' })
      .css({ width: '100%' });
    $(document.body).append($popDiv);
  }
  else {
    $popDiv.empty();
  }

  let jexlEditor;
  const attributeNameInput = $('<input/>')
    .css({ width: '100%' })
    .enable(false);
  let jexlDiv = $('<div/>')
    .attr('id', 'jexlEditor')
    .css({ width: '100%' })
    .addClass('code wrap scresizable ui-resizable ace_editor ace-chrome');

  const jexlTestBtn =
    $('<button/>')
      .text('Test')
      .addClass('btn')
      .css({ float: 'right' })
      .click(function () {
        if (jexlEditor.getValue()) {
          const jextTestData = {};
          propertiesTestGridApi.forEachNode(
            function (node) {
              if (node &&
                node.data &&
                node.data.responseValue) {
                jextTestData[node.data.responseName] = node.data.responseValue;
              }
            });

          jQuery.ajax({
            url: 'restful/v1/auroraMigration/questionMapping/jexlTest',
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({
              id: questionId,
              jexl: jexlEditor.getValue(),
              responseValues: jextTestData,
            }),
            success: function (response) {
              oCompliance.alert('Test Jexl', response.errorMsg ? ('JEXL Error:\n\n' + response.errorMsg) : response.result);
            },
            error: function (error) {
              oCompliance.alert('Test Jexl', 'ERROR:\n\n' + error);
            },
          });
        }
      });

  let propertiesTestGridApi;

  const propertiesTestGrid = $('<div/>')
    .addClass('ag-theme-balham sc-ag-theme')
    .attr('id', 'propertiesTestGrid')
    .css({ width: '100%', 'min-height': 250, height: 'calc(100% - 290px)' });

  var formRow = function (label, control) {
    return $('<tr/>').append(
      $('<td/>').css({ width: '5%', 'padding-right': 20 }).addClass('popupLabel').text(label),
      $('<td/>').addClass('popupControl').append(control));
  };

  var form = $('<table/>')
    .css({ width: '100%' })
    .append(
      formRow('Name:', attributeNameInput),
      formRow('Jexl:', jexlDiv),
      formRow('', jexlTestBtn),
      $('<tr/>').append(
        $('<td/>').attr({ colspan: 2 }).addClass('popupLabel').text('Properties')));

  $popDiv.append(form)
    .append(propertiesTestGrid);

  const submitStageMapping = function (onSuccess) {
    jQuery.ajax({
      url: 'restful/v1/auroraMigration/questionMapping/list',
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        id: questionId,
        name: name ? name : '',
        attributeType: attributeType,
        attributeName: attributeName,
        jexlMapping: jexlEditor.getValue(),
      }),
      success: function () {
        onSuccess();
        onSubmit();
      },
      error: function (error) {
        $.ajaxSettings.error(error);
        console.log('ERROR: saving list mapping: ' + error);
      },
    });
  };


  $popDiv.dialog({
    title: 'Modify List Mapping',
    autoOpen: true,
    closeOnEscape: true,
    width: 1000,
    height: 700,
    modal: true,
    buttons: [
      {
        text: 'Submit',
        click: function () {
          submitStageMapping(function () {
            $popDiv.dialog('close');
          });
        },
      },
      {
        text: 'Cancel',
        click: function () {
          $(this).dialog('close');
        },
      },
      {
        text: 'Delete',
        style: 'float:left; background-color: #b9333d; color:white;',
        disabled: true,
        click: function () {
          popup.confirm(
            'Are you sure you want to remove this list mapping? The list item itself will NOT be removed.',
            'Remove List Mapping',
            function () {
              const url = 'restful/v1/auroraMigration/questionMapping/list/' + questionId + '/' + attributeType + '/' + attributeName;

              $.ajax(url, {
                type: 'DELETE',
              }).always(function (result) {
                $popDiv.dialog('close');
                if (result.status >= 400) {
                  oCompliance.alert(
                    'Sorry, an error has occurred.',
                    'An Error occurred while removing this list mapping.'
                  );
                }
                else {
                  if (onSubmit) onSubmit();
                  menu.refreshData();
                }
              });
            }
          );
        },
      },
    ],
    open: function () {
      jexlEditor = ace.edit('jexlEditor');
      jexlEditor.setTheme('ace/theme/chrome');
      jexlEditor.setShowPrintMargin(false);
      jexlEditor.setOptions({
        tabSize: 2,
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
      });
      $('#jexlEditor').resizable({
        handles: 'se',
        stop: function () {
          jexlEditor.resize();
        },
      });

      const url = 'restful/v1/auroraMigration/questionMapping/list/' + questionId + '/' + attributeType + '/' + attributeName;
      $.ajax(url, {
        error: function (result) {
          $.ajaxSettings.error(result);
          if (result.status != 200) {
            oCompliance.alert(
              'Sorry, an error has occurred.',
              'An Error occurred while loading this list mapping.');
          }
        },
        success: function (data) {
          if (data) {
            attributeNameInput.val(data.attributeName);
            if (data.jexlMapping) {
              jexlEditor.setValue(data.jexlMapping);
            }
            $(':button:contains("Delete")').prop('disabled', false);
          }
        },
      });

      // load source responses for entity
      const propertiesUrl = 'restful/v1/auroraMigration/questionMapping/source/' + questionId;

      $.ajax(propertiesUrl, {
        error: function (result) {
          $.ajaxSettings.error(result);
          if (result.status != 200) {
            oCompliance.alert(
              'Sorry, an error has occurred.',
              'An Error occurred while loading the source responses for this entity.');
          }
        },
        success: function (data) {

          createPropertiesTestGrid(data.responses);
        },
      });
    },
    close: function () {
      $popDiv.remove();
    },
  });

  const getPropertiesTestGridData = function (properties) {
    const gridData = {
      columns: [
        {
          headerName: 'Name',
          field: 'responseName',
          flex: 1,
        },
        {
          headerName: 'Test Value',
          field: 'responseValue',
          editable: true,
          flex: 1,
        },
      ],
      rows: [],
    };

    if (properties) {
      properties.forEach(function (property) {
        gridData.rows.push({
          responseName: property.name,
          responseValue: '',
        });
      });
    }
    return gridData;
  };

  const getPropertiesTestGridOptions = function () {
    const agGridOptions = {
      overlayNoRowsTemplate: '<span>No Source Responses Mappings Configured</span>',
      columnTypes: {
        myDefColumn: {
          suppressAutoSize: true,
          suppressMovable: true,
          lockPinned: true,
        },
      },
      onGridReady: function (params) {
        setTimeout(function () {
          params.api.sizeColumnsToFit();
        }, 100);
      },
    };
    return agGridOptions;
  };

  const createPropertiesTestGrid = function (properties) {
    propertiesTestGridApi = agGridController.grid(
      '#propertiesTestGrid',
      getPropertiesTestGridData(properties),
      false,
      getPropertiesTestGridOptions()
    );
  };
};
