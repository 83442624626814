export function isEmpty(value?: string): boolean {
  return value === undefined || value === null || value === '' || value.length === 0;
}

export function isObject(variable: any): boolean {
  return typeof variable === 'object' && variable !== null && !Array.isArray(variable);
}

export const isNullOrUndefined = (value: any): boolean => {
  return typeof value === 'undefined' || value === null;
};

export const areNumbers = (a: any, b: any): boolean => {
  return isNumber(a) && isNumber(b);
};

export const isNumber = (value: any): boolean => {
  return typeof value === 'number';
};

export const areStrings = (a: any, b: any): boolean => {
  return isString(a) && isString(b);
};

export const isString = (value: any): boolean => {
  return typeof value === 'string';
};

export const groupBy = <T>(arr: any[], key: string): T => {
  return arr.reduce((map: any, item: any) => {
    map[item[key]] = item;
    return map;
  }, {});
};

export const arrayToMap = <T>(arr: T[], key: string): { [key: string]: T } => {
  if (arr.length < 1) return {};
  return arr.reduce((mapObj: { [key: string]: T }, item: any) => {
    const keyValue = item[key];
    if (typeof keyValue === 'string') {
      mapObj[keyValue] = item;
    }
    return mapObj;
  }, {});
};
