import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalResponseInterceptor } from './http/global-response.interceptor';
import { ErrorMessageComponent } from './components/message/message.component';
import { ErrorModalComponent } from './components/modal/error-modal.component';

const COMPONENTS = [
  ErrorMessageComponent,
  ErrorModalComponent,
];

@NgModule({
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
  imports: [CommonModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalResponseInterceptor,
      multi: true,
    },
  ],
})
export class ErrorModule {}
