import { DropMenuOption, IconConfig } from '@surecloud/ui-interfaces';

export const makeDropMenuOption =
  (partialDropMenuOption: Pick<DropMenuOption, 'label' | 'id' | 'breakAfter' | 'routeName' | 'modalName'>, partialIconConfig: Pick<IconConfig, 'name'>): DropMenuOption => {
    const defaultIconConfig: Partial<IconConfig> = {
      fontSize: '16px',
      height: '16px',
      width: '16px',
      iconClass: '',
    };

    const iconConfig: IconConfig = { ...defaultIconConfig, ...partialIconConfig as IconConfig };

    const dropMenuOption: Partial<DropMenuOption> = {
      icon: iconConfig,
      breakAfter: false,
    };

    return { ...dropMenuOption, ...partialDropMenuOption as DropMenuOption };
  };
