export interface Tile {
  colour?: string;
  callJsFunction?: boolean;
  created?: string;
  favourited?: boolean;
  hoverColour?: string;
  hoverTheme?: string;
  icon?: string;
  identifier: TileIdent;
  links?: any[];
  menu?: TileMenuItem[];
  readonly?: boolean;
  standardKey?: number;
  theme?: string;
  tileGroup?: string;
  tileTypeLabel?: string;
  title?: string;
  published?: boolean;
  subscription?: boolean;
  description?: string;
  applicationGroupId?: number;
  dashboardId?: number;
  shortcutId?: number;
  questionnaireKey?: number;
  uiStandardKey?: number;
  dashboardOwner?: string;
}

export interface TileMenuItem {
  button: string;
  fixed?: boolean;
  entity?: string;
  grc?: boolean;
  initFunction?: string;
  label: string;
  menus?: any[];
}

export interface TileIdent {
  tileType: TileIdentType;
  id: number;
}

export enum TileIdentType {
  Standard = 'STANDARD',
  Skeleton = 'SKELETON',
  ApplicationGroup = 'APPLICATION_GROUP',
  Dashboard = 'DASHBOARD',
  Shortcut = 'SHORTCUT',
  Questionnaire = 'QUESTIONNAIRE',
  CyberApplicationGroup = 'CYBER_APPLICATION_GROUP',
  Assets = 'ASSETS',
  Vulnerability = 'VULNERABILITY',
  PciScans = 'PCI_SCANS',
  Events = 'EVENTS'
}

export interface TilePayload {
  colour: string;
  icon: string;
  identifier: TileIdent;
  initials: string;
  title: string;
}

export interface TileColour {
  key: string;
  value: string;
}

export interface Icon {
  id: string;
  url: string;
}

export enum TileMenuAction {
  AddDefaultFavourite = 'DEFAULT_FAVOURITE_ADD',
  RemoveDefaultFavourite = 'DEFAULT_FAVOURITE_REMOVE',
  SetDefaultHomePage = 'DEFAULT_HOME_PAGE_SET',
  RemoveDefaultHomePage = 'DEFAULT_HOME_PAGE_REMOVE',
  EditColour = 'TILE_COLOUR_MODIFY',
  EditAppGroup = 'APPLICATION_GROUP_MODIFY',
  RenameDashboard = 'DASHBOARD_NAME_MODIFY',
  EditShortcut = 'SHORTCUT_MODIFY',
  EditFolder = 'INITIATIVE_MODIFY',
  EditForm = 'QUESTIONNAIRE_MODIFY',
  Form = 'QUESTIONNAIRE',
  SetDefaultHome = 'SET_AS_DEFAULT_HOME',
  RemoveDefaultHome = 'REMOVE_AS_DEFAULT_HOME',
  AddAsDefaultSubscription = 'DEFAULT_SUBSCRIPTION_ADD',
  RemoveDefaultSubscription = 'DEFAULT_SUBSCRIPTION_REMOVE',
}
