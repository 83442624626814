import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { isEmpty } from '@surecloud/utils';

@Injectable({
  providedIn: 'root'
})
export class ErrorModalFacade {
  error$: Subject<string | undefined> = new Subject();

  constructor() {
  }

  selectError() {
    return this.error$.pipe(
      filter((msg?: string) => !isEmpty(msg))
    );
  }

  dispatch(messageHtml: string): void {
    this.error$.next(messageHtml);
  }

  reset(): void {
    this.error$.next(undefined);
  }
}
