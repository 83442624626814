import { isFocusableHTMLElement } from '@surecloud/utils';

export class FocusTracker {
  private stashedFocusedElement: HTMLElement;

  stashFocusedElement(): void {
    const focusedElement = document.activeElement as HTMLElement;
    if (focusedElement) {
      focusedElement.blur();
      this.stashedFocusedElement = focusedElement;
    }
  }

  reFocusStashedElement(): void {
    if (this.stashedFocusedElement
      && this.stashedFocusedElement.id
      && this.stashedFocusedElement.id.length > 1
      && isFocusableHTMLElement(this.stashedFocusedElement)) {
      this.reQueryElement();
      this.stashedFocusedElement.focus();
    }
  }

  private reQueryElement(): void {
    const newElement = document.querySelector<HTMLElement>(`#${this.stashedFocusedElement.id}`);
    if (newElement) {
      this.stashedFocusedElement = newElement;
    }
  }
}

export const formFocusTracker = new FocusTracker();
