<!-- Unique Page -->
<h1 class="login-app__h1">Log into your account</h1>
<form class="login-app__form" method="post" autocomplete="off" (submit)="processForm()">
  <fieldset class="login-app__fieldset">
    <!-- IE Support: Remove in September 2021:start -->
    <p *ngIf="isIE" class="login__warning">
      You are using an Internet Explorer (IE) web browser. From September 2021 you will no longer be able to log in using this browser.
      <br><br>
      We strongly recommend that you switch to use a <a href="https://success.surecloud.com/hc/en-us/articles/360002680618-Browser-Support-" target="_blank">supported web browser</a>.
    </p>
    <!-- IE Support: Remove in September 2021:end -->
    <input data-test="scLogin:email" [ngClass]="{ nod2: hideEmail }" [disabled]="!hidePass" id="sure-email" type="text"
      name="sure-email" autocomplete="off" placeholder="your@email.com / User ID" class="login-app__input"
      [(ngModel)]="formData.email" (ngModelChange)="changedEmail()" />
    <label class="hand d-flex flex-center-y mb20">
      <input data-test="scLogin:rememberUserName" type="checkbox" name="remember" id="remember"
        ng-checked="formData.remember" [(ngModel)]="formData.remember" />
        <span class="login__checkbox" [ngClass]="{'login__checkbox--on': formData.remember, 'login__checkbox--off': !formData.remember}"></span>
      <span class="ml5 fs16">Remember my username</span>
    </label>
    <input data-test="scLogin:password" [ngClass]="{ nod2: hidePass }" #passwd type="password" id="sure-pass" name="sure-pass"
      autocomplete="off" placeholder="Password" class="login-app__input" [(ngModel)]="formData.password" />
    <div *ngIf="error" class="login-app__error" [ngClass]="{ show: error }" data-test="scLogin:error" [innerHtml]="error"></div>
    <button class="login-app__btn" [disabled]="!userIdIsValid" id="login-button" data-test="scLogin:submit">
      {{ hidePass ? "Next" : "Sign in" }}
    </button>
  </fieldset>
</form>

<a data-test="scLogin:reEnterUsername" (click)="reEnterUsername()" [ngClass]="{ nod2: hidePass }" class="login-app__link">Re-enter the
  username/userID</a>

<app-forgot-pass [email]="formData.email"></app-forgot-pass>
<!-- Unique:end -->
