import { TileIdentType } from '@surecloud/api-interfaces';
import { IconConfig, makeIconConfig } from '../design';
import { ModalName } from '../modal/modal-names';
import { RouteNames } from '../route';

export const NAV_ITEM_TYPE_MODAL = '[Nav Item Type] Modal';
export const NAV_ITEM_TYPE_EXT = '[Nav Item Type] External';
export const NAV_ITEM_TYPE_INT = '[Nav Item Type] Internal (Angular)';
export const NAV_ITEM_TYPE_INT_TABS = '[Nav Item Type] Internal (Non-Angular)';

export type NavItemType =
  typeof NAV_ITEM_TYPE_MODAL |
  typeof NAV_ITEM_TYPE_EXT |
  typeof NAV_ITEM_TYPE_INT |
  typeof NAV_ITEM_TYPE_INT_TABS;

export interface NavItem {
  /* icon
  -----------------
  Any icon configuration needed (e.g primary-nav, actions) */
  icon: IconConfig;

  /* label
  -----------------
  The label that will appear on screen. */
  label: string;

  /* id
  -----------------
  unique identifier e.g generalId / dashboardId / applicationId */
  id: number | string | RouteNames;

  /* idString
  -----------------
  unique string identifier, added to facilitate cyber routes */

  idString: string;

  /* routePathToId
  ----------------------
  which part of the custom route snapshot should match to this
  navItem id. either 'topPath', 'fullPath' or 'lastPath'
  default of 'lastPath'
  */
  routePathToId?: 'topPath' | 'fullPath' | 'lastPath';

  /* name
  ------------
  link MenuItem to NavItem by name. */
  name: string;

  /* type
  -----------------
  The type of link/action to take when a nav item is clicked */
  type: NavItemType;

  /* tileType
  -----------------
  The type of link/action to take when a nav item is clicked */
  tileType?: TileIdentType;

  /* url [Deprecated] */
  // url?: string;

  /* uri
  ----------------- */
  uri?: string;

  /* modalName
  -----------------
  Trigger a modal, by name from a nav item */
  modalName?: ModalName;

  /* isFavourite
  -----------------
  Displays a star next to secondary nav item, also affects sorting.
  (true goes to the top of the list) */
  isFavourite?: boolean;

  /* sortOrder
  -----------------
  Optional additional sorting preference */
  sortOrder?: number;

  /* routeCommands
  -----------------
  For angular routes, specify an array of command
  that will be passed to the angular router. */
  routeCommands?: string[];

  /* visibleInMenu
  ----------------
  A flag from the server used to determine main menu visibility
  (a user can sometimes have access to screens that aren't in the direct
  navigation, so the data is needed for them even if they aren't displayed in a menu).
  */
  visibleInMenu: boolean;
}

export const makeNavItem = (partial: Partial<NavItem> = {}): NavItem => {
  const defaults: NavItem = {
    icon: makeIconConfig(),
    label: '',
    id: -1,
    idString: '',
    name: '',
    uri: '',
    type: NAV_ITEM_TYPE_INT,
    isFavourite: false,
    routePathToId: undefined,
    tileType: undefined,
    routeCommands: undefined,
    visibleInMenu: true,
  };
  return { ...defaults, ...partial as NavItem };
};
