import { ModalEvent, ModalName } from './modal-names';

export interface ModalPayload {
  name: ModalName;
  event?: ModalEvent;
  data?: any;
}

export const makeModalPayload = (partial: Partial<ModalPayload> = {}): ModalPayload => {
  const defaults: ModalPayload = {
    name: undefined,
    event: undefined,
    data: undefined,
  };
  return { ...defaults, ...partial } as ModalPayload;
};
