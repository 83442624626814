export const addIfTrue = <T>(element: T, array: T[], booleanValue: boolean): void => {
  if (booleanValue)
    array.push(element);
};

/**
 * Returned interface from the arrayDifference utility function.
 *
 * @export
 * @interface ArrayDifferenceInterface
 */
export interface ArrayDifferenceInterface {
  added: number;
  removed: number;
  changes: number;
}

/**
 * Compare two arrays to find out the difference in terms of what has been added, what has been removed and the total changes.
 *
 * @param array1 Initial array data set.
 * @param array2 Final array data set.
 *
 * @returns Object literal describing the differences between array1 and array2.
 */
export const arrayDifference = (array1: string[], array2: string[]): ArrayDifferenceInterface => {

  const result = {
    added: 0,
    removed: 0,
    changes: 0,
  };

  result.changes = array1.filter((id) => {

    const isRemoved = array2.indexOf(id) === -1;

    if (isRemoved) {
      result.removed = result.removed + 1;
    }
    return isRemoved;
  }).concat(array2.filter((id) => {

    const isAdded = array1.indexOf(id) === -1;

    if (isAdded) {
      result.added = result.added + 1;
    }
    return isAdded;
  })).length;

  return result;
};
