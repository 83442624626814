import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ResetPasswordResponse } from '@surecloud/api-interfaces';
import { UserService } from "@surecloud/core-data";
import { ModalComponent } from '../modal/modal.component';

interface UIResetForm {
  userEmail: string;
}

@Component({
  selector: 'app-forgot-pass',
  templateUrl: './forgot-pass.component.html',
  styleUrls: ['./forgot-pass.component.scss'],
})
export class ForgotPassComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent;
  @Input() email: any;

  forgottenPassSent: boolean = false;
  configVariables: any;
  formData: UIResetForm = {
    userEmail: ''
  };
  error: string = '';
  info: string = '';

  constructor(
    public userService: UserService
  ) {
  }

  ngOnInit() {
    if (this.email && this.email.length > 0) {
      this.formData.userEmail = this.email;
    }
  }

  forgotPass() {
    this.formData.userEmail = this.info = this.error = '';
    this.forgottenPassSent = false;
    this.modal.show();
    return false;
  }

  sendPass() {
    const credentials = this.formData.userEmail;
    if (this.formData.userEmail && this.formData.userEmail.length) {
      this.userService.resetPassword(credentials).subscribe(
        (response: ResetPasswordResponse | any) => {
          if (response && response.errorMessage) {
            this.error = 'Sorry, an error has occurred and an administrator has been informed.';
            return;
          }
          if (response.message) {
            this.info = response.message;
          }

          if (response.validEmail) {
            this.forgottenPassSent = true;
          }
        }
      );
    }
    else {
      this.error = 'Please provide a valid email address or User ID.';
    }
  }
}
