import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  modalContent: any;
  public visible = false;
  public visibleAnimate = false;

  constructor() {
  }

  public show(): void {
    setTimeout(() => this.visible = true);
    setTimeout(() => {
      this.visibleAnimate = true;
    }, 100);
  }

  public hide(): void {
    this.visibleAnimate = false;
    setTimeout(() => this.visible = false, 300);
  }

  removeDragable() {
    //this.elRef.nativeElement.querySelector('.modal-dialog');
  }

  public onContainerClicked(event: MouseEvent): void {
    /*
    if ((<HTMLElement>event.target).classList.contains('modal')) {
    }
    */
  }

  ngOnInit() {
  }

}
