import { Injectable } from '@angular/core';
import { CORE_APP_URL } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class LoginReturnUrlHelper {
  url = CORE_APP_URL;

  navigate() {
    const url = new URL(window.location.href);
    const returnUrlParamKey = 'returnUrl';
    const { searchParams } = url;
    if (searchParams.has(returnUrlParamKey)) {
      window.location.replace(this.url + "/" + searchParams.get(returnUrlParamKey));
    }
    else {
      window.location.replace(this.url);
    }
  }

  navigateToLoginSuccess(token: string) {
    const url = new URL(window.location.href);
    const returnUrlParamKey = 'returnUrl';
    const { searchParams } = url;
    let returnUrl = 'home';
    if (searchParams.has(returnUrlParamKey)) {
      const returnUrlRaw = searchParams.get(returnUrlParamKey);
      returnUrl = encodeURIComponent(returnUrlRaw).replace('(', '%28').replace(')', '%29');
    }
    window.location.replace(`${CORE_APP_URL}/loginSuccess/${token}?returnPath=${returnUrl}`);
  }
}
