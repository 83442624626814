import { makeIconConfig } from '../../design';
import { RouteNames } from '../../route';
import { NavItem, NAV_ITEM_TYPE_INT } from '../nav-item';
import { PrimaryNavAltName } from './primary-nav-interfaces';

export interface PrimaryNavItem extends NavItem {
  /* menu
  -----------------
  Secondary nav items */
  menu?: NavItem[];

  /* actions
  -----------------
  Secondary nav actions (display at bottom of nav) */
  actions?: NavItem[];

  /* preventActive
  -----------------
  Permanently prevent active state (e.g Chat). */
  preventActive?: boolean;

  /* altPathMatch
  -----------------
  Show as active for additional paths (sub-menu items) */
  altPathMatch?: (PrimaryNavAltName | RouteNames | string)[];

  /* showBuildInfo
  -----------------
  Show build info in secondary nav (only for info/help menu) */
  showBuildInfo?: boolean;

  /* hasSecondaryNav
  ------------------
  Flag nav item has a secondary nav */
  hasSecondaryNav?: boolean;
}

export const makePrimaryNavItem = (partial: Partial<PrimaryNavItem> = {}): PrimaryNavItem => {
  const defaults: PrimaryNavItem = {
    icon: makeIconConfig(),
    label: '',
    id: -1,
    idString: '',
    name: '',
    type: NAV_ITEM_TYPE_INT,
    menu: [],
    actions: [],
    showBuildInfo: false,
    routeCommands: undefined,
    routePathToId: undefined,
    visibleInMenu: true,
  };
  return { ...defaults, ...partial as PrimaryNavItem };
};
