import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CreateErrorPayload } from "@surecloud/api-interfaces";
import { tap } from "rxjs/operators";
import { ERRORS_ENDPOINT } from "../../url-constants";
import { getApiUrl } from "../../utils/url-helpers";

@Injectable({
  providedIn: 'root'
})
export class ErrorsService {
  inflight = false;

  constructor(
    private http: HttpClient
  ) {
  }

  create(errorData: CreateErrorPayload): void {
    const url = getApiUrl(ERRORS_ENDPOINT);
    if (this.inflight === true) { return; }
    this.inflight = true;
    this.http.post(url, errorData)
      .pipe(tap(() => {
        this.inflight === false;
      }))
      .subscribe();
  }

  createHttpError(httpError: HttpErrorResponse): void {
    const { url, error } = httpError;
    const userAgent = navigator && navigator.userAgent ? navigator.userAgent : 'Unknown User Agent';
    const errorData = { apiURL: url, error: JSON.stringify(error), message: JSON.stringify(httpError), userAgent };
    this.create(errorData);
  }
}
