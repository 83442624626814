import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CURRENT_USER_ENDPOINT_ORGANISATION, CURRENT_USER_HOMEPAGE } from '../../url-constants';
import { getApiUrl } from '../../utils/url-helpers';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  constructor(private http: HttpClient) {
  }

  switchOrganisation(encryptedKey: string) {
    const url = getApiUrl(
      `${CURRENT_USER_ENDPOINT_ORGANISATION}?encryptedKey=${encryptedKey}`
    );
    return this.http.post(url, {});
  }

  setHomepage(uri: string): Observable<any> {
    const url = getApiUrl(`${CURRENT_USER_HOMEPAGE}`);
    return this.http.put(url, { uri });
  }

  unsetHomepage(): Observable<any> {
    const url = getApiUrl(`${CURRENT_USER_HOMEPAGE}`);
    return this.http.delete(url);
  }
}
