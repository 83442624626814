import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerLocation } from "@surecloud/api-interfaces";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LOCATIONS_ENDPOINT, LOCATION_ENDPOINT } from "../../url-constants";
import { getApiUrl } from "../../utils/url-helpers";

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
  constructor(
    private http: HttpClient
  ) {
  }

  getLocation(): Observable<ServerLocation | undefined> {
    const url = getApiUrl(`${LOCATION_ENDPOINT}`);
    return this.http.get<ServerLocation | undefined>(url)
      .pipe(map((location?: ServerLocation) => location ? location : undefined));
  }

  getLocations(): Observable<ServerLocation[]> {
    const url = getApiUrl(`${LOCATIONS_ENDPOINT}`);
    return this.http.get<ServerLocation[]>(url);
  }
}
