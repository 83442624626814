<a (click)="forgotPass();" class="login-app__link" data-test="scForgotPassword:link">Can't remember your password?</a>
<app-modal #modalForgotPasword>
  <div class="modal-dialog modal-sm app-modal-content" data-test="scForgotPassword:modal">
    <div class="modal-content">
      <div class="modal-sc-content forgot-pass">
        <div class="modal-header">
          <button aria-label="close" type="button" class="close" (click)="modalForgotPasword.hide()">&times;</button>
        </div>
        <form class="login-app__form" method="post" autocomplete="off" (submit)="sendPass()" *ngIf="!forgottenPassSent">
          <div class="modal-body">
            <h4>Forgot your password?</h4>
            <h5>Enter the ID you logon with (User ID or email) below to receive your password reset instructions.</h5>

            <input data-test="scForgotPassword:email" type="text" name="email" class="login-app__input mb15" [(ngModel)]="formData.userEmail">
            <button data-test="scForgotPassword:submit" type="submit" class="login-app__btn" type="submit" [disabled]="!formData.userEmail">Reset my password
            </button>

            <a data-test="scForgotPassword:cancel" class="login-app__link" (click)="modalForgotPasword.hide()">Cancel</a>
            <div class="login-app__info-message login-app__info-message--error" *ngIf="error.length">{{ error }}</div>
          </div>
        </form>
        <div class="pass-sent modal-body" *ngIf="forgottenPassSent">
          <p data-test="scForgotPassword:successMessage" class="login-app__info-message">{{ info }}</p>
          <a class="login-app__link" data-test="scForgotPassword:close" (click)="modalForgotPasword.hide()">Close</a>
        </div>
      </div>
    </div>
  </div>
</app-modal>
