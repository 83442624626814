import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Auth0Profile, AuthMethod, AuthProfile } from '@surecloud/api-interfaces';
import { UserService } from '@surecloud/core-data';
import { encodeIDPReturnUrl, joinIDPQueryParams } from '@surecloud/utils';
import { isEmpty } from '@surecloud/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ROUTE_NAMES } from '../constants';
import { Auth0Helper } from '../helpers/auth0.helper';

@Injectable({
  providedIn: 'root'
})
export class IdpLinkGuard {

  constructor(
    private userService: UserService,
    private auth0Helper: Auth0Helper,
    private router: Router,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const returnUrlQuery = encodeIDPReturnUrl(joinIDPQueryParams(route.queryParams));
    const person = route.queryParams.person || '';
    if (isEmpty(person)) {
      location.href = `${environment.loginAppUrl}${returnUrlQuery}`;
      return true;
    }
    return this.userService.getIDPAuthProfile(person)
      .pipe(
        map((authProfile: AuthProfile) => {
          if (!authProfile || (authProfile as any).errorMessage) {
            return true;
          }
          if (authProfile.authenticationMethod === AuthMethod.Auth0) {
            this.auth0Helper.login(authProfile.email, (authProfile as Auth0Profile), returnUrlQuery);
          }
          if (authProfile.authenticationMethod === AuthMethod.Surecloud) {
            location.href = `${environment.loginAppUrl}/${ROUTE_NAMES.home}/${returnUrlQuery}`;
          }
          return true;
        })
      );
  }


}
