<div class="login-app">
  <section class="login-app__page d-flex">
    <!-- Location Nav -->
    <div class="login-app__locations" *ngIf="(isLogin$ | async) && (location$ | async)">
      <login-inline-links [links]="(locationLinks$ | async)"></login-inline-links>
    </div>

    <div class="d-flex flex-col flex-grow-1 flex-center-xy">
      <div class="d-flex flex-grow-1 flex-center-xy">
        <div class="login-app__content flex-grow-1">
          <a class="login-app__main-logo" [ngClass]="{'login-app__main-logo--show-default': (hasBranding$ | async) === false }"
            href="#">
            <img *ngIf="(hasBranding$ | async)" src="{{ logoSrc }}" alt="">
          </a>
          <!-- Unique Page -->
          <router-outlet></router-outlet>
          <!-- Unique:end -->
        </div>
      </div>
      <!-- Footer -->
      <div class="login-app__footer pb24">
        <div>
          <span class="login-app-footer__logo"></span>
        </div>
        <h6>Powered and protected by SureCloud</h6>
        <div class="d-flex flex-center-xy">
          <span>&copy; SureCloud {{ today | date: "yyyy" }}</span>
          <ul class="login-app-footer__links">
            <li><a href="{{ links.privacy }}" target="_blank">Privacy</a></li>
            <li><a href="{{ links.cookies }}" target="_blank">Cookies</a></li>
          </ul>
        </div>
      </div>
    </div>

    <!-- RHS page split -->
    <a *ngIf="(hasBranding$ | async) === false" href="{{ links.featured }}" target="_blank" class="login-app__featured">
    </a>
  </section>
  <error-message></error-message>
</div>

<div id="divHover" class="hover ng-scope text-content" style="display: none;"></div>
