import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { TokenService } from '@surecloud/core-data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginReturnUrlHelper } from '../helpers/login-return-url.helper';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {

  constructor(
    private tokenService: TokenService,
    private loginReturnUrlHelper: LoginReturnUrlHelper
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.tokenService.getIsAuthenticated()
      .pipe(
        map(isAuthenticated => {
          if (isAuthenticated) {
            this.loginReturnUrlHelper.navigate();
            return false;
          }
          return true;
        })
      );
  }

}
