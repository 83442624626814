export interface IconConfig {
  name: string;
  fontSize: string;
  height: string;
  width: string;
  iconClass: string;
}

export const makeIconConfig = (partial: Partial<IconConfig> = {}): IconConfig => {
  const defaults: IconConfig = {
    name: '',
    fontSize: undefined,
    height: '',
    width: '',
    iconClass: '',
  };
  return { ...defaults, ...partial as IconConfig };
};
