import { Component, OnInit, ViewChild } from "@angular/core";
import { ErrorModalFacade } from "../../+state/error-modal.facade";
import { ErrorModalComponent } from "./../modal/error-modal.component";

@Component({
  selector: "error-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.scss"],
})
export class ErrorMessageComponent implements OnInit {
  currentError$ = this.errorModalFacade.error$;

  @ViewChild(ErrorModalComponent, { static: true })
  modal: ErrorModalComponent;

  constructor(
    private errorModalFacade: ErrorModalFacade
  ) {
  }

  ngOnInit() {
    this.errorModalFacade
      .selectError()
      .subscribe(() => {
        this.showModal();
      });
  }

  showModal() {
    this.modal.show();
    return false;
  }

  closeModal() {
    this.errorModalFacade.reset();
    this.modal.hide();
  }
}
