<error-modal #errorDialog>
  <div class="modal-dialog modal-sm app-modal-content">
    <div class="modal-content">
      <div class="modal-sc-content">
        <div class="modal-header">
          <button aria-label="close" type="button" class="close" (click)="closeModal()">&times;</button>
          <h4 class="modal-title">Sorry, an error has occurred.</h4>
        </div>
        <div class="modal-body">
          <div class="c8-error" [innerHTML]="currentError$ | async"></div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-grey" (click)="closeModal()">Ok</button>
        </div>
      </div>
    </div>
  </div>
</error-modal>
