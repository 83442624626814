export function getPasswordValidationError(password: string): string {
  if (password.search(new RegExp(/^(.*)([a-z]+)(.*)$/)) == -1 ||
    password.search(new RegExp(/^(.*)([A-Z]+)(.*)$/)) == -1) return INVALID_PASSWORD_MSG_CASE;
  else if (password.search(new RegExp(/^(.*)([0-9]+)(.*)$/)) == -1) return INVALID_PASSWORD_MSG_NO_DIGIT;
  else if (password.search(new RegExp(/^(.*)([^A-Za-z\d\s])(.*)$/)) == -1) return 'Password does not contain a character which is neither alphabetical or a number.';
  else if (password.match(new RegExp('.*(.)\\1{2,}.*'))) return 'Password contains more than 2 identical characters in a row.';
  else return '';
}

export const INVALID_PASSWORD_MSG_CASE = 'Password does not contain a uppercase and a lowercase letter';
export const INVALID_PASSWORD_MSG_NO_DIGIT = 'Password does not contain a digit.';
export const INVALID_PASSWORD_MSG_NO_SYMBOL = 'Password does not contain a character which is neither alphabetical or a number.';
export const INVALID_PASSWORD_MSG_REPEATED_CHAR = 'Password contains more than 2 identical characters in a row.';
