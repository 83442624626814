import { FILE_UPLOAD_ENDPOINT, MULTI_FILE_UPLOAD_ENDPOINT } from "../constants";
import { UploadFileResponseBody } from "../modules/uploadFile";
import { fetchRequest } from "./fetch-request";

class UploadService {
  constructor() {
  }

  upload(formData: FormData): Promise<UploadFileResponseBody | string> {
    return fetchRequest<UploadFileResponseBody>({ url: FILE_UPLOAD_ENDPOINT, method: 'POST', body: formData }, '')
      .then((body: UploadFileResponseBody) => {
        if (body.error) {
          return Promise.reject(body.error);
        }
        return body;
      });
  }
}

export const uploadService = new UploadService();
