export const API = 'restful/v1';
export const FILE_UPLOAD_ENDPOINT = `${API}/upload`;
export const MULTI_FILE_UPLOAD_ENDPOINT = `${FILE_UPLOAD_ENDPOINT}/multiFile`;
export const ASSOCIATION_PAIRS_ENDPOINT = `${API}/association-pairs`;
export const ASSOCIATION_OFFERS_ENDPOINT = `${API}/association-offers`;
export const RESPONSE_ASSOCIATIONS_ENDPOINT = `${API}/response-associations`;
export const RESPONSE_ASSOCIATION_PAIRS_ENDPOINT = `${API}/response-association-pairs`;
export const RESPONSES_ENDPOINT = `${API}/responses`;
export const TEMPLATES_ENDPOINT = `${API}/templates`;
export const FORMS_ENDPOINT = `${API}/forms`;
export const SHORTCUTS_ENDPOINT = `${API}/shortcuts`;
export const FORM_RESPONSE_ENDPOINT = `${API}/formResponse`;
