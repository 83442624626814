import { Tile, TileIdent } from '@surecloud/api-interfaces';
import { IconConfig, UITile } from '../..';

export interface TileGroup {
  key: string | boolean;
  values: UITile[];
}

export enum TileLocation {
  Search = 'SEARCH',
  Recents = 'RECENTS',
  Favourites = 'FAVOURITES',
  Favorites = 'FAVOURITES',
  Dashboards = 'DASHBOARDS',
  Applications = 'APPLICATION_GROUPS',
  Application = 'APPLICATION_GROUP',
  CyberApplicationGroup = 'CYBER_APPLICATION_GROUP',
}

export const tileLoctationToIdParamName: { [key: string]: string; } = {
  'APPLICATION_GROUP': 'applicationGroupId',
  'CYBER_APPLICATION_GROUP': 'parentApplicationId',
};

export const tileIdentToLocationMap: { [key: string]: string; } = {
  'favourites': TileLocation.Favourites.toString(),
  'favorites': TileLocation.Favorites.toString(),
  'recents': TileLocation.Recents.toString(),
  'dashboards': TileLocation.Dashboards.toString(),
  'applications': TileLocation.Applications.toString(),
  'application': TileLocation.Application.toString(),
};

export interface TileSearchResult {
  applicationId?: number;
  colour: string;
  dashboardId?: number;
  identifier: TileIdent;
  questionnaireKey?: number;
  tileGroup: string;
  title: string;
  uiStandardKey?: number;
}

export interface SimpleTile {
  colour: string;
  identifier?: TileIdent;
  groupName: string;
  title: string;
  icon?: IconConfig;
  badgeLabel?: string;
  applicationGroupId?: number;
  dashboardId?: number;
  isSkeleton?: boolean;
  originalTile: Tile;
}
