// @ts-nocheck
import { oAjax } from "../modules/oAjax";
import { browser } from '../modules/oBrowser';
import { getLocalToken } from '@surecloud/utils';
import { APP_SHELL_CONTENT_ID } from '../modules/toolbar-helper';

export const surecloudChartLoad = () => {
  //Chris Tandy
  // http://closure-compiler.appspot.com/home

  $(function () {
    $.extend($.jgrid, $.jgrid.regional["en"]);

    if (typeof $.sc_support_canvas === "undefined")
      $.sc_support_canvas = !!document.createElement("canvas").getContext;

    if (typeof $.sc_unique_element === "undefined")
      $.sc_unique_element = function (e, p) {
        var n = Math.floor(Math.random() * 100000000);
        var el = document.getElementById(p + n);
        while (el != null) {
          n = Math.floor(Math.random() * 100000000);
          el = document.getElementById(p + n);
        }
        return $(e).attr("id", p + n);
      };

    if (typeof $.sc_stopPropagation === "undefined")
      $.sc_stopPropagation = function (event) {
        if (event && event.stopPropagation) {
          event.stopPropagation();
        }
        else if (window.event) {
          window.event.cancelBubble = true;
        }
      };

    if (typeof $.sc_locale === "undefined")
      $.sc_locale = function (locale) {
        var value;
        if (locale) {
          value = $.datepicker.regional[locale.replace("_", "-")];
          if (!value) {
            value = $.datepicker.regional[locale.substring(0, 2)];
          }
        }
        if (!value) {
          return $.datepicker.regional["en-GB"];
        }
        return value;
      };

    $.scPosSearchPopup = function (box) {
      var id = box.attr("id");
      $.sc_position_abs(
        $(id.replace("fbox_", "#searchmodfbox_")),
        $(id.replace("fbox_", "#gview_")),
        10,
        10
      );
      return true;
    };
    $.scCloseSearchPopup = function () {
      $("#editmodlist").attr({
        style:
          "width: auto; height: auto; z-index: 1002; overflow: hidden; top: 4px; left: 4px; display: block;",
      });
    };

    $.scMultipleSearchOptions = {
      closeAfterSearch: true,
      multipleSearch: true,
      multipleGroup: false,
      top: $(document).height() / 2 - 50,
      left: $(document).width() / 2 - 200,
      resize: false,
      width: 650,
      beforeShowSearch: $.scPosSearchPopup,
      onClose: $.scCloseSearchPopup,
    };

    if (typeof $.scResizeJqGrid === "undefined")
      $.scResizeJqGrid = function (g, c) {
        var p = c ? $(c) : $(g).closest(".ui-jqgrid").parent();
        var hh = p.find(".ui-jqgrid-hdiv").height() || 0;
        var ph = p.find(".ui-jqgrid-pager").height() || 0;
        if (ph > 0) ph += 2;
        p.css({ overflow: "hidden" });
        $(g).setGridWidth(p.width(), true);
        $(g).setGridHeight(p.height() - hh - ph, true);
      };

    if (typeof $.sc_radioButtonDD === "undefined") {
      $.sc_radioButtonDD = function (parent, name, key, label, checked, id) {
        var cb = $('<input type="radio"/>')
          .attr("id", id != undefined ? id : key)
          .attr("name", name)
          .attr("value", key)
          .prop("checked", checked);
        parent.append(
          cb,
          $("<label/>")
            .attr("for", id != undefined ? id : key)
            .append("<span></span>" + label)
        );
        return cb;
      };
    }

    if (typeof $.sc_checkBoxDD === "undefined")
      $.sc_checkBoxDD = function (parent, name, key, label, checked) {
        var cb = $('<input type="checkbox"/>')
          .attr("id", key)
          .attr("name", name)
          .attr("value", key)
          .prop("checked", checked);
        parent.append(
          cb,
          $("<label/>")
            .attr("for", key)
            .append("<span></span>" + label)
        );
        return cb;
      };

    if (typeof $.sc_radioButton === "undefined")
      $.sc_radioButton = function (parent, name, key, label, checked) {
        var cb = $('<input type="radio"/>')
          .attr("role", "button")
          .attr("tabindex", "0")
          .attr("id", key)
          .attr("name", name)
          .attr("value", key)
          .prop("checked", checked);
        parent.append(
          cb,
          $("<label/>").attr("for", key).append("<span/>"),
          label ? $("<label/>").attr("for", key).text(label) : null
        );
        return cb;
      };

    if (typeof $.sc_checkBox === "undefined")
      $.sc_checkBox = function (parent, name, key, label, checked) {
        if (!name) name = "cbx" + Math.floor(Math.random() * 100000000);
        var cb = $('<input type="checkbox"/>')
          .attr("id", name)
          .attr("name", name)
          .attr("value", key)
          .prop("checked", checked);
        parent.append(
          cb,
          $("<label/>").attr("for", name).append("<span/>", label)
        );
        return cb;
      };

    if (typeof $.sc_checkBoxWrap === "undefined")
      $.sc_checkBoxWrap = function (ui) {
        ui.wrap($('<label class="ei-check"/>'));
        ui.after("<span/>");
      };

    if (typeof $.sc_checkBoxWrapNoClick === "undefined")
      $.sc_checkBoxWrapNoClick = function (ui) {
        ui.wrap($('<span class="ei-check"/>'));
        ui.after("<span/>");
      };

    if (typeof $.sc_smoothScroll === "undefined")
      $.sc_smoothScroll = function (name) {
        var target = $(name);
        target = target.length ? target : $("[name=" + name + "]");
        if (target.length) {
          const $appShellContent = $(`#${APP_SHELL_CONTENT_ID}`);
          $appShellContent.animate(
            {
              scrollTop: target.offset().top - 100,
            },
            500
          );
          return false;
        }
      };

    if (typeof $.sc_link === "undefined")
      $.sc_link = function (value) {
        try {
          var o = JSON.parse(value);
          if (o.icon) {
            if (o.url) {
              const link = $('<a>')
                .addClass('evidence-ico')
                .addClass(o.icon)
                .attr('href', o.url)
                .attr('target', '_blank')
                .attr('rel', 'noopener noreferrer')
                .attr('title', o.title);
              return link[0].outerHTML;
            }
            if (o.skey || o.qkey) {
              return (
                '<span class="drill evidence-ico ' +
                o.icon +
                '"' +
                (o.skey ? ' skey="' + o.skey + '"' : "") +
                (o.qkey ? ' qkey="' + o.qkey + '"' : "") +
                ' title="' +
                o.title +
                '"></span>'
              );
            }
            return (
              '<span class="evidence-ico ' +
              o.icon +
              '" title="' +
              o.title +
              '"></span>'
            );
          }
        } catch (err) {}
        return "";
      };

    if (typeof $.sc_position === "undefined")
      $.sc_position = function (
        element,
        relativeTo,
        offsetX,
        offsetY,
        callback
      ) {
        if (element.is(":hidden")) {
          element.offset({ top: -2000, left: 0 }).show();
        }
        //chrome fix
        window.setTimeout(function () {
          var p = $(relativeTo).offset();
          var w = $(window);
          var wh = w.height() - 23;
          var ww = w.width() - 58;
          var eh = element.height() + 10;
          var ew = element.width() + 10;

          if (p) {
            var o = {};
            o.left = Math.min(w.scrollLeft() + ww - ew, p.left + offsetX);
            if (o.left < p.left + offsetX) {
              offsetY += 20;
            }
            o.top = Math.min(w.scrollTop() + wh - eh - 20, p.top + offsetY);
            if (o.top < p.top + offsetY) {
              o.top = p.top - eh;
            }
            element.offset(o).show();
            element.find(":input:enabled:visible:first").focus();
          }
          else {
            var w = $(window);
            element
              .offset({
                top: Math.max(
                  w.scrollTop() + (wh - eh) / 2,
                  w.scrollTop() + 20
                ),
                left: Math.max(
                  w.scrollLeft() + (ww - ew) / 2,
                  w.scrollLeft() + 10
                ),
              })
              .show();
            element.find(":input:enabled:visible:first").focus();
          }
          if (callback) {
            callback.call(element);
          }
        }, 10);
      };

    if (typeof $.sc_position_over === "undefined")
      $.sc_position_over = function (
        element,
        relativeTo,
        offsetX,
        offsetY,
        callback
      ) {
        element.offset({ top: -2000, left: 0 }).show();
        //chrome fix
        window.setTimeout(function () {
          var p = $(relativeTo).offset();
          var w = $(window);
          var wh = w.height();
          var ww = w.width();
          var eh = element.height();
          var ew = element.width();

          if (p) {
            p.left = Math.max(
              w.scrollLeft(),
              Math.min(w.scrollLeft() + ww - ew, p.left + offsetX)
            );
            p.top = Math.max(
              w.scrollTop() + parseInt($("body").css("padding-top")),
              Math.min(w.scrollTop() + wh - eh - 20, p.top + offsetY)
            );

            element.offset(p).show();
            element.find(":input:enabled:visible:first").focus();
          }
          if (callback) {
            callback.call(element, p);
          }
        }, 10);
      };

    if (typeof $.sc_position_abs === "undefined")
      $.sc_position_abs = function (element, relativeTo, offsetX, offsetY) {
        //chrome fix
        window.setTimeout(function () {
          var p = $(relativeTo).offset();
          if (p) {
            p.top += offsetY;
            p.left += offsetX;
            element.offset(p).show();
          }
          else {
            var w = $(window);
            element
              .offset({
                top: w.scrollTop() + (w.height() - element.height()) / 2,
                left: w.scrollLeft() + (w.width() - element.width()) / 2,
              })
              .show();
            element.find(":input:enabled:visible:first").focus();
          }
        }, 10);
      };

    try {
      $.ui.dialog.prototype.closeOriginal = $.ui.dialog.prototype.close;
      $.ui.dialog.prototype.close = function () {
        if (hover) {
          hover.hide();
        }
        return $.ui.dialog.prototype.closeOriginal.call(this);
      };
    } catch (err) {}
  });

  $(function () {
    $.widget("custom.scBase", {
      prep: function () {
        var self = this;
        self.element
          .on("scLayout:ReDrawPortlet", function (evt, force) {
            self.reDraw.call(self, force);
          })
          .on("scLayout:RefreshPortlet", function (evt, force) {
            self.refresh.call(self, force);
          });
      },

      Strip: function (object) {
        var pdp = {};
        $.each(object, function (key, value) {
          if (typeof value !== "function" && typeof value !== "object")
            pdp[key] = value;
        });
        return pdp;
      },

      PostError: function (jqXHR, textStatus, errorThrown) {
        //alert(textStatus + ", " + errorThrown);
        if (jqXHR.status === 454) {
          //session timeout;
          document.cookie = "expiry=true";
          browser.reload();
        }
        else {
          var emsg = {
            JQueryError: true,
            error: "javascript",
            file: "surecloud.chart.js",
          };
          if (this.options && this.options.scPortletData)
            emsg.line =
              this.options.scPortletData.PortletKey +
              ", " +
              this.options.scPortletData.Title;
          emsg.scCsrf = getLocalToken();
          if (jqXHR)
            emsg.javascriptError =
              "JQuery ajax post error (not reported to user):" +
              jqXHR.status +
              ", " +
              jqXHR.statusText +
              ", " +
              errorThrown;
          else
            emsg.javascriptError =
              "JQuery ajax post error (not reported to user):" + errorThrown;

          try {
            var a = $.map($.parseHTML(jqXHR.responseText), function (v) {
              if ($(v).get(0).tagName === "P") return v.innerText;
              else return null;
            });
            if (a && a.length > 0) emsg.javascriptError += "\n" + a.join("\n");
            else emsg.javascriptError += "\n" + jqXHR.responseText;
          } catch (err) {}

          $.post("jqueryajax", emsg);
        }
      },

      MsgBox: function (parent, data) {
        var popDiv = $("<div/>").text(data.msg);
        $(parent).append(popDiv);

        popDiv.dialog({
          title: data.title,
          autoOpen: true,
          closeOnEscape: true,
          width: "400px",
          modal: true,
          buttons: data.buttons,
          close: function (event, ui) {
            $(this).dialog("destroy").remove();
          },
        });
      },

      deToken: function (text) {
        if (text && this.options.scPortletData) {
          var self = this;
          return text.replace(/\{(\w+)\}/, function (match, key) {
            return self.options.scPortletData[key];
          });
        }
      },

      _showProcessing: function () {
        this._hideProcessing();
        $(this.element[0]).append(
          $("<div/>")
            .addClass("sc-portlet-loading")
            .append($("<div/>").addClass("ei-loader"))
        );
      },

      _hideProcessing: function () {
        $(this.element[0]).find(".sc-portlet-loading").remove();
      },
    });
  });

  $(function () {
    $.widget("custom.scLayout", $.custom.scBase, {
      options: {
        scRow: null,
        numColumns: 3,
        minWidth: 400,
        width1: 400,
        width2: 400,
        width3: 400,
        column1: null,
        column2: null,
        column3: null,
        resizeFn: null,
        resizeTimer: null,
      },

      // the constructor
      _create: function () {
        this.build();
      },
      build: function () {
        var minWidth = this.options.minWidth;
        var p = this.element;
        p.empty();
        this.options.column1 = null;
        this.options.column2 = null;
        this.options.column3 = null;
        p.addClass("sc-layout");
        this.options.scRow = $("<div/>");
        p.append(this.options.scRow);
        this.setNumColumns(this.options.numColumns);

        var self = this;
        $(window).resize(function () {
          if (self.options.resizeTimer) {
            window.clearTimeout(self.options.resizeTimer);
          }
          self.options.resizeTimer = window.setTimeout(function () {
            self.resize.call(self);
          }, 500);
        });
      },

      setNumColumns: function (numColumns) {
        if (this.options.numColumns != numColumns) {
          switch (numColumns) {
            case 1:
              this.movePortlets(this.options.column2, [this.options.column1]);
              this.movePortlets(this.options.column3, [this.options.column1]);
              break;
            case 2:
              this.movePortlets(this.options.column3, [
                this.options.column1,
                this.options.column2,
              ]);
              break;
          }
        }

        this.options.numColumns = numColumns;
        switch (numColumns) {
          case 1:
            this.addColumn1();
            this.removeColumn2();
            this.removeColumn3();
            break;
          case 2:
            this.addColumn1(this.options.width1);
            this.addColumn2();
            this.removeColumn3();
            break;
          case 3:
            this.addColumn1(this.options.width1);
            this.addColumn2(this.options.width2);
            this.addColumn3();
            break;
        }
        this.balancePortlets(numColumns);
        this.resize();
      },
      movePortlets: function (source, target) {
        var i = 0;
        $(source)
          .children()
          .each(function () {
            $(this).appendTo(target[i++ % target.length]);
          });
      },
      balancePortlets: function (numColumns) {
        var o = this.options;
        var c = [];
        if (o.column1) {
          c.push({ column: o.column1 });
        }
        if (o.column2) {
          c.push({ column: o.column2 });
        }
        if (o.column3) {
          c.push({ column: o.column3 });
        }

        $.each(c, function (k, v) {
          v.children = [];
          v.column.children().each(function (k, child) {
            v.children.push(child);
          });
          v.count = v.children.length;
          v.last = v.children[v.count - 1];
        });

        while (true) {
          var longest = null;
          var shortest = null;
          var longLength = 0;
          var shortLength = 1000000;
          for (var i = 0; i < c.length; i++) {
            if (c[i].count > longLength) {
              longLength = c[i].count;
              longest = i;
            }
            if (c[i].count < shortLength) {
              shortLength = c[i].count;
              shortest = i;
            }
          }
          if (longLength - shortLength > 1 && longest != shortest) {
            $(c[longest].last).appendTo($(c[shortest].column));
            c[longest].count--;
            c[shortest].count++;
            c[longest].last = c[longest].children[c[longest].count - 1];
          }
          else {
            break;
          }
        }
      },

      resize: function () {
        if (this.element.parent().length > 0) {
          switch (this.options.numColumns) {
            case 1:
              this.resize1();
              break;
            case 2:
              this.resize2();
              break;
            case 3:
              this.resize3();
              break;
          }
          this.element.trigger("scLayout:SaveState");
        }
      },

      resize1: function () {
        var o = this.options;
        var pw = this.element.parent().width();
        var w1 = Math.max(this.options.minWidth, pw);

        o.width1 = this.resizeColumn(o.column1, w1, 1);
        //now turn off size for last column
        //o.column1.css({ "min-width": "", "max-width": "", width: "" });
      },
      resize2: function () {
        var o = this.options;
        var w = this.calcWidths(
          this.element.parent().width() - this.element.find("#sizerA").width(),
          o.minWidth,
          [o.width1, o.width2]
        );

        o.width1 = this.resizeColumn(o.column1, w[0], 1);
        o.width2 = this.resizeColumn(o.column2, w[1], 2);
        //now turn off size for last column
        o.column2.css({ "min-width": "", "max-width": "", width: "" });
      },
      resize3: function () {
        var o = this.options;
        var w = this.calcWidths(
          this.element.parent().width() -
          (this.element.find("#sizerA").width() +
            this.element.find("#sizerB").width()),
          o.minWidth,
          [o.width1, o.width2, o.width3]
        );

        o.width1 = this.resizeColumn(o.column1, w[0], 1);
        o.width2 = this.resizeColumn(o.column2, w[1], 2);
        o.width3 = this.resizeColumn(o.column3, w[2], 3);
        //now turn off size for last column
        o.column3.css({ "min-width": "", "max-width": "", width: "" });
      },
      resizeColumn: function (column, width, idx) {
        if (width != column.width()) {
          //column.find('.portlet').width(width);
          column.css({ "min-width": width, "max-width": width }).width(width);
          column.find(".scForceWidth").each(function (k, v) {
            $(v).trigger("scPortlet:forceWidth", width);
          });
        }
        return width;
      },
      calcWidths: function (tw, mw, w) {
        for (let i = w.length; i >= 0; i--) {
          var t = 0;
          for (let j = 0; j < w.length; j++) {
            if (w[j] < mw) w[j] = mw;
            t += w[j];
          }
          if (t <= tw) {
            w[i] += tw - t;
            break;
          }
          if (i > 0) {
            w[i - 1] += tw - t;
          }
        }
        return w;
      },

      addColumn1: function (width) {
        if (!this.options.column1) {
          this.options.column1 = this.getColumn();
          this.options.scRow.append(this.options.column1);
        }
      },
      addColumn2: function (width) {
        if (!this.options.column2) {
          this.addSizer("sizerA");
          this.options.column2 = this.getColumn();
          this.options.scRow.append(this.options.column2);
        }
      },
      addColumn3: function (width) {
        if (!this.options.column3) {
          this.addSizer("sizerB");
          this.options.column3 = this.getColumn();
          this.options.scRow.append(this.options.column3);
        }
      },

      getColumn: function () {
        var self = this;
        return $("<div/>")
          .addClass("column")
          .sortable({
            distance: 20,
            connectWith: ".column",
            handle: ".sc-widget-dragable",
            stop: function (event, ui) {
              self.element.trigger("scLayout:SaveState");
            },
          });
      },

      removeColumn2: function () {
        this.element.find("#sizerA").remove();
        if (this.options.column2) {
          this.options.column2.remove();
        }
        this.options.column2 = null;
      },
      removeColumn3: function () {
        this.element.find("#sizerB").remove();
        if (this.options.column3) {
          this.options.column3.remove();
        }
        this.options.column3 = null;
      },

      newOffset: function (cell, offset, startpos) {
        var minWidth = this.options.minWidth;
        var ret = {
          prevCell: cell.prev(),
          nextCell: cell.next(),
          move: offset - startpos,
        };
        ret.prevWidth = ret.prevCell.width() + ret.move;
        ret.nextWidth = ret.nextCell.width() - ret.move;
        if (ret.prevWidth < minWidth) {
          ret.move += minWidth - ret.prevWidth;
        }
        else if (ret.nextWidth < minWidth) {
          ret.move -= minWidth - ret.nextWidth;
        }
        ret.prevWidth = ret.prevCell.width() + ret.move;
        ret.nextWidth = ret.nextCell.width() - ret.move;
        return ret;
      },

      addSizer: function (sizerID) {
        var self = this;
        this.options.scRow.append(
          $("<div/>")
            .addClass("sizer")
            .attr("id", sizerID)
            .draggable({
              containment: "parent",
              startPos: 0,
              revert: true,
              revertDuration: 0,
              start: function (event, ui) {
                $(event.target).css({ "background-color": "#BBB" });
                this.startPos = ui.offset.left;
              },
              drag: function (event, ui) {
                ui.position.left = self.newOffset(
                  $(event.target),
                  ui.offset.left,
                  this.startPos
                ).move;
              },
              stop: function (event, ui) {
                $(event.target).css({ "background-color": "" });
                var o = self.newOffset(
                  $(event.target),
                  ui.offset.left,
                  this.startPos
                );
                if ($(this).attr("id") == "sizerA") {
                  self.options.width1 += o.move;
                  self.options.width2 -= o.move;
                }
                else if ($(this).attr("id") == "sizerB") {
                  self.options.width2 += o.move;
                  self.options.width3 -= o.move;
                }
                self.resize();
              },
            })
        );
      },
    });
  });

  $(function () {
    $.widget("custom.scContainer", $.custom.scBase, {
      // default options
      options: {
        scPortletKey: "-",
        scContainerKey: "-",
        scParent: null,
        scLayout: null,
        scLayoutState: null,
        scContentUrl: "",
        scNumColumns: 2,
        scPortlets: [],
        scPortletDivs: [],
        scColumns: [],
        scCSSclass: "",
        scConfig: true,
        scConfigAbove: true,
      },

      // the constructor
      _create: function () {
        this.options.scParent = this.element[0];

        if (this.options.legacyDashboard) {
          this.options.legacyLoaded = false;
        }
        else {
          var nc = this.options.scNumColumns;
          if (this.options.scState && this.options.scState.NumColumns > 0)
            nc = this.options.scState.NumColumns;
          this.PopulateContainer(nc);
          this.ShowMenuConfig();
        }
      },

      loadIfRequired: function (menuUL) {
        var self = this;
        self.options.menuUL = menuUL;
        if (self.options.legacyLoaded) {
          self.options.scLayout.trigger("scLayout:ReDrawDashboard", true);
          self.ShowMenuConfig(menuUL);
        }
        else {
          $(this.options.scParent).height(200);
          //$.consoleLog("scContainer : loadIfRequired : " + restfulAPIVersion + 'dashboards/legacy' + ", scCsrf:" + self.options.scCsrf + ", legacyDashboard:" + JSON.stringify(self.options))
          var ld = {
            legacyRequest: self.options.legacyRequest,
            legacyDashboard: self.options.legacyDashboard,
            Key: self.options.Key,
          };
          $.scAjax(
            restfulAPIVersion + "dashboards/legacy",
            "GET",
            self.options.scCsrf,
            { legacyDashboard: JSON.stringify(ld) },
            function (data) {
              $.extend(self.options, data);
              var nc = self.options.scNumColumns;
              if (self.options.scState && self.options.scState.NumColumns > 0)
                nc = self.options.scState.NumColumns;
              self.PopulateContainer(nc);
              self.ShowMenuConfig(menuUL);
              self.options.legacyLoaded = true;
            },
            this.options.scParent
          );
        }
      },

      ShowMenuConfig: function (menuUL) {
        if (this.options.scConfig === false) {
          return;
        }

        var self = this;

        if (menuUL) {
          $("#menuDashboardConfig").hide();
          self.options.menuUL = menuUL;
          menuUL.closest("ul.customise").show();
        }
        else {
          var m = $("#menuDashboardConfig");
          menuUL = m.find("ul.menulist");
          m.unbind("click").click(function (event) {
            event.stopPropagation();
            $(this).toggleClass("open");
            $(this).find("div.scrollbar").css({ overflow: "auto" });
          });
          m.show();
          self.options.menuUL = menuUL;
        }
        $(menuUL).empty();
        if (self.options.mnuConfig && self.options.mnuConfig.items) {
          $.each(self.options.mnuConfig.items, function (k, item) {
            if (item && !item.disabled) {
              menuUL.append(
                $("<li/>").text(item.label).attr("idx", k).click(item.action)
              );
            }
            else if (item == null) {
              menuUL.append($("<li/>").addClass("break"));
            }
          });
        }
      },

      PopulateContainer: function (nc, ns) {
        var self = this;
        if (this.options.scLayout) {
          return;
        }
        ns = this.options.scState;
        var w1 = ns.width1
          ? ns.width1
          : ns.west && ns.west.size
            ? ns.west.size
            : 400;
        var w2 = ns.width2 ? ns.width2 : 400;
        var w3 = ns.width3
          ? ns.width3
          : ns.east && ns.east.size
            ? ns.east.size
            : 400;

        this.options.scLayout = $(this.options.scParent)
          .scLayout({ numColumns: nc, width1: w1, width2: w2, width3: w3 })
          .on("scLayout:SaveState", function (evt, layout) {
            self.saveState();
          })
          .on("scLayout:ReDrawDashboard", function (evt, force) {
            self.ShowMenuConfig(self.options.menuUL);
          });

        var cols = this.options.scLayout.scLayout("option");
        var cl = cols.column1;
        var cc = cols.column2;
        var cr = cols.column3;

        var cMap = [];
        cMap[0] = cl;
        cMap[1] = cc;
        cMap[2] = cr;
        this.options.cMap = cMap;

        var n = 0;

        //Add config
        if (this.options.scConfig) {
          if (this.options.scState && this.options.scState.portlets) {
            //load saved state
            $.each(this.options.scState.portlets, function (key, value) {
              if (value.pcKey && self.options.scPortlets[value.pcKey]) {
                self.addPortlet(
                  self.options.scPortlets[value.pcKey],
                  value,
                  cMap[value.pcColumn || 0]
                );
                n++;
              }
            });
          }

          var objConfig = {};
          objConfig.title = "Configuration";
          objConfig.items = [];

          objConfig.items.push({
            portlet: null,
            label: "Refresh Data",
            icon: null,
            idx: n,
            action: function () {
              self.options.scLayout.trigger("scLayout:RefreshCache");
            },
          });

          objConfig.items.push(null);

          $.each(
            ["1 Column layout", "2 Column layout", "3 Column layout"],
            function (key, value) {
              objConfig.items.push({
                portlet: null,
                label: value,
                icon: null,
                idx: n,
                action: function () {
                  self.options.scLayout.scLayout("setNumColumns", key + 1);
                },
              });
            }
          );

          objConfig.items.push(null);

          $.each(this.options.scPortlets, function (key, value) {
            var label =
              value.scPortletData && value.scPortletData.piMenu
                ? value.scPortletData.piMenu
                : value.scTitle;
            objConfig.items[value.pcOrder + 6] = {
              portlet: value,
              label: label,
              icon: null,
              idx: n,
              action: function () {
                var idx = $(this).attr("idx");
                self.addPortlet(
                  objConfig.items[parseInt(idx)].portlet,
                  {},
                  self.options.cMap[0]
                );
                self.save(true);
              },
            };
          });

          objConfig.items.push(null);

          objConfig.items.push({
            portlet: null,
            label: "Reset Layout and Graph Options",
            icon: null,
            idx: n,
            action: function () {
              self.MsgBox(self.options.scParent, {
                title: "Reset Dashboard:",
                msg:
                  "You are about to reset all of the configuration information for this dashboard. Do you wish to continue?",
                buttons: {
                  Yes: function () {
                    var url =
                      "jqueryajax/content/" +
                      self.options.scPortletKey +
                      "/save/" +
                      Math.random();
                    $.post(url, {
                      scCsrf: getLocalToken(),
                      State: "{}",
                      ContainerKey: self.options.scContainerKey,
                    }).always(function () {
                      tabs.tab.menu.breadCrumb.select({});
                    });
                    $(this).dialog("close");
                  },
                  No: function () {
                    $(this).dialog("close");
                  },
                },
              });
            },
          });

          this.options.mnuConfig = objConfig;

          //default, load all portlets
          if (n === 0)
            $.each(objConfig.items, function (key, value) {
              if (value && value.portlet && value.portlet.pcDefaultShow)
                self.addPortlet(value.portlet, {}, cMap[n++ % nc]);
            });
        }
        else
          $.each(this.options.scPortlets, function (key, value) {
            var o = {};
            if (self.options.scState && self.options.scState.portlets) {
              //find saved state
              $.each(self.options.scState.portlets, function (k, v) {
                if (key === v.pcKey) o = v;
              });
            }

            self.addPortlet(value, o, cMap[n++ % nc]);
          });
      },

      addPortlet: function (portletDef, portletState, column) {
        var pl = $("<div/>");
        var pp = $.extend({}, portletDef, {
          scContainer: this,
          scState: portletState,
          scLayout: this.options.scLayout,
        });

        this.options.scPortletDivs.push(pl);
        column.append(pl);
        $(pl).scPortlet(pp);
      },

      saveState: function () {
        var self = this;
        if (self.options.saveStateTimer) {
          window.clearTimeout(self.options.saveStateTimer);
        }
        self.options.saveStateTimer = window.setTimeout(function () {
          self.save.call(self);
        }, 500);
      },

      save: function () {
        $.consoleLog(
          "*********************************************************************************************************************************"
        );
        $.consoleLog("DASHBOARD V1 Save State: ");

        var lo = this.options.scLayout.scLayout("option");

        //Save State
        var State = { west: { size: 400 }, east: { size: 400 } }; // = $.extend(true, {}, this.options.scLayout.state);
        State.west.size = lo.width1;
        State.east.size = lo.width3;
        State.width1 = lo.width1;
        State.width2 = lo.width2;
        State.width3 = lo.width3;
        State.NumColumns = lo.numColumns;

        State.portlets = [];
        var intCol = 0;
        var intPortlet = 0;
        $(this.options.scParent)
          .find(".column")
          .each(function () {
            $(this)
              .children(".portlet")
              .each(function () {
                var p = $(this).scPortlet("getState");
                if (p) {
                  State.portlets[intPortlet++] = $.extend(true, p, {
                    pcColumn: intCol,
                  });
                }
              });
            intCol++;
          });

        if (
          intPortlet > 0 &&
          !this.compare(this.options.scLayoutState, State)
        ) {
          this.options.scLayoutState = State;
          var url =
            "jqueryajax/content/" +
            this.options.scPortletKey +
            "/save/" +
            Math.random();
          //Save State;
          if (window.JSON)
            $.post(
              url,
              {
                scCsrf: getLocalToken(),
                State: JSON.stringify(State),
                ContainerKey: this.options.scContainerKey,
              },
              function () {
                $.consoleLog("DASHBOARD V1 Save Complete: ");
              }
            );
        }
        return State;
      },

      compare: function (x, y) {
        if (!x || !y) return false;
        var p;
        for (p in y) {
          if (typeof x[p] === "undefined") {
            return false;
          }
        }

        for (p in y) {
          if (y[p]) {
            switch (typeof y[p]) {
              case "object":
                if (!this.compare(y[p], x[p])) {
                  return false;
                }
                break;
              default:
                if (y[p] !== x[p]) {
                  return false;
                }
            }
          }
          else {
            if (x[p]) return false;
          }
        }

        for (p in x) {
          if (typeof y[p] === "undefined") {
            return false;
          }
        }

        return true;
      },

      scRemove: function () {
        $(this.options.scParent).remove();
      },

      resize: function () {
        if (this.options.scLayout) {
          this.options.scLayout.trigger("scLayout:ReDrawDashboard");
        }
      },

      _destroy: function () {
        if (this.options.scLayout) {
          $(this.options.scParent)
            .children(".column")
            .each(function () {
              $(this)
                .children(".portlet")
                .each(function () {
                  $(this).remove();
                });
            });
          $(this.options.scParent).children().remove();

          this.options.scLayout = null;
          this.options.scLayoutState = null;
        }
      },
    });
  });

  $(function () {
    $.widget("custom.scPortlet", $.custom.scBase, {
      // default options
      options: {
        scParent: null,
        scContentUrl: "",
        scPortletKey: "",
        scPortletDiv: null,
        scPortletTitleDiv: null,
        scPortletContentDiv: null,
        scPortletConfig: {},
        scTitleSpan: null,
        scTitle: "",
        scTitleHTML: "",
        scPortletData: {},
        scWidth: "",
        scHeight: "",
        scHeader: true,
        scFold: false,
        scRefresh: false,
        scClose: true,
        scConfig: true,
        scResize: true,
        scDragable: true,
        scState: {},
      },
      size: { width: -1, height: -1 },

      // the constructor
      _create: function () {
        var self = this;

        if (self.options.scLayout) {
          self.options.scLayout
            .on("scLayout:SaveState", function (evt) {
              var size = {
                width: self.element.width(),
                height: self.element.height(),
              };
              if (
                self.size.width != size.width ||
                self.size.height != size.height
              ) {
                self.reDraw.call(self);
                self.size = size;
              }
            })
            .on("scLayout:ReDrawDashboard", function (evt, force) {
              var size = {
                width: self.element.width(),
                height: self.element.height(),
              };
              self.reDraw.call(self, force);
              self.size = size;
            })
            .on("scLayout:RefreshCache", function (evt) {
              self.refresh.call(self, true);
            });
        }

        if (!this.options.scConfigPos) this.options.scConfigPos = "hide";

        this.options.scParent = this.element[0];
        this.options.scContentUrl =
          "jqueryajax/content/" + this.options.scPortletKey;

        if (this.options.scState && this.options.scState.Height)
          this.options.scHeight = this.options.scState.Height;

        $(this.options.scParent).addClass(
          "portlet ui-widget ui-widget-content ui-helper-clearfix ui-corner-all"
        );

        if (!this.options.scDragable)
          $(this.options.scParent).addClass("ui-widget-no-shadow");

        if (!this.options.scState.Title)
          this.options.scState.Title = this.options.scTitle;
        if (self.options.scPortletData.fixTitle)
          this.options.scState.Title = self.options.scPortletData.fixTitle;
        $.extend(self.options.scPortletData, this.options.scState);

        if (this.options.scHeader) {
          this.options.scPortletTitleDiv = $("<div/>").addClass(
            "portlet-header ui-widget-header ui-corner-all"
          );
          this.options.scTitleSpan = $("<span/>", {
            text: this.options.scState.Title,
          }).css({ float: "left", "margin-right": "10px" });
          $(this.options.scTitleSpan).appendTo(this.options.scPortletTitleDiv);

          if (this.options.scDragable)
            $(this.options.scPortletTitleDiv).addClass("sc-widget-dragable");

          if (this.options.scTitleHTML || this.options.scTitleHTML !== "") {
            $("<span/>")
              .html(this.options.scTitleHTML)
              .appendTo(this.options.scPortletTitleDiv);
          }

          if (this.options.scTitleJQ)
            $.each(this.options.scTitleJQ, function (key, value) {
              self.options.scPortletTitleDiv.append(value);
            });

          var confDiv = $("<ul/>")
            .addClass(this.options.scState.Display === "none" ? "min" : "max")
            .mouseleave(function () {
              $(this).parent().removeClass("open");
            });
          var confHld = $("<div/>")
            .addClass("dash-conf")
            .hide()
            .click(function () {
              $(this).toggleClass("open");
            });
          confHld.append(confDiv);
          this.options.scPortletTitleDiv.append(confHld);

          if (this.options.scClose) {
            $("<li>Remove</li>")
              .addClass("max")
              .bind("click", function () {
                $(self.options.scParent).scPortlet("destroy");
                $(self.options.scParent).remove();
                if (self.options.scLayout) {
                  self.options.scLayout.trigger("scLayout:SaveState");
                }
              })
              .appendTo(confDiv);
            confHld.show();
          }

          if (this.options.scFold) {
            $("<li>Minimise</li>")
              .addClass("max")
              .bind("click", function () {
                $(this).parent().removeClass("max").addClass("min");
                $(this)
                  .parents(".portlet:first")
                  .find(".portlet-content")
                  .toggle();
                self.reDraw();
                self.element.trigger("scLayout:SaveState");
              })
              .appendTo(confDiv);
            $("<li>Restore</li>")
              .addClass("min")
              .bind("click", function () {
                $(this).parent().removeClass("min").addClass("max");
                $(this)
                  .parents(".portlet:first")
                  .find(".portlet-content")
                  .toggle();
                self.reDraw();
                self.element.trigger("scLayout:SaveState");
              })
              .appendTo(confDiv);
            confHld.show();
          }

          if (this.options.scRefresh) {
            $("<li>Refresh</li>")
              .addClass("max")
              .bind("click", function () {
                self.refresh.call(self, true);
              })
              .appendTo(confDiv);
            confHld.show();
          }

          //Config functionality ///////////////////////////
          self.options.divConfigOpen = false;

          self.options.onButtonClick = function (d, b) {
            let r = {};
            for (let p in self.options.scPortletData) if ((/^cont_\d+/g).test(p)) r[p] = p;
            for (let p in r) delete self.options.scPortletData[p];
            delete self.options.scPortletData["button"];

            var o = { button: b };
            var ord = 0;
            $(self.options.scPortletContentDiv)
              .find(".scValue")
              .each(function (index, data) {
                if ($(this)[0].checked) o["cont_" + $(this).val()] = ord++;
              });

            $.extend(d, o);
            d.UpdateData = b === "btnUpdate";

            $.extend(self.options.scState, d);
            $.extend(self.options.scPortletData, d);

            if (self.options.scTitleSpan && d.Title)
              $(self.options.scTitleSpan).text(self.deToken(d.Title));

            self.element.trigger("scLayout:SaveState");
            self.refresh(b === "btnUpdate");
            if (self.options.scConfigPos === "hide" && b === "btnUpdate")
              closeConfig(false);
          };

          var closeConfig = function (fo) {
            if (fo)
              $(self.options.divConfig)
                .animate({ top: 0 }, 0, function () {
                  $(self.options.scPortletContentDiv).css({
                    overflow: "visible",
                  });
                  $(self.options.scParent).css({ overflow: "visible" });
                })
                .show();
            else {
              $(self.options.scPortletContentDiv).css({ overflow: "hidden" });
              $(self.options.scParent).css({ overflow: "hidden" });
              $(self.options.divConfig).animate(
                { top: -$(self.options.divConfig).height() - 30 },
                0,
                function () {
                  $(self.options.divConfig).hide();
                }
              );
            }
            self.options.divConfigOpen = fo;
            return false;
          };

          var PopulateConfig = function (pos, formOptions) {
            var Options = {};
            if (pos !== "hide") {
              Options.scButtons = {
                Update: function (d) {
                  self.options.onButtonClick(d, "btnUpdate");
                },
              };
            }
            //
            // if (pos === 'hide')
            //     Options.scButtons["Cancel"] = function () { closeConfig(false); }

            if (formOptions) {
              self.options.scPortletConfig.form =
                formOptions.scPortletConfig.form;
              self.options.scPortletData = formOptions.scPortletData;
            }

            Options.scForm = self.options.scPortletConfig.form;
            Options.piData = self.options.scPortletData;

            Options.onButtonClick = self.options.onButtonClick;
            Options.piData.scContentUrl = self.options.scContentUrl;

            if (self.options.divConfig)
              $(self.options.divConfig).scForm("populateForm", Options);
          };

          var openConfig = function (pos, formOptions) {
            if (!self.options.divConfig) {
              self.options.divConfig = $("<div/>").addClass("scJqFormDiv");
              self.options.form = $(self.options.divConfig).scForm({});

              if (pos === "hide") {
                self.options.divConfig.dialog({
                  title: self.options.scState.Title + " Portlet Configuration",
                  height: 400,
                  width: 650,
                  modal: true,
                  autoOpen: true,
                  closeOnEscape: true,
                  position: { my: "center", at: "center", of: window },
                  buttons: {
                    Update: function () {
                      var configurationData = self.options.form.scForm(
                        "values"
                      );
                      self.options.onButtonClick(
                        configurationData,
                        "btnUpdate"
                      );
                      $(this).dialog("close");
                    },
                    Cancel: function () {
                      $(this).dialog("close");
                    },
                  },
                  close: function (event, ui) {
                    $(this).dialog("destroy").remove();
                    self.options.divConfig = null;
                  },
                });
              }
              else {
                self.options.divConfig.css({ padding: "6px 6px 6px 6px" });
              }
            }

            if (self.options.divConfig) {
              self.options.divConfig.append(
                $("<div/>")
                  .addClass("sc-portlet-loading")
                  .append($("<div/>").addClass("ei-loader"))
              );
            }
            setTimeout(function () {
              PopulateConfig(pos, formOptions);
              self.options.divConfig.find(".sc-portlet-loading").remove();
            }, 10);

            // if (pos === 'hide') {
            //     $(self.options.scPortletContentDiv).append(self.options.divConfig);
            //     self.options.divConfig.addClass("scJqFormDivHide");
            //     self.options.divConfig.css({ top: -$(self.options.divConfig).height() });
            //
            //     if (!$(self.options.divConfig).is(':animated'))
            //         window.setTimeout(function () { closeConfig(!self.options.divConfigOpen); }, 1);
            // }
            // else
            //     self.options.divConfig.css({ padding: '6px 6px 6px 6px' });
          };

          self.options.fnBuildConfig = PopulateConfig;

          if (this.options.scConfig) {
            var p = this.options.scConfigPos;
            if (p === "hide")
              $("<li>Configuration</li>")
                .addClass("max")
                .bind("click", function () {
                  openConfig(self.options.scConfigPos);
                })
                .appendTo(confDiv);
            else openConfig(p);
          }
          $(this.options.scParent).append(this.options.scPortletTitleDiv);
        }

        var contentSize = {};
        var rc;
        if (this.options.scWidth !== "")
          contentSize.width = this.options.scWidth;
        if (this.options.scHeight !== "")
          contentSize.height = this.options.scHeight;

        if (this.options.scPortletContentDiv === null) {
          this.options.scPortletContentDiv = $.sc_unique_element(
            "<div/>",
            "div"
          )
            .addClass("portlet-content")
            .css(contentSize);

          if (this.options.scConfigPos === "hide") {
            $(this.options.scParent).append(this.options.scPortletContentDiv);
            //if (this.options.scContainer.options.scConfig === true) {
            //    $(this.options.scPortletContentDiv).bind('contextmenu', function (e) {
            //        if (openConfig) openConfig(self.options.scConfigPos);
            //        e.preventDefault();
            //        return false;
            //    });
            //}
            rc = this.options.scPortletContentDiv;
          }
          else {
            this.options.scPortletContentHolder = $.sc_unique_element(
              "<div/>",
              "div"
            )
              .addClass("portlet-content-holder")
              .css(contentSize);
            $(this.options.scParent).append(
              this.options.scPortletContentHolder
            );

            this.options.scPortletContentDiv
              .addClass("ui-layout-center")
              .css({ "padding-left": 20 });
            this.options.divConfig.addClass("ui-layout-west");

            $(this.options.scPortletContentHolder).append(
              this.options.divConfig
            );
            $(this.options.scPortletContentHolder).append(
              this.options.scPortletContentDiv
            );

            $(this.options.scPortletContentHolder).layout({
              enableCursorHotkey: false,
              defaults: { minSize: 440 },
              west: { size: 440 },
              onresize: function () {
                self.reDraw(true);
              },
            });
            rc = this.options.scPortletContentHolder;
          }

          if (this.options.scHeader)
            $(this.options.scPortletContentDiv).addClass("scSquareTop");
          else
            $(this.options.scPortletContentDiv).css({
              "border-top-style": "none",
            });

          //$.extend(true, this.options.scPortletData, { scPortletKey: this.options.scPortletKey, scCsrf: this.options.scCsrf, scPortletOptions: Object(this.options) });
          this.options.piParent = this;

          //Strip tmp form data
          let r = {};
          for (p in self.options.scPortletData)
            if (/^cont_\d+/g.test(p)) r[p] = p;
          for (p in r) delete self.options.scPortletData[p];
          delete self.options.scPortletData["button"];

          this.options.scPortletData.scCsrf = getLocalToken();
          switch (this.options.scType) {
            case "scHtml":
              this.options.scPortletContentDiv
                .scHtml({ init: false })
                .scHtml("postCreate", this.options);
              break;

            case "scGrid":
              this.options.scPortletContentDiv
                .scGrid({ init: false })
                .scGrid("postCreate", this.options);
              break;

            case "scChartLine":
              this.options.scPortletContentDiv
                .scChartLine({ init: false })
                .scChartLine("postCreate", this.options);
              break;

            case "scLibrary":
              this.options.scPortletContentDiv
                .scLibrary({ init: false })
                .scLibrary("postCreate", this.options);
              break;

            case "scTreeContent":
              this.options.scPortletContentDiv
                .scTreeContent({ init: false })
                .scTreeContent("postCreate", this.options);
              break;

            case "scGantt":
              this.options.scPortletContentDiv
                .scGantt({ init: false })
                .scGantt("postCreate", this.options);
              break;
          }
        }
        else {
          $(this.options.scPortletContentDiv)
            .addClass("portlet-content")
            .css(contentSize);
          $(this.options.scParent).append(this.options.scPortletContentDiv);
        }

        if (this.options.scResize || this.options.scResize === "true") {
          rc.resizable({ handles: "s", minHeight: 100 })
            .bind("resize", function (ev) {
              //resize layout if required.
              if (
                $(self.options.scPortletContentHolder).hasClass(
                  "ui-layout-container"
                )
              ) {
                self.options.scPortletContentDiv.height(
                  $(self.options.scPortletContentHolder).height()
                );
                self.options.divConfig.height(
                  $(self.options.scPortletContentHolder).height()
                );
                $(self.options.scPortletContentHolder).layout("resizeAll");
              }
              self.reDraw();
              ev.stopPropagation();
            })
            .bind("resizestop", function (event, ui) {
              //self.options.scPortletContentDiv.css("width", "auto");
              self.element.trigger("scLayout:SaveState");
            });
        }

        if (
          this.options.scHeader &&
          this.options.scState &&
          this.options.scState.Display === "none"
        ) {
          $(this.options.scParent)
            .find(".ui-icon-minusthick")
            .toggleClass("ui-icon-minusthick")
            .toggleClass("ui-icon-plusthick");
          $(this.options.scPortletContentDiv).toggle();
        }
      },

      getState: function () {
        var state = this.options.scState;
        state.PortletKey = this.options.scPortletKey;
        state.Height = $(this.options.scPortletContentDiv).height();
        state.Display = $(this.options.scPortletContentDiv).css("display");
        state.scContIndex = this.options.scContIndex;
        state.pcKey = this.options.pcKey;

        return state;
      },

      reDraw: function (force) {
        if (this.options.scPortletContentDiv) {
          $(this.options.scPortletContentDiv[0]).trigger(
            "scLayout:ReDrawPortlet",
            force
          );
        }
      },

      refresh: function (force) {
        if (this.options.scPortletContentDiv) {
          $(this.options.scPortletContentDiv[0]).trigger(
            "scLayout:RefreshPortlet",
            force
          );
        }
      },

      _destroy: function () {
      },
    });
  });

  $(function () {
    $.widget("custom.scChartLine", $.custom.scBase, {
      options: {
        scContentUrl: "jqueryajax/content",
        data: null,
        chart: null,
        divInfo: null,
        divChart: null,
        divUpdated: null,
        aWidth: -1,
        aHeight: -1,
      },

      // the constructor
      _create: function () {
        this.prep();
        if (this.options.init != false) this.postCreate();
      },

      postCreate: function (o) {
        if (o !== undefined) this.options = o;
        if (!this.options.scPortletContentDiv)
          this.options.scPortletContentDiv = $(this.element[0]);
        this.options.scPortletContentDiv.addClass("scChart");
        this._refresh();
      },

      reDrawChart: function (force) {
        var o = this.options;
        if (
          force ||
          o.divChart == null ||
          o.aWidth !== o.divChart.width() ||
          o.aHeight !== o.divChart.height()
        ) {
          this._DrawChart(o.data, this);
          return true;
        }
        return false;
      },

      refresh: function (force) {
        try {
          this._refresh(force);
        } catch (err) {}
      },

      reDraw: function (force) {
        if (this.options.divGrid != null) {
          var h = 21;
          $(this.options.scPortletContentDiv)
            .find(".ui-jqgrid-htable")
            .each(function (index, data) {
              h = $(this).height();
            });
          $.scResizeJqGrid(
            this.options.divGrid,
            this.options.scPortletContentDiv
          );
        }
        else this.reDrawChart(force);
      },

      _ChartURL: function () {
        return this.options.scContentUrl + "/" + Math.random();
      },

      _TestData: function (data) {
        var o = this.options;
        if (o.chart != null) {
          $(o.chart).jqChart("destroy");
          $(o.chart).remove();
          o.chart = null;
        }

        if (data && data.portletData) {
          $.extend(this.options.scPortletData, data.portletData);
        }

        if (o.divChart != null) o.divChart.remove();

        if (o.divUpdated != null) o.divUpdated.remove();

        o.scPortletContentDiv.append(
          (o.divChart = $("<div/>").css({ width: "100%", height: "98%" }))
        );
        o.scPortletContentDiv.append(
          (o.divUpdated = $("<div/>").addClass("sc-updated-label"))
        );

        if (this.options.divUpdated && data)
          if (data.message2) $(this.options.divUpdated).text(data.message2);
          else $(this.options.divUpdated).text(data.updated);

        if (data == null)
          $(this.options.divChart)
            .addClass("no-data")
            .text("No data available");
        else if (data.message && data.message !== "")
          $(this.options.divChart).addClass("no-data").text(data.message);
        else {
          $(this.options.divChart).removeClass("no-data").text("");
          return true;
        }
        return false;
      },

      _AddCopyBtn: function () {
        var self = this;
        //var tb = $(this.element[0]).parent().data("scPortlet").options.scPortletTitleDiv;
        var tb = this.options.piParent.options.scPortletTitleDiv.find(
          "div.dash-conf > ul"
        );
        if (tb.find("li.CopyChartImageBtn").length === 0) {
          $('<li class="CopyChartImageBtn max">Copy chart image</li>')
            .bind("click", function () {
              var chart = self.options.divChart;
              if (chart == null) return;
              var dataURL = $(chart).jqChart("todataurl", "image/png");
              var img = $("<img/>").attr("src", dataURL);

              var message =
                "Please right click the chart and select either 'save' or 'copy' from the menu.";
              if (dataURL == null) {
                message = "No data available";
              }
              var popDiv = $("<div/>").text(message);
              $(popDiv).append(img);
              $(self.options.scPortletContentDiv).append(popDiv);

              popDiv.dialog({
                title: "Copy Chart Image",
                autoOpen: true,
                closeOnEscape: true,
                width: $(chart).width() + 30,
                modal: true,
                buttons: {
                  Close: function () {
                    $(this).dialog("close");
                  },
                },
                close: function (event, ui) {
                  $(this).dialog("destroy").remove();
                },
              });
            })
            .appendTo(tb);
        }
      },

      _destroy: function () {
        if (this.options.chart != null) {
          $(this.options.chart).jqChart("destroy");
        }
        $(this.options.divChart).remove();
      },

      _refresh: function (force, drilldata) {
        var self = this;
        var acb = true;
        if (this.options.divChart != null) {
          $(self.options.divChart).remove();
          self.options.divChart = null;
          self.options.chart = null;
          acb = false;
        }
        if (this.options.divGrid != null) {
          //$(this.options.divGrid).jqGrid('GridDestroy');
          $.jgrid.gridDestroy("#" + $(this.options.divGrid).attr("id"));
          this.options.divGrid.remove();
          this.options.divPager.remove();
          this.options.divGrid = null;
          this.options.divPager = null;
        }

        if (acb && $.sc_support_canvas) self._AddCopyBtn();

        this.options.scPortletData.forceRefresh = force;
        //$.extend(this.options.scPortletData, $(this.element[0]).parent().data("scPortlet").options.scPortletData);
        //$.extend(this.options.scPortletData, this.options.scPortletData.scPortletOptions.scPortletData);

        var pd = $.extend({}, this.options.piParent.options.scPortletData); //this.Strip(this.options.scPortletData);
        if (drilldata) pd.drilldata = oAjax.Json.stringify(drilldata);

        self._showProcessing();

        $.post(
          this._ChartURL(),
          pd,
          function (data) {
            if (data == null) return;
            if (data.colModel) self._DrawGrid(data, self);
            else self._DrawChart(data, self);
            data = null;
          },
          "json"
        )
          .done(function () {
          })
          .fail(function (jqXHR, s, e) {
            self.PostError(jqXHR, s, e);
          })
          .always(function () {
            self._hideProcessing();
          });
      },

      _DrawGrid: function (data, self) {
        var o = self.options;
        o.scPortletContentDiv.append(
          (o.divGrid = $.sc_unique_element("<table/>", "pg").addClass(
            "scGridSingleLine"
          ))
        );
        o.scPortletContentDiv.append(
          (o.divPager = $.sc_unique_element("<div/>", "pp"))
        );

        o.gridData = $.extend({}, data, {
          pager: "#" + $(o.divPager).attr("id"),
          height: o.scPortletContentDiv.height() - 60,
          width: o.scPortletContentDiv.width() - 1,
          sortable: true,
          datatype: "jsonstring",
          datastr: data.gridData,
          gridview: true,
          shrinkToFit: false,
          jsonReader: {
            repeatitems: false,
            root: function (obj) {
              return obj;
            },
            page: function (obj) {
              return 1;
            },
            total: function (obj) {
              return 1;
            },
            records: function (obj) {
              return obj.length;
            },
          },
          onSelectRow: function (id, sel) {
            var gd = $(o.divGrid).jqGrid("getRowData", id);
            tabs.tab.menu.addAndSelect(
              "Form: " + gd.qd,
              { entity: "Form", key: gd.qk },
              undefined,
              {
                view: "Initiative",
                standardKey: gd.stk,
                questionnaireKey: gd.qk,
                scrollToS: id,
              }
            ); //, 'scrollToR': tarr });
          },
        });

        $(o.divGrid)
          .jqGrid(o.gridData)
          .navGrid(
            "#" + o.divPager.attr("id"),
            {
              search: true,
              add: false,
              del: false,
              edit: false,
              refresh: false,
              view: false,
            },
            {},
            {},
            {},
            { closeAfterSearch: true }
          );
        $(o.divGrid).jqGrid("setFrozenColumns");
        data = null;
        self.reDraw();
        return false;
      },

      _DrawChart: function (data, self) {
        if (data && data.chartType === "stackedBar" && data.axes)
          $.each(data.axes, function (k, v) {
            if (v.interval) v.interval = undefined;
          });
        var o = self.options;
        if (self._TestData(data)) {
          if ($.sc_support_canvas) self.renderChart(data, self);
          else
            window.setTimeout(function () {
              self.renderChart(data, self);
              var a = $(self.options.divChart).closest(".portlet-container");
            }, 0);
          return true;
        }
        o.aWidth = -1;
        o.aHeight = -1;
        return false;
      },

      renderChart: function (data, self) {
        var o = self.options;
        if (
          data &&
          data.axes &&
          data.axes[0] &&
          data.axes[0].type === "dateTime"
        ) {
          $.each(data.series, function (k, v) {
            v.data = $.map(v.data, function (p) {
              p[0] = new Date(p[0]);
              p[1] = Math.max(0.001, p[1]);
              return [p];
            });
          });
        }
        o.data = data;

        o.divChart.bind("dataPointLabelCreating", function (event, data) {
          if (data.context.chart.options.scChartType === "risk") {
            data.y += 23;
            data.text = data.context.dataItem[2];
          }
        });

        o.chart = o.divChart
          .jqChart(data)
          .bind("dataPointMouseDown", function (e, data) {
            var p = data.dataItem;
            if (p instanceof Array && p.length > 3) {
              if (p.length > 4 && !!p[4]) {
                var d = $.parseJSON(p[4]);
                self._refresh(true, d);
              }
              else if (p[3].match(/^\d+$/) == null) {
                try {
                  eval(p[3]);
                } catch (e) {}
              }
              else {
                self._refresh(true, p[3]);
              }
            }
          })
          .bind("dataPointMouseEnter", function (e, data) {
            var p = data.dataItem;
            if (p instanceof Array && p.length > 3)
              $(e.target).css("cursor", "pointer");
          })
          .bind("dataPointMouseLeave", function (e, data) {
            var p = data.dataItem;
            if (p instanceof Array && p.length > 3)
              $(e.target).css("cursor", "default");
          })
          .bind("tooltipFormat", function (e, data) {
            var p = data.dataItem;
            if (data.x instanceof Date) {
              var s = data.series;
              return (
                "<b>" +
                data.chart.stringFormat(data.x, "dd/mm/yyyy") +
                '</b><br/><span style="color:' +
                s.fillStyle +
                '">' +
                s.title +
                "</span>:<b>" +
                Math.round(data.y) +
                "</b>"
              );
            }
            if (data.chart.options.scChartType === "risk") {
              if (data.series.tooltips)
                return "<b>" + p[2] + "</b><br />" + p[3];
              else return false;
            }
            if (p instanceof Array && p.length > 2)
              if (p[2] != null) return p[2].toString();
            return null;
          });
        try {
          for (var i = 0; i < 2; i++) {
            if ($(o.chart).data().data.axes.userAxes[i].actualInterval < 1) {
              data.axes[i].interval = 1;
            }
          }
          o.chart = o.divChart.jqChart(data);
        } catch (e) {}
        o.aWidth = o.divChart.width();
        o.aHeight = o.divChart.height();
      },
    });
  });

  $(function () {
    $.widget("custom.scGrid", $.custom.scBase, {
      // default options
      options: {
        scContentUrl: "#",
        scGrid: null,
      },

      // the constructor
      _create: function () {
        this.prep();
        if (this.options.init != false) this.postCreate();
      },

      postCreate: function (o) {
        if (o !== undefined) this.options = o;
        this.createGrid();
      },

      createGrid: function () {
        var self = this;
        self._showProcessing();

        //this.options.scParent = this.element[0];
        if (!this.options.scPortletContentDiv)
          this.options.scPortletContentDiv = $(this.element[0]);

        var G_vmlCanvasManager;

        var ModelUrl =
          self.options.scContentUrl + "/model/" + new Date().getTime();
        var DataUrl =
          self.options.scContentUrl + "/data/" + new Date().getTime();
        var ExportUrl =
          self.options.scContentUrl + "/data/export/" + new Date().getTime();

        //Load portlet config data.
        //$.extend(this.options.scPortletData, $(this.element[0]).parent().data("scPortlet").options.scPortletData);
        //$.extend(this.options.scPortletData, this.options.scPortletData.scPortletOptions.scPortletData);

        var pdp = this.Strip(
          $.extend(
            {},
            self.options.piParent.options.scPortletData,
            self.options.piParent.options.scPortletData.def
          )
        );

        $.post(
          ModelUrl,
          pdp,
          function (data) {
            if (self.options.scGrid) {
              //self.options.scGrid.GridDestroy();
              $.jgrid.gridDestroy("#" + self.options.scTableDivId);
              self.options.scGrid = null;
            }

            if (
              data != null &&
              data.portletConfig &&
              self.options.piParent.options.fnBuildConfig
            )
              self.options.piParent.options.fnBuildConfig(
                self.options.piParent.options.scConfigPos,
                data.portletConfig
              );

            if (data != null && data.colModel) {
              self.options.scTableDiv = $.sc_unique_element("<table/>", "td");
              self.options.scPagerDiv = $.sc_unique_element(
                "<div/>",
                "pd"
              ).css({ "background-color": "#FFFFFF" });

              self.options.scTableDivId = self.options.scTableDiv.attr("id");
              self.options.scPagerDivId = self.options.scPagerDiv.attr("id");

              self.options.scPortletContentDiv
                .css({ position: "relative" })
                .append(self.options.scTableDiv, self.options.scPagerDiv);

              for (let col in data.colModel) {
                if (self.options.scState.scColSize) {
                  var width = self.options.scState.scColSize[col] || 100;
                  data.colModel[col].width = Math.max(
                    20,
                    Math.min(1000, width)
                  );
                  data.colModel[col].fixed = true;
                }

                if (data.colModel[col].wordWrap)
                  data.colModel[col].cellattr = function (
                    rowId,
                    tv,
                    rawObject,
                    cm,
                    rdata
                  ) {
                    return 'style="white-space: normal;"';
                  };

                if (data.colModel[col].editable) {
                  data.colModel[col].cellattr = function (rowId, val, raw, cm) {
                    return 'class="sc-editable"';
                  };
                }

                if (data.colModel[col].formatter === "scSparkleLine")
                  data.colModel[col].formatter = function (
                    cellvalue,
                    options,
                    rowObject
                  ) {
                    //return '<div id="div' + divNum + '" class="sc-spark-cell" sca="' + cellvalue + '"></div>';
                    //return '<canvas id="div' + divNum + '" class="sc-spark-cell" sca="' + cellvalue + '"></canvas>';
                    return $.sc_unique_element("<canvas/>", "pp")
                      .addClass("sc-spark-cell")
                      .attr({ sca: cellvalue });
                  };
                else if (data.colModel[col].formatter === "scLink")
                  data.colModel[col].formatter = function (
                    cellvalue,
                    options,
                    rowObject
                  ) {
                    return $.sc_link(cellvalue);
                  };
                else if (data.colModel[col].formatter === "scIcon")
                  data.colModel[col].formatter = function (
                    cellvalue,
                    options,
                    rowObject
                  ) {
                    return (
                      '<img style="margin-left: 12px;" src="images/icons/' +
                      cellvalue +
                      '" />'
                    );
                  };
                else if (data.colModel[col].formatter === "scNull")
                  data.colModel[col].formatter = function (
                    cellvalue,
                    options,
                    rowObject
                  ) {
                    return cellvalue === undefined
                      ? ""
                      : cellvalue
                        .replace(/&/g, "&amp;")
                        .replace(/"/g, "&quot;")
                        .replace(/'/g, "&#39;")
                        .replace(/</g, "&lt;")
                        .replace(/>/g, "&gt;");
                  };
                else if (data.colModel[col].formatter === "scRAG%")
                  data.colModel[col].formatter = function (
                    cellvalue,
                    options,
                    rowObject
                  ) {
                    if (parseInt(cellvalue) >= 100)
                      return (
                        '<div class="sc-rag-green">' + cellvalue + " %</div>"
                      );
                    else
                      return (
                        '<div class="sc-rag-red">' + cellvalue + " %</div>"
                      );
                  };
                else if (data.colModel[col].formatter === "scCell") {
                  data.colModel[col].cellattr = function (rowId, val, raw, cm) {
                    if (raw[cm.index]) {
                      var o = $.parseJSON(raw[cm.index]);
                      var c = "",
                        s = "",
                        p = "",
                        b = "";
                      if (o.h) c = ' class="sc-formgridhdr"';
                      if (o.i > 0) p = "padding-left:" + o.i * 20 + "px;";
                      if (o.bc) b = "background-color:" + o.bc + ";";
                      if (p !== "" || b !== "") s = ' style="' + p + b + '"';
                      return c + s;
                    }
                    return "";
                  };

                  data.colModel[col].formatter = function (
                    cellvalue,
                    options,
                    rowObject
                  ) {
                    if (cellvalue) {
                      var o = $.parseJSON(cellvalue);
                      var s = "";
                      if (o.s === true) s = ' checked="checked"';
                      if (o.cb)
                        return (
                          '<label class="ei-check"><input class="scValue" type="checkbox" name="checkbox"' +
                          s +
                          ' value="' +
                          o.rs +
                          "|" +
                          o.k +
                          '"><span></span></label>' +
                          (o.v ? o.v : " ")
                        );
                      if (!o.v) o.v = "";
                      o.v = o.v
                        .replace(/&/g, "&amp;")
                        .replace(/"/g, "&quot;")
                        .replace(/'/g, "&#39;")
                        .replace(/</g, "&lt;")
                        .replace(/>/g, "&gt;");
                      if (o.b === "all")
                        return (
                          '<span class="scGridSelectAll" title="Select all."></span>&nbsp;<span class="scGridDeSelectAll" title="Deselect all."></span>&nbsp;' +
                          o.v
                        );
                      if (o.b === "row")
                        return (
                          '<span class="scGridSelectRow" title="Select row."></span>&nbsp;<span class="scGridDeSelectRow" title="Deselect row."></span>&nbsp;' +
                          o.v
                        );
                      return o.v;
                    }
                    return "";
                  };
                }
                else if (data.colModel[col].formatter === "scCellDrill")
                  data.colModel[col].cellattr = function (rowId, val, raw, cm) {
                    return ' class="scFormDrill"';
                  };
                else if (data.colModel[col].formatter !== "")
                  data.colModel[col].formatter =
                    self.options.scPortletData[data.colModel[col].formatter];
                else if (!data.colModel[col].formatter)
                  data.colModel[col].formatter = function (v, o, r) {
                    if (typeof v === "string")
                      return v
                        .replace(/&/g, "&amp;")
                        .replace(/"/g, "&quot;")
                        .replace(/'/g, "&#39;")
                        .replace(/</g, "&lt;")
                        .replace(/>/g, "&gt;");
                    return v;
                  };
              }

              var pd = {};
              $.each(
                $.extend({}, data.portletData, pdp, self.options.scPortletData),
                function (key, value) {
                  if (
                    typeof value === "string" ||
                    typeof value === "number" ||
                    typeof value === "boolean"
                  )
                    pd[key] = value;
                }
              );

              var go = {};
              if (data.datastr && data.treeGrid === true) {
                go = $.extend(data, {
                  sortable: false,
                  loadui: "disable",
                  loadComplete: function (data) {
                    if (data) {
                      var item,
                        i,
                        l = data.length || 0;
                      var t = $(self.options.scTableDiv);
                      for (i = 0; i < l; i++) {
                        item = data[i];
                        if (
                          !item.isLeaf &&
                          item.icon &&
                          (item.level === "1" || item.level === 1)
                        ) {
                          t.find("#" + item.id + " div.treeclick").addClass(
                            item.icon
                          );
                        }
                      }
                    }
                  },
                  jsonReader: {
                    repeatitems: false,
                  },
                });
                self.options.scTableDiv.addClass("scTreeGrid");
              }
              else if (data.data)
                go = {
                  postData: pd,
                  data: data.data,
                  datatype: "local",
                  rowNum: 500,
                  rowList: [100, 500, 1000, 10000],
                  colNames: data.colNames,
                  colModel: data.colModel,
                  pager: self.options.scPagerDivId,
                  viewrecords: true,
                  sortname: data.sortname,
                  ignoreCase: true,
                };
              else if (!data.gridData)
                go = {
                  autoencode: true,
                  recordtext: "View {0} - {1} of {2}",
                  emptyrecords: "No records to view",
                  loadtext: "Loading...",
                  pgtext: "Page {0} of {1}",
                  colModel: data.colModel,
                  sortname: data.sortname,
                  viewrecords: true,
                  pager: self.options.scPagerDivId,
                  rowNum: data.rowNum,
                  rowList: data.rowList,
                  sortorder: data.sortorder,
                  datatype: "json",
                  url: DataUrl,
                  editurl: self.options.scPortletData.scEditURL,
                  cellurl: self.options.scPortletData.scEditURL,
                  cellEdit: data.cellEdit,
                  postData: pd,
                  mtype: "POST",
                  rownumbers: !data.treeGrid,
                  sortable: true, //sort columns
                  loadonce: false, //get local copy of data
                  autowidth: false,
                  shrinkToFit: false,
                  subGrid: false,
                  //sortname: "default",
                  gridview: !data.treeGrid, // speed up
                  treeGrid: data.treeGrid,
                  treeGridModel: data.treeGridModel,
                  //ExpandColumn: "description",
                  ExpandColClick: true,

                  jsonReader: {
                    repeatitems: !data.treeGrid,
                    cell: data.treeGrid ? null : "cell",
                  },

                  //subGridUrl: DataUrl,
                  //subGridModel: [data.subGridModel],

                  subGridRowExpanded: function (subgrid_id, row_id) {
                    // we pass two parameters
                    // subgrid_id is a id of the div tag created whitin a table data
                    // the id of this elemenet is a combination of the "sg_" + id of the row
                    // the row_id is the id of the row
                    // If we wan to pass additinal parameters to the url we can use
                    // a method getRowData(row_id) - which returns associative array in type name-value
                    // here we can easy construct the flowing
                    var subgrid_table_id, pager_id;
                    subgrid_table_id = subgrid_id + "_t";
                    pager_id = "p_" + subgrid_table_id;
                    $("#" + subgrid_id).html(
                      "<table id='" +
                      subgrid_table_id +
                      "' class='scroll'></table><div id='" +
                      pager_id +
                      "' class='scroll'></div>"
                    );
                    jQuery("#" + subgrid_table_id).jqGrid({
                      colModel: data.colModel,
                      sortname: data.sortname,
                      viewrecords: true,
                      pager: "#" + pager_id,
                      rowNum: data.rowNum,
                      rowList: data.rowList,
                      sortorder: data.sortorder,
                      datatype: "json",
                      url: DataUrl + "?subgridId=" + row_id,
                      mtype: "POST",
                      rownumbers: true,
                      sortable: true, //sort columns
                      loadonce: false,
                      gridview: true,
                      autowidth: false,
                      shrinkToFit: false,
                      height: "100%",
                      width: self.options.scPortletContentDiv.width() - 130,
                    });
                    $("#" + subgrid_table_id).jqGrid(
                      "navGrid",
                      "#" + pager_id,
                      { edit: false, add: false, del: false, refresh: false },
                      {},
                      {},
                      {},
                      { closeAfterSearch: true }
                    );
                  },
                  subGridRowColapsed: function (subgrid_id, row_id) {
                    // this function is called before removing the data
                    //var subgrid_table_id;
                    //subgrid_table_id = subgrid_id+"_t";
                    //jQuery("#"+subgrid_table_id).remove();
                  },

                  beforeSaveCell: function (rowid, celname, value, iRow, iCol) {
                    //return value.replace(/(<([^>]+)>)/ig, " ");
                    //return String(value).replace(/&/g, '&amp;').replace(/"/g, '&quot;').replace(/'/g, '&#39;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
                  },
                  beforeSubmitCell: function () {
                    return { scCsrf: getLocalToken() };
                  },

                  loadComplete: function () {
                    $(this)
                      .find(".jqDownloadIcon")
                      .each(function () {
                        $(this).click(function () {
                          if ($(this).attr("scf"))
                            self.options.scPortletData[$(this).attr("scf")](
                              $(this).attr("sca")
                            );
                        });
                      });
                    window.setTimeout(function () {
                      var a = $(self.options.scTableDiv).closest(
                        ".portlet-container"
                      );
                    }, 0);
                  },

                  onCellSelect: function (id) {
                    if (self.options.scPortletData.onCellSelect)
                      self.options.scPortletData.onCellSelect(id);
                  },
                  //                       gridComplete : function(){
                  //                           jQuery("#list4").tableDnDUpdate();
                  //                       }
                };
              else {
                $.each(data.gridData, function (k, v) {
                  if (!v.parent) v.parent = null;
                });
                if (data.treeGrid)
                  go = {
                    datatype: "jsonstring",
                    datastr: data.gridData,
                    //data: data.gridData,
                    //datatype: "local",
                    colModel: data.colModel,
                    viewrecords: true,
                    loadonce: true,
                    sortable: data.sortable,
                    sortname: data.sortname,
                    gridview: true, // speed up
                    loadComplete: function () {
                      $("#" + self.options.scTableDivId + " tr.jqgroup").each(
                        function () {
                          var grouprow = $(this);
                          grouprow.children("td").attr("colSpan", 1);
                          for (var c = 1; c < data.colModel.length; c++)
                            if (!data.colModel[c].hidden)
                              grouprow.append(
                                $("<td/>")
                                  .text(data.colModel[c].label)
                                  .css({ "background-color": "#eee" })
                              );
                        }
                      );
                      window.setTimeout(function () {
                        var a = $(self.options.scTableDiv).closest(
                          ".portlet-container"
                        );
                      }, 0);
                    },
                    pager: self.options.scPagerDivId,
                    localReader: {
                      repeatitems: false,
                      cell: null,
                    },
                    jsonReader: {
                      repeatitems: false,
                      root: function (obj) {
                        return obj;
                      },
                      page: function (obj) {
                        return 1;
                      },
                      total: function (obj) {
                        return 1;
                      },
                      records: function (obj) {
                        return obj.length;
                      },
                    },
                  };
                else
                  go = {
                    datatype: "jsonstring",
                    datastr: data.gridData,
                    url: DataUrl,
                    mtype: "POST",

                    rowNum: 500,
                    rowList: [100, 500, 1000, 10000],
                    sortable: true,
                    colModel: data.colModel,
                    viewrecords: true,
                    gridview: true, // speed up
                    pager: self.options.scPagerDivId,
                    postData: $.extend({ grid: "update" }, pd),
                    localReader: {
                      repeatitems: false,
                      cell: null,
                    },
                    jsonReader: {
                      repeatitems: false,
                      root: function (obj) {
                        return obj;
                      },
                      page: function (obj) {
                        return 1;
                      },
                      total: function (obj) {
                        return 1;
                      },
                      records: function (obj) {
                        return obj.length;
                      },
                    },
                    loadComplete: function () {
                      if (data.scSortable)
                        $(this).jqGrid("setGridParam", {
                          datatype: "json",
                        });
                      window.setTimeout(function () {
                        var a = $(self.options.scTableDiv).closest(
                          ".portlet-container"
                        );
                      }, 0);
                    },
                  };
              }

              go.resizeStop = function (width, index) {
                if (!self.options.scState.scColSize)
                  self.options.scState.scColSize = {};
                self.options.scState.scColSize[index] = width;
                self.reDraw();
                self.element.trigger("scLayout:SaveState");
              };

              go.beforeSelectRow = function (rowId, e) {
                var c = $(e.target || e.srcElement);
                if (c.hasClass("scGridSelectRow")) {
                  $(c)
                    .closest("tr")
                    .find('[type="checkbox"]')
                    .prop("checked", true);
                  return false;
                }
                if (c.hasClass("scGridDeSelectRow")) {
                  $(c)
                    .closest("tr")
                    .find('[type="checkbox"]')
                    .prop("checked", false);
                  return false;
                }
                if (c.hasClass("scGridSelectAll")) {
                  $(c)
                    .closest("table")
                    .find('[type="checkbox"]')
                    .prop("checked", true);
                  return false;
                }
                if (c.hasClass("scGridDeSelectAll")) {
                  $(c)
                    .closest("table")
                    .find('[type="checkbox"]')
                    .prop("checked", false);
                  return false;
                }
                return true;
              };

              go.onCellSelect = function (id) {
                if (self.options.scPortletData.onCellSelect)
                  self.options.scPortletData.onCellSelect(id);
              };

              if (data.extend) go = $.extend(go, data.extend);

              go.deepempty = true;
              go.width = self.options.scPortletContentDiv.width();
              go.height = self.options.scPortletContentDiv.height() - 48;

              go.rowNum = 20;

              if (data.noPaging === true) {
                go.rowList = [];
                go.rowNum = 1000000;
                go.pgbuttons = false;
                go.pginput = false;
                go.pgtext = "";
              }

              self.options.scGrid = $(self.options.scTableDiv)
                .jqGrid(go)
                .navGrid(
                  "#" + self.options.scPagerDivId,
                  {
                    edit: false,
                    add: false,
                    del: data.DeleteButton,
                    search: true,
                    refresh: false,
                  },
                  {},
                  {},
                  {},
                  $.scMultipleSearchOptions
                );

              self.element.find(".ui-jqgrid").css({ position: "absolute" });

              $(self.options.scTableDiv).jqGrid(
                "navButtonAdd",
                "#" + self.options.scPagerDivId,
                {
                  caption: "",
                  title: "Export data to Excel.",
                  onClickButton: function () {
                    //var url = "jqueryajax/iqy/data?"
                    //window.open();
                    $(self.options.scTableDiv).jqGrid("excelExport", {
                      url: ExportUrl.replace("content", "csv"),
                    });
                  },
                }
              );

              $(self.options.scTableDiv).mousedown(function (e) {
                var c = $(e.target || e.srcElement);

                if (data && data.clickDrill === true) {
                  if (c.is(".cell-wrapper, .cell-wrapperleaf, .tree-leaf")) {
                    var i = c.closest("tr").attr("id");

                    var gd = $(self.options.scGrid).jqGrid("getRowData", i);
                    if (gd) {
                      $(self.options.scGrid).jqGrid("resetSelection");
                      if (gd.qk)
                        tabs.tab.menu.addAndSelect(
                          "Form: " + gd.questionnaire,
                          { entity: "Form", key: gd.qk },
                          undefined,
                          {
                            view: "Initiative",
                            standardKey: gd.sk,
                            questionnaireKey: gd.qk,
                          }
                        );
                      else if (gd.sk)
                        tabs.tab.menu.addAndSelect(
                          "Initiative: " + gd.standard,
                          { entity: "Standard", key: gd.sk },
                          undefined,
                          { view: "Initiative", standardKey: gd.sk }
                        );
                    }

                    e.stopPropagation();
                    e.preventDefault();
                    e.originalEvent.stopPropagation();
                    e.originalEvent.preventDefault();
                    return false;
                  }
                }
                if (c && c.is("span.drill")) {
                  var skey = c.attr("skey");
                  var qkey = c.attr("qkey");

                  if (qkey)
                    tabs.tab.menu.addAndSelect(
                      "Form: Form",
                      { entity: "Form", key: qkey },
                      undefined,
                      {
                        view: "Initiative",
                        standardKey: skey,
                        questionnaireKey: qkey,
                      }
                    );
                  else if (skey)
                    tabs.tab.menu.addAndSelect(
                      "Initiative: Initiative",
                      { entity: "Standard", key: skey },
                      undefined,
                      { view: "Initiative", standardKey: skey }
                    );

                  e.stopPropagation();
                  e.preventDefault();
                  e.originalEvent.stopPropagation();
                  e.originalEvent.preventDefault();
                  return false;
                }
              });

              $(self.options.scTableDiv).click(function (e) {
                var c = $(e.target || e.srcElement);
                if (c.hasClass("scFormDrill")) {
                  var i = c.closest("tr").attr("id");
                  if (data && data.clickDrill === true) {
                    var gd = $(self.options.scGrid).jqGrid("getRowData", i);
                    if (gd) {
                      $(self.options.scGrid).jqGrid("resetSelection");
                      if (gd.qk)
                        tabs.tab.menu.addAndSelect(
                          "Form: " + gd.questionnaire,
                          { entity: "Form", key: gd.qk },
                          undefined,
                          {
                            view: "Initiative",
                            standardKey: gd.sk,
                            questionnaireKey: gd.qk,
                          }
                        );
                      else if (gd.sk)
                        tabs.tab.menu.addAndSelect(
                          "Initiative: " + gd.standard,
                          { entity: "Standard", key: gd.sk },
                          undefined,
                          { view: "Initiative", standardKey: gd.sk }
                        );
                    }
                  }
                  else {
                    if (data.drillThrough) {
                      var d = data.drillThrough[i];
                      if (d)
                        tabs.tab.menu.addAndSelect("Form: " + d.q, {
                          entity: "Form",
                          key: d.qk,
                          inherit: {
                            view: "Initiative",
                            standardKey: d.ik,
                            questionnaireKey: d.qk,
                          },
                        });
                    }
                  }
                }
              });

              var searchDialog = $(
                "#searchmodfbox_" + self.options.scTableDivId
              );
              searchDialog.addClass("sc-centered");
              //var gbox = $("#gbox_" + grid[0].id);
              //gbox.before(searchDialog);
              //gbox.css({ clear: "left" });

              self.reDraw();
              //jQuery("#" + self.options.scTableDivId).jqGrid('navButtonAdd', '#' + self.options.scPagerDivId, {
              //    caption: "",
              //    buttonicon: "scAddFile",
              //    onClickButton: function () {
              //        alert("Adding Row");
              //    },
              //    position: "last"
              //});

              go = null;
              //data = null;
              pd = null;
              pdp = null;
            }
          },
          "json"
        )
          .done(function () {
          })
          .fail(function (jqXHR, s, e) {
            self.PostError(jqXHR, s, e);
          })
          .always(function () {
            self._hideProcessing();
          });
      },

      _refresh: function () {
        if (this.options.scPortletData.rebuild) this.createGrid();
        else {
          //var pd = $.extend({}, this.options.scPortletData, $(this.element[0]).parent().data("scPortlet").options.scPortletData);
          var pd = this.options.piParent.options.scPortletData; //$.extend({}, this.options.scPortletData, this.options.scPortletData.scPortletOptions.scPortletData);

          $(this.options.scTableDiv)
            .setGridParam({ postData: pd })
            .trigger("reloadGrid");
        }
      },

      reLoad: function (data) {
        $.scResizeJqGrid(
          this.options.scTableDiv,
          this.options.scPortletContentDiv
        );
        data.filters = "";
        $(this.options.scTableDiv)
          .setGridParam({ postData: data })
          .trigger("reloadGrid", [{ page: 1 }]);
      },

      // called when created, and later when changing options
      refresh: function () {
        this._refresh();
      },

      reDraw: function () {
        $.scResizeJqGrid(
          this.options.scTableDiv,
          this.options.scPortletContentDiv
        );
      },

      _destroy: function () {
        $.consoleLog(
          "scGrid : _destroy: " +
          this.options.scTableDivId +
          ", " +
          this.options.scPagerDiv +
          ", " +
          this.options.scPortletContentDiv
        );
        if ($("#" + this.options.scTableDivId).length) {
          //$(this.options.scGrid).jqGrid('GridDestroy');
          $.jgrid.gridDestroy("#" + this.options.scTableDivId);
        }
        $(this.options.scTableDiv).remove();
        $(this.options.scPagerDiv).remove();
      },
    });
  });

  $(function () {
    $.widget("custom.scHtml", $.custom.scBase, {
      // default options
      options: {
        scContentUrl: "#",
      },

      // the constructor
      _create: function () {
        this.prep();
        if (this.options.init != false) this.postCreate();
      },

      postCreate: function (o) {
        if (o !== undefined) this.options = o;
        var self = this;
        //this.options.scParent = this.element[0];
        if (!this.options.scPortletContentDiv)
          this.options.scPortletContentDiv = $(this.element[0]);

        $.post(
          this.options.scContentUrl,
          function (data) {
            $(self.options.scPortletContentDiv).html(data.html);
          },
          "json"
        )
          .done(function () {
          })
          .fail(function (jqXHR, s, e) {
            self.PostError(jqXHR, s, e);
          })
          .always(function () {
          });
      },

      // called when created, and later when changing options
      refresh: function () {
        this._refresh();
      },
      reDraw: function () {
      },
      _destroy: function () {
      },
    });
  });

  $(function () {
    $.widget("custom.scLibrary", $.custom.scBase, {
      // default options
      options: {
        scContentUrl: "#",
        document_folder_key: -1,
        path: "",
      },

      // the constructor
      _create: function () {
        this.prep();
        if (this.options.init != false) this.postCreate();
      },

      postCreate: function (o) {
        if (o !== undefined) this.options = o;
        var self = this;
        //this.options.scParent = this.element[0];
        if (!this.options.scPortletContentDiv)
          this.options.scPortletContentDiv = $(this.element[0]);

        this.options.addAsEvidence = this.options.scPortletData.optAddAsEvidence;
        //this.options.scCsrf = this.options.scPortletData.scCsrf;

        var TreeUrl =
          self.options.scContentUrl + "/tree/" + new Date().getTime();

        var divLeft = $("<div/>")
          .css({
            left: "0px",
            top: "0px",
            width: "300px",
            bottom: "4px",
            position: "absolute",
            padding: "4px",
            "background-color": "#ffffff",
          })
          .addClass("pane ui-layout-west")
          .click(function (data) {
            if ($(data.target).hasClass("ui-layout-west"))
              if (self.options.scTree != null) {
                self.options.scTree.jstree("deselect_all");
                self.options.document_folder_key = "";
                self.options.path = "";
                self.options.fKey = -1;
                //self.options.GridFiles.data().scPortlet.options.scPortletContentDiv.data().scGrid.reLoad({ "dKey": self.options.document_folder_key, "dl": true, "uf": false });
                //self.options.GridFile.data().scPortlet.options.scPortletContentDiv.data().scGrid.reLoad({ "fKey": -1, "dl": true, "uf": false });
                //self.options.GridEvidence.data().scPortlet.options.scPortletContentDiv.data().scGrid.reLoad({ "fKey": -1 });

                self.options.GridFiles.scPortlet(
                  "option"
                ).scPortletContentDiv.scGrid("reLoad", {
                  dKey: self.options.document_folder_key,
                  dl: true,
                  uf: false,
                });
                self.options.GridFile.scPortlet(
                  "option"
                ).scPortletContentDiv.scGrid("reLoad", {
                  fKey: -1,
                  dl: true,
                  uf: false,
                });
                self.options.GridEvidence.scPortlet(
                  "option"
                ).scPortletContentDiv.scGrid("reLoad", { fKey: -1 });
              }
          });

        var divRight = this.options.scLayout.scLayout("option", "column2");

        this.options.scTree = $("<div/>")
          .css({
            left: "0px",
            top: "34px",
            right: "0px",
            bottom: "0px",
            position: "absolute",
            "background-color": "#ffffff",
          })
          .width(this.element.width() - 4)
          .addClass("sc-no-radius library");
        $(this.options.scPortletContentDiv).append(divLeft);
        //$(this.options.scPortletContentDiv).append(divRight);
        $(divLeft).append(this.options.scTree);

        $(this.options.scTree)
          .bind("loaded.jstree", function (event, data) {
            $(self.options.scTree).jstree("select_node", "ul > li:first");
            $(self.options.scTree).jstree("open_node", "ul > li:first");
            $(self.options.scTree).dblclick(function () {
              var selected = $(self.options.scTree)
                .jstree()
                .get_node(
                  $(self.options.scTree).jstree()._data.core.selected[0]
                );
              if (selected && selected.id !== "-1") {
                $(self.options.scTree).jstree().edit(selected);
              }
            });

            $(self.options.scTree).click(function (e) {
              if ($(e.originalEvent.srcElement).is(".jstree")) {
                self.options.scTree.jstree("deselect_all");
                self.options.document_folder_key = "";
                self.options.path = "";
              }
            });
          })
          .jstree({
            core: {
              data: {
                url: TreeUrl,
                type: "POST",
                dataType: "json",
                cache: false,
                data: {
                  scCsrf: getLocalToken(),
                },
              },
              check_callback: true,
            },
            plugins: ["crrm"],
          })
          .bind("select_node.jstree", function (e, selected) {
            var metadata = selected.node.original.metadata;
            var a = metadata.all;
            var c = metadata.pcd;
            var d = metadata.pdd;
            var u = metadata.puf; // Up file
            var x = metadata.pdf; // del file
            var dl = a || metadata.ppf; //download_files
            var uf = a || metadata.pvf; //download_files
            var s = metadata.psp; //set_permissions
            var o = self.options;

            o.fKey = -1;
            o.document_folder_key = metadata.dkey;
            //if (o.document_folder_key < 0) o.document_folder_key = "";
            o.path = metadata.path;
            //o.GridFiles.data().scPortlet.options.scPortletContentDiv.data().scGrid.reLoad({ "dKey": o.document_folder_key, "dl": dl, "uf": uf });
            //o.GridFile.data().scPortlet.options.scPortletContentDiv.data().scGrid.reLoad({ "fKey": -1, "dl": dl, "uf": uf });
            //o.GridEvidence.data().scPortlet.options.scPortletContentDiv.data().scGrid.reLoad({ "fKey": -1 });

            if (o.GridFiles.data()["customScPortlet"]) {
              o.GridFiles.scPortlet("option").scPortletContentDiv.scGrid(
                "reLoad",
                {
                  dKey: o.document_folder_key,
                  dl: dl,
                  uf: uf,
                  project_report_path:
                    metadata.projectReportPath == undefined
                      ? ""
                      : metadata.projectReportPath,
                  project_report_type:
                    metadata.projectReportType == undefined
                      ? ""
                      : metadata.projectReportType,
                  project_report_name:
                    metadata.projectReportName == undefined
                      ? ""
                      : metadata.projectReportName,
                  project_report_date:
                    metadata.projectReportDate == undefined
                      ? ""
                      : metadata.projectReportDate,
                  project_instance_key:
                    metadata.projectInstanceKey == undefined
                      ? ""
                      : metadata.projectInstanceKey,
                }
              );
            }
            if (o.GridFile.data()["customScPortlet"]) {
              o.GridFile.scPortlet("option").scPortletContentDiv.scGrid(
                "reLoad",
                {
                  fKey: -1,
                  dl: dl,
                  uf: uf,
                  project_report_path:
                    metadata.projectReportPath == undefined
                      ? ""
                      : metadata.projectReportPath,
                  project_report_type:
                    metadata.projectReportType == undefined
                      ? ""
                      : metadata.projectReportType,
                  project_report_name:
                    metadata.projectReportName == undefined
                      ? ""
                      : metadata.projectReportName,
                  project_report_date:
                    metadata.projectReportDate == undefined
                      ? ""
                      : metadata.projectReportDate,
                  project_instance_key:
                    metadata.projectInstanceKey == undefined
                      ? ""
                      : metadata.projectInstanceKey,
                }
              );
            }
            if (o.GridEvidence.data()["customScPortlet"]) {
              o.GridEvidence.scPortlet(
                "option"
              ).scPortletContentDiv.scGrid("reLoad", { fKey: -1 });
            }
            if ((o.btnPer && a) || s) o.btnPer.css({ display: "inline-block" });
            else o.btnPer.css({ display: "none" });
            if ((o.btnAddFolder && a) || c)
              o.btnAddFolder.css({ display: "inline-block" });
            else o.btnAddFolder.css({ display: "none" });
            if ((o.btnDelFolder && a) || d)
              o.btnDelFolder.css({ display: "inline-block" });
            else o.btnDelFolder.css({ display: "none" });
            if ((o.btnAddFile && a) || u) o.btnAddFile.show();
            else o.btnAddFile.hide();
            if ((o.btnDelFile && a) || x) o.btnDelFile.show();
            else o.btnDelFile.hide();
          })
          .bind("rename_node.jstree", function (e, data) {
            if (data.node.original.text !== data.node.text) {
              var UpdateUrl =
                self.options.scContentUrl + "/" + new Date().getTime();
              $.post(
                UpdateUrl,
                {
                  scCsrf: getLocalToken(),
                  action: "renamefolder",
                  dkey: data.node.original.metadata.dkey,
                  foldername: data.node.text,
                },
                function (data) {
                }
              );
            }
          })
          .bind("delete_node.jstree", function (e, data) {
            var UpdateUrl =
              self.options.scContentUrl + "/" + new Date().getTime();
            if (data) {
              $.post(
                UpdateUrl,
                {
                  scCsrf: getLocalToken(),
                  action: "deletefolder",
                  dkey: data.node.original.metadata.dkey,
                },
                function (data) {
                }
              );
            }
          });

        var popDir = $('<div title="Create Folder">').css({
          height: "300px",
          width: "400px",
        });
        var popDirfrm = $("<form>").addClass("scJqForm");
        popDirfrm.append(
          '<input type="hidden" name="document_folder_key"></input>'
        );
        popDirfrm.append(
          '<p><span>Current Path:</span><input type="text" name="path" style="width: 100%" readonly="readonly"></input></p>'
        );
        popDirfrm.append(
          '<p><span>New Folder:</span><input type="text" name="pathNew" style="width: 100%"></input></p>'
        );

        popDir.append(popDirfrm);
        $(this.options.scPortletContentDiv).append(popDir);

        popDir.dialog({
          autoOpen: false,
          closeOnEscape: true,
          width: 400,
          minWidth: 400,
          modal: true,
          buttons: {
            Create: function () {
              var formdata = {
                scCsrf: getLocalToken(),
                document_folder_key: popDirfrm
                  .find("input[name='document_folder_key']")
                  .val(),
                path: popDirfrm.find("input[name='path']").val(),
                pathNew: popDirfrm.find("input[name='pathNew']").val(),
              };

              var UpdateUrl =
                self.options.scContentUrl +
                "/fold/create/" +
                new Date().getTime();
              $.post(UpdateUrl, formdata, function (data) {
                //alert(data);
              })
                .done(function () {
                })
                .fail(function (jqXHR, s, e) {
                  $(popDir).dialog("close");
                  self._refresh();
                  self.PostError(jqXHR, s, e);
                })
                .always(function () {
                  $(popDir).dialog("close");
                  self._refresh();
                });
            },
            Cancel: function () {
              $(this).dialog("close");
            },
          },
          open: function () {
            popDirfrm
              .find("input[name='document_folder_key']")
              .val(self.options.document_folder_key);
            popDirfrm.find("input[name='path']").val(self.options.path);
            popDirfrm.find("input[name='pathNew']").val("").focus();
          },
        });

        var tbutton = $('<button type="button">Expand All</button>')
          .button({ text: false, icons: { primary: "scExpandAll" } })
          .click(function (e) {
            var t = $(self.options.scTree);
            if ($(tbutton).button("option").icons.primary === "scExpandAll") {
              $(tbutton).button("option", "icons", { primary: "scColapseAll" });
              t.jstree("open_all");
            }
            else {
              $(tbutton).button("option", "icons", { primary: "scExpandAll" });
              t.jstree("close_all");
              t.jstree("select_node", "ul > li:first");
              t.jstree("open_node", "ul > li:first");
            }
          });

        divLeft.append(tbutton);

        this.options.btnAddFolder = $(
          '<button type="button" title="Add Folder">Add Folder</button>'
        )
          .button({ text: false, icons: { primary: "scAddFolder" } })
          .css({ display: "none" })
          .click(function () {
            $(popDir).dialog("open");
          })
          .appendTo(divLeft);

        this.options.btnDelFolder = $(
          '<button type="button" title="">Delete Folder</button>'
        )
          .button({ text: false, icons: { primary: "scDelFolder" } })
          .css({ display: "none" })
          .click(function () {
            self.MsgBox(self.options.scPortletContentDiv, {
              title: "Delete Folder:",
              msg: "Do you wish to delete the selected folder?",
              buttons: {
                Yes: function () {
                  var selected = $(self.options.scTree)
                    .jstree()
                    .get_selected()[0];
                  if (selected && selected !== "-1") {
                    $(self.options.scTree).jstree().delete_node(selected);
                  }
                  $(this).dialog("close");
                },
                No: function () {
                  $(this).dialog("close");
                },
              },
            });
          })
          .appendTo(divLeft);

        var popFoldSel = {};

        //permissions START
        var popFoldPer = $("<div/>");
        var perTable = $("<div/>");
        popFoldPer.append(perTable).css({ padding: "0px 0px 0px 0px" });

        $(this.options.scPortletContentDiv).append(popFoldPer);
        var permdata = {};
        var gt;
        var gg;

        popFoldPer.dialog({
          autoOpen: false,
          closeOnEscape: true,
          title: "Permissions",
          classes: { "ui-dialog": "no-padding" },
          width: 1030,
          minWidth: 740,
          height: 455,
          minHeight: 455,
          modal: true,
          resizable: true,
          resizeStop: function (event, ui) {
            //if (gt) {
            //    $(gt).setGridWidth($(popFoldPer).width() - 1, false);
            //    $(gt).setGridHeight($(popFoldPer).height() - 88, true);
            //}
          },

          buttons: {
            OK: {
              text: "OK",
              id: "ag-ok-button",
              click: function () {
                $("#ag-ok-button").attr("disabled", true);

                var formdata = {
                  action: "folderpermissions",
                  scCsrf: getLocalToken(),
                  dkey: self.options.document_folder_key,
                  data: oAjax.Json.stringify(permdata),
                };

                var UpdateUrl =
                  self.options.scContentUrl + "/" + new Date().getTime();
                $.post(UpdateUrl, formdata, function (data) {
                })
                  .done(function () {
                  })
                  .fail(function (jqXHR, s, e) {
                    $(popFoldPer).dialog("close");
                    self._refresh();
                    self.PostError(jqXHR, s, e);
                  })
                  .always(function () {
                    $("#ag-ok-button").attr("disabled", false);
                    $(popFoldPer).dialog("close");
                    self._refresh();
                  });
              },
            },
            Cancel: function () {
              $(this).dialog("close");
            },
          },
          close: function () {
            $(".ag-holder-library-permissions").empty();
          },
          open: function () {
            if (!$(this).hasClass("ag-theme-belham")) {
              $(this)
                .addClass("ag-holder-library-permissions")
                .addClass("sc-aggrid-wrap-header");
              $(this).addClass("ag-theme-balham");
              $(this).addClass("sc-ag-theme");
            }
            var UpdateUrl =
              self.options.scContentUrl + "/" + new Date().getTime();
            $.post(
              UpdateUrl,
              {
                scCsrf: getLocalToken(),
                action: "folderusers",
                dkey: self.options.document_folder_key,
              },
              function (data) {
                $(perTable).empty();
                if (data.error) perTable.append($("<div/>").text(data.error));
                else {
                  permdata = data.users;

                  var gridData = {
                    rows: permdata,
                    columns: [
                      {
                        description: "Group / User",
                        key: "description",
                        type: "nonClickableColumn",
                      },
                      {
                        description: "View This\nFolder",
                        key: "view_folder",
                        type: "tickColumn",
                        cellRenderer: "booleanCellRender",
                        cellStyle: { "text-align": "center" },
                      },
                      {
                        description: "Create a\nSub-Folder",
                        key: "create_folder",
                        type: "tickColumn",
                        cellRenderer: "booleanCellRender",
                        cellStyle: { "text-align": "center" },
                      },
                      {
                        description: "Delete This\nFolder",
                        key: "delete_folder",
                        type: "tickColumn",
                        cellRenderer: "booleanCellRender",
                        cellStyle: { "text-align": "center" },
                      },
                      {
                        description: "Upload\nFiles",
                        key: "upload_files",
                        type: "tickColumn",
                        cellRenderer: "booleanCellRender",
                        cellStyle: { "text-align": "center" },
                      },
                      {
                        description: "Download\nFiles",
                        key: "download_files",
                        type: "tickColumn",
                        cellRenderer: "booleanCellRender",
                        cellStyle: { "text-align": "center" },
                      },
                      {
                        description: "Delete\nFiles",
                        key: "delete_files",
                        type: "tickColumn",
                        cellRenderer: "booleanCellRender",
                        cellStyle: { "text-align": "center" },
                      },
                      {
                        description: "View Own\nFiles Only",
                        key: "view_user_files_only",
                        type: "tickColumn",
                        cellRenderer: "booleanCellRender",
                        cellStyle: { "text-align": "center" },
                      },
                    ],
                    columnTypes: {
                      nonClickableColumn: {},
                      tickColumn: {},
                    },
                  };

                  var gridOptions = {
                    headerHeight: 46,
                    components: {
                      booleanCellRender: agGridController.BooleanCellRender,
                    },
                    onCellClicked: function (param) {
                      var cell = param.column.colId;
                      if (cell === "description") {
                        return;
                      }

                      if (param.data.hasOwnProperty(cell)) {
                        param.data[cell] = !param.data[cell];
                      }
                      else {
                        param.data[cell] = true;
                      }

                      gridAPI.refreshCells();
                    },
                  };

                  gridOptions.onGridReady = function (params) {
                    agGridController.smartAutoSize(gridAPI, null, 1030, 200);
                  };

                  var gridAPI = agGridController.grid(
                    ".ag-holder-library-permissions",
                    gridData,
                    false,
                    gridOptions
                  );
                }
              },
              "json"
            );
          },
          resize: function (event, ui) {
            // $.scResizeJqGrid(gg, this);
          },
        });

        this.options.btnPer = $(
          '<button type="button" title="">Permissions</button>'
        )
          .button({ text: false, icons: { primary: "scUserPermissions" } })
          .css({ display: "none" })
          .click(function () {
            $(popFoldPer).dialog("open");
          })
          .appendTo(divLeft);
        //permissions END

        this.element
          .closest("div.sc-layout")
          .on("scLayout:SaveState", function (evt, layout) {
            self.options.scTree.width(self.element.width() - 4);
          });

        this.options.GridFiles = $.sc_unique_element("<div/>", "div");
        divRight.append(this.options.GridFiles);
        this.options.GridEvidence = $.sc_unique_element("<div/>", "div");
        this.options.GridFile = $.sc_unique_element("<div/>", "div");

        this.options.tabs = $("<div/>");
        divRight.append(this.options.tabs);
        this.options.tabs.scTabs({
          scPortlets: [
            {
              title: "References",
              portlet: this.options.GridEvidence,
              onClick: function () {
                if (self.options.GridEvidence) {
                  self.options.GridEvidence.scPortlet(
                    "option"
                  ).scPortletContentDiv.scGrid("reLoad", {
                    fKey: self.options.fKey,
                  });
                }
              },
            },
            {
              title: "Versions",
              portlet: this.options.GridFile,
              onClick: function () {
                if (self.options.GridFile) {
                  self.options.GridFile.scPortlet(
                    "option"
                  ).scPortletContentDiv.scGrid("reLoad", {
                    fKey: self.options.fKey,
                  });
                }
              },
            },
          ],
        });

        //this.options.scForm.append($("<button type=\"button\" title=\"\">" + (self.options.addAsEvidence ? "Attach" : "Download") + "</button>").click(function () {
        //        if (self.options.addAsEvidence)
        //            self._attach(DKey, fileName);
        //        else
        //            window.location = self.options.scContentUrl.replace("content", "download") + "/" + DKey + "/" + new Date().getTime();

        var divFileContent = $("<div/>").css({
          left: "0px",
          top: "0px",
          width: "auto",
          padding: "4px",
          "padding-top": "0px",
        });
        divFileContent.append(this.options.scForm);

        this.options.GridEvidence.scPortlet({
          scType: "scGrid",
          scTitle: "References",
          scHeader: false,
          scPortletKey: "grdLibraryEvidence",
          scCsrf: getLocalToken(),
          scHeight: 315,
          scFold: false,
          scResize: false,
          scPortletData: {
            scEditURL: self.options.scContentUrl + "/" + new Date().getTime(),
          },
          scDragable: false,
          scClose: false,
          scConfig: false,
          scLayout: this.options.scLayout,
        });

        //jQuery('<span/>').addClass('ui-icon portlet-header-icon ui-icon-minusthick').css("cursor", "pointer").bind('click', function () {
        //    $(this).toggleClass("ui-icon-minusthick").toggleClass("ui-icon-plusthick");
        //    $(this).parents(".portlet:first").find(".portlet-content").toggle();
        //    if (self.options.scContainer)
        //        self.options.scContainer.resize();
        //}).appendTo(this.options.scPortletTitleDiv);

        this.options.btnAddFile = $('<span title="Upload File"/>')
          .addClass("ui-icon portlet-header-icon scAddFile")
          .css({ cursor: "pointer", float: "left", "margin-left": "6px" })
          .bind("click", function () {
            self._addFilePopup();
          });

        this.options.btnDelFile = $('<span title="Delete File"/>')
          .addClass("ui-icon portlet-header-icon scDelFile")
          .css({ cursor: "pointer", float: "left", "margin-left": "6px" })
          .bind("click", function () {
            self.MsgBox(self.options.scPortletContentDiv, {
              title: "Delete File:",
              msg: "Do you wish to delete the selected file?",
              buttons: {
                Yes: function () {
                  $.post(
                    self.options.scContentUrl + "/" + new Date().getTime(),
                    {
                      action: "deletefile",
                      scCsrf: getLocalToken(),
                      fKey: self.options.fKey,
                    },
                    function (data) {
                    }
                  ).always(function () {
                    self._refresh();
                  });
                  $(this).dialog("close");
                },
                No: function () {
                  $(this).dialog("close");
                },
              },
            });
          });

        function FormatAttachCell(cellvalue) {
          const img = $('<img>')
            .addClass('jqDownloadIcon jqDownloadHand')
            .attr('title', 'Attach document to requirement.')
            .attr('src', 'images/icons/paperclip.png')
            .attr('scf', 'scFuncAttacha')
            .attr('sca', cellvalue)
          return img[0].outerHTML;
        }

        this.options.GridFiles.scPortlet({
          scType: "scGrid",
          scTitle: "Files",
          scPortletKey: "grdLibraryFiles",
          scCsrf: getLocalToken(),
          scHeight: 315,
          scFold: false,
          scResize: false,
          scDragable: false,
          scClose: false,
          scConfig: false,
          scLayout: this.options.scLayout,
          scPortletData: {
            def: { dKey: null, addAsEvidence: self.options.addAsEvidence },
            scEditURL:
              self.options.scContentUrl +
              "/editFile/latest/" +
              new Date().getTime(),
            scFuncAttach: function (cellvalue, options, rowObject) {
              return FormatAttachCell(cellvalue);
            },
            scFuncAttacha: function (cellvalue) {
              if (cellvalue !== undefined && cellvalue !== "") {
                var a = cellvalue.split("|");
                oEvidence.add(a[0], a[1]); //(documentkey, fileName)
              }
            },
            onCellSelect: function (id) {
              self.options.fKey = id;
              //self.options.GridFile.data().scPortlet.options.scPortletContentDiv.data().scGrid.reLoad({ "fKey": id });
              //self.options.GridEvidence.data().scPortlet.options.scPortletContentDiv.data().scGrid.reLoad({ "fKey": id });
              self.options.GridFile.scPortlet(
                "option"
              ).scPortletContentDiv.scGrid("reLoad", { fKey: id });
              self.options.GridEvidence.scPortlet(
                "option"
              ).scPortletContentDiv.scGrid("reLoad", { fKey: id });
            },
          },
          scTitleJQ: [this.options.btnAddFile, this.options.btnDelFile],
        });

        this.options.GridFile.scPortlet({
          scType: "scGrid",
          scTitle: "File",
          scPortletKey: "grdLibraryFiles",
          scCsrf: getLocalToken(),
          scHeader: false,
          scHeight: 315,
          scResize: false,
          scDragable: false,
          scFold: false,
          scClose: false,
          scConfig: false,
          scLayout: this.options.scLayout,
          scPortletData: {
            def: { fKey: -2, addAsEvidence: self.options.addAsEvidence },
            scEditURL:
              self.options.scContentUrl +
              "/editFile/version/" +
              new Date().getTime(),
            scFuncAttach: function (cellvalue, options, rowObject) {
              return FormatAttachCell(cellvalue);
            },
            scFuncAttacha: function (cellvalue) {
              if (cellvalue !== undefined && cellvalue !== "") {
                var a = cellvalue.split("|");
                oEvidence.add(a[0], a[1]); //(documentkey, fileName)
              }
            },
          }, //,
          //scTitleJQ: $('<span/>').addClass('ui-icon portlet-header-icon scAddFile')
          //                       .css({ cursor: "pointer", float: "left" })
          //                       .bind('click', function () { $(popFile).dialog("open"); })
        });

        //$('body').bind('ajaxSuccess', function (event, request, settings) {
        //    alert("here");
        //});
      },

      // called when created, and later when changing options
      refresh: function () {
        this._refresh();
      },
      reDraw: function () {
        //if (this.options.GridFiles) this.callByName($(this.options.GridFiles), 'reDraw');
        //if (this.options.GridFile) this.callByName($(this.options.GridFile), 'reDraw');
        //if (this.options.GridEvidence) this.callByName($(this.options.GridEvidence), 'reDraw');

        if (this.options.GridFiles) {
          $(this.options.GridFiles[0]).trigger("scLayout:ReDrawPortlet");
        }
        if (this.options.GridFile) {
          $(this.options.GridFile[0]).trigger("scLayout:ReDrawPortlet");
        }
        if (this.options.GridEvidence) {
          $(this.options.GridEvidence[0]).trigger("scLayout:ReDrawPortlet");
        }
      },
      _destroy: function () {
      },

      _refresh: function () {
        $(this.options.scTree).jstree("refresh");
        //if (this.options.GridFiles) this.options.GridFiles.data().scPortlet.options.scPortletContentDiv.data().scGrid.reLoad({});
        //if (this.options.GridFile) this.options.GridFile.data().scPortlet.options.scPortletContentDiv.data().scGrid.reLoad({});
        //if (this.options.GridEvidence) this.options.GridEvidence.data().scPortlet.options.scPortletContentDiv.data().scGrid.reLoad({});

        if (this.options.GridFiles)
          this.options.GridFiles.scPortlet("option").scPortletContentDiv.scGrid(
            "reLoad",
            {}
          );
        if (this.options.GridFile)
          this.options.GridFile.scPortlet("option").scPortletContentDiv.scGrid(
            "reLoad",
            {}
          );
        if (this.options.GridEvidence)
          this.options.GridEvidence.scPortlet(
            "option"
          ).scPortletContentDiv.scGrid("reLoad", {});
      },

      _post: function (action) {
        var formdata = { scCsrf: getLocalToken() };
        var self = this;
        this.options.scForm.find("input").each(function (index, data) {
          formdata[data.name] = data.value;
        });

        var UpdateUrl =
          this.options.scContentUrl + "/" + action + "/" + new Date().getTime();
        $.post(UpdateUrl, formdata, function (data) {
          //alert(data);
        })
          .done(function () {
          })
          .fail(function (jqXHR, s, e) {
            self._refresh();
            self.PostError(jqXHR, s, e);
          })
          .always(function () {
            self._refresh();
          });
      },

      _addFilePopup: function () {
        var self = this;

        $.post(
          self.options.scContentUrl + "/" + new Date().getTime(),
          { scCsrf: getLocalToken(), action: "folderoptions" },
          function (data) {
            //var UploadURL = self.options.scContentUrl + "/" + new Date().getTime();
            var UploadURL =
              self.options.scContentUrl.replace("content", "file") +
              "/" +
              new Date().getTime() +
              "?scCsrf=" +
              getLocalToken();

            //tags
            var ipTags = $(
              '<input type="text" name="tags" style="width:100%"/>'
            );

            var availableTags = data.piTags;

            function split(val) {
              return val.split(/,\s*/);
            }

            function extractLast(term) {
              return split(term).pop();
            }

            $(ipTags)
              .bind("keydown", function (event) {
                if (
                  event.keyCode === $.ui.keyCode.TAB &&
                  $(this).data("uiAutocomplete") &&
                  $(this).data("uiAutocomplete").menu.active
                ) {
                  event.preventDefault();
                }
              })
              .autocomplete({
                minLength: 0,
                source: function (request, response) {
                  response(
                    $.ui.autocomplete.filter(
                      availableTags,
                      extractLast(request.term)
                    )
                  );
                },
                focus: function () {
                  return false;
                },
                classes: { "ui-autocomplete": "sc-autocomplete" },
                select: function (event, ui) {
                  var terms = split(this.value);
                  terms.pop();
                  terms.push(ui.item.value);
                  terms.push("");
                  this.value = terms.join(", ");
                  return false;
                },
              });

            var ipDnD = $('<input type="text" name="url" style="width:100%"/>');
            ipDnD.bind("dragenter", function ignoreDrag(e) {
              e.originalEvent.stopPropagation();
              e.originalEvent.preventDefault();
            });
            ipDnD.bind("dragover", function ignoreDrag(e) {
              e.originalEvent.stopPropagation();
              e.originalEvent.preventDefault();
            });
            ipDnD.bind("drop", function drop(e) {
              e.originalEvent.stopPropagation();
              e.originalEvent.preventDefault();
              ipDnD.val(e.originalEvent.dataTransfer.getData("text"));
            });

            var ipPI = $("<div/>").addClass("scProgressInner");
            var ipProgress = $('<div style="border:1px solid #888;"/>')
              .addClass("scProgress")
              .append(ipPI);

            var Options = {
              scForm: [
                {
                  description: "hidden:",
                  control: "input",
                  attr: { type: "hidden", name: "document_folder_key" },
                  value: self.options.document_folder_key,
                },
                {
                  description: "Current Path:",
                  control: "input",
                  attr: {
                    type: "text",
                    name: "path",
                    readonly: "readonly",
                    style: "color: #808080; width: 100%",
                  },
                  value: self.options.path,
                  lblcss: "scGrey",
                },
                {
                  description: "Select File:",
                  control: "input",
                  attr: {
                    type: "file",
                    name: "filename",
                    style: "width: 100%",
                    multiple: "multiple",
                  },
                  lblattr: { style: "padding-left: 10px;" },
                },
                { description: "OR", control: null },
                {
                  description: "Initiative:",
                  control: "select",
                  attr: { name: "initiative" },
                  data: data.piInitiatives,
                  lblattr: { style: "padding-left: 10px;" },
                },
                { description: "OR", control: null },
                {
                  description: "URL:",
                  given: ipDnD,
                  lblattr: { style: "padding-left: 10px;" },
                },
                {
                  description: "Short Name:",
                  control: "input",
                  attr: {
                    type: "text",
                    name: "shortname",
                    style: "width: 100%",
                  },
                },
                { description: null, control: "hr" },
                {
                  description: "Reference:",
                  control: "input",
                  attr: {
                    type: "text",
                    name: "reference",
                    style: "width: 100%",
                  },
                },
                {
                  description: "Description:",
                  control: "input",
                  attr: {
                    type: "text",
                    name: "description",
                    style: "width: 100%",
                  },
                },
                { description: "Tags:", given: ipTags },
                { description: "Progress:", given: ipProgress },
              ],
              tableattr: { style: "width: 100%" },
              formattr: {
                action: UploadURL,
                enctype: "multipart/form-data",
                method: "post",
              },
            };

            var f = $("<div/>");
            f.scForm(Options);
            $(f).scForm("populateForm", Options);

            var popFile = $('<div title="Add File">').css({
              height: "300px",
              width: "500px",
            });
            popFile.append(f);

            var popFilefrm = $(f.scForm("option").Form);
            var intervalID = undefined;

            function notifyOnFileUploadError(msg) {
              var $popDiv = $("<div>" + msg + "</div>");
              $(document.body).append($popDiv);
              $popDiv.dialog({
                title: "File Upload",
                autoOpen: true,
                closeOnEscape: true,
                width: "auto",
                modal: true,
                buttons: {
                  OK: function () {
                    $(this).dialog("close");
                  },
                },
                close: function (event, ui) {
                  $popDiv.remove();
                },
              });
            }

            function showProgress(showMsg) {
              $.post(
                self.options.scContentUrl + "/" + new Date().getTime(),
                {
                  scCsrf: getLocalToken(),
                  action: "fileProgress",
                  msg: showMsg,
                },
                function (data) {
                  var t = data.fileTotalSize;
                  var s = data.fileUploadedSize;
                  if (t > 0 && s > 0)
                    $(ipPI).css({ width: (s / t) * 100 + "%" });
                  else $(ipPI).css({ width: 0 });
                },
                "json"
              ).fail(function (j) {
                if (j.status === 454) {
                  document.cookie = "expiry=true";
                  browser.reload();
                }
              });
            }

            var uploadxhr = null;

            popFilefrm.ajaxForm({
              dataType: "json",
              data: { action: "uploadfile" },
              beforeSend: function (xhr) {
                xhr.setRequestHeader(
                  "requestGUID",
                  String(new Date().getTime() + Math.random())
                );
                uploadxhr = xhr;
              },
              beforeSubmit: function (arr, $form, options) {
                if (self.options.divPFPro == null) {
                  self.options.divPFPro = $("<div/>")
                    .addClass("sc-chart-loading")
                    .html("&nbsp;");
                  $(self.options.divPFProC).append(self.options.divPFPro);
                  intervalID = window.setInterval(showProgress, 330);
                }
              },
              complete: function (xhr) {
                if (self.options.divPFPro != null)
                  $(self.options.divPFPro).remove();
                self.options.divPFPro = null;
                self._refresh();
                popFile.dialog("close");
                clearInterval(intervalID);
                notifyOnFileUploadError($.parseJSON(xhr.responseText).error);
              },
              error: function (jqXHR, s, e) {
                $.ajaxSettings.error(jqXHR);
                clearInterval(intervalID);
              },
            });

            $(self.options.scPortletContentDiv).append(popFile);

            popFile.dialog({
              autoOpen: true,
              closeOnEscape: true,
              width: "400px",
              modal: true,
              buttons: {
                Upload: function (btn_evt) {
                  $(btn_evt.currentTarget).prop("disabled", true);
                  popFilefrm.submit();
                },
                Cancel: function () {
                  if (self.options.divPFPro != null)
                    $(self.options.divPFPro).remove();
                  self.options.divPFPro = null;
                  $(this).dialog("close");
                },
              },
              open: function () {
                popFilefrm.find("input[name='filename']").focus();
              },
              close: function (event, ui) {
                if (uploadxhr) uploadxhr.abort();
                $(this).dialog("destroy").remove();
              },
            });
          },
          "json"
        );
      },
    });
  });

  // scAddCommas is used in the tooltips,
  function scAddCommas(nStr) {
    nStr += "";
    var x = nStr.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    return x1 + x2;
  }

  $(function () {
    $.widget("custom.scTabs", $.custom.scBase, {
      // default options
      options: {
        scPortlets: [],
      },

      // the constructor
      _create: function () {
        this.prep();
        if (this.options.init != false) this.postCreate();
      },

      postCreate: function (o) {
        if (o !== undefined) this.options = o;
        var self = this;
        //this.options.scParent = this.element[0];
        if (!this.options.scPortletContentDiv)
          this.options.scPortletContentDiv = $(this.element[0]);

        var t = $("<ul/>");
        for (var i = 0; i < this.options.scPortlets.length; i++) {
          var ip = this.options.scPortlets[i];
          ip.ref = $(ip.portlet).attr("id");
          const href = location.href;
          $('<li><a href="' + href + '#' + ip.ref + '">' + ip.title + "</a></li>")
            .click(this.options.scPortlets[i].onClick)
            .appendTo(t);
        }

        $(this.options.scPortletContentDiv).append(t);

        for (var j = 0; j < this.options.scPortlets.length; j++) {
          var jp = this.options.scPortlets[j];
          $(this.options.scPortletContentDiv).append(jp.portlet);
        }

        $(this.options.scPortletContentDiv).tabs({});
        $(this.options.scPortletContentDiv).css({ padding: "0px" });
      },

      // called when created, and later when changing options
      reDraw: function () {
      },
      _destroy: function () {
      },
    });
  });

  $(function () {
    $.widget("custom.scTreeContent", $.custom.scBase, {
      // default options
      options: {
        scParent: null,
        scContentUrl: "",
        scPortletKey: "",
        scPortletDiv: null,
        scPortletTitleDiv: null,
        scPortletContentDiv: null,
        scTree: null,
        scTitle: "",
        scPortletData: "",
        scWidth: "100%",
        scHeight: "100%",
      },

      // the constructor
      _create: function () {
        this.prep();
        if (this.options.init != false) this.postCreate();
      },

      postCreate: function (o) {
        if (o !== undefined) this.options = o;
        var self = this;
        this.options.scParent = this.element[0];

        var TreeUrl =
          self.options.scContentUrl + "/tree/" + new Date().getTime();
        //$(this.options.scParent).addClass("portlet ui-widget ui-widget-content ui-helper-clearfix ui-corner-all");
        //.css({ "width": this.options.scWidth, "height": this.options.scHeight });

        var divLeft = $("<div/>").addClass("pane ui-layout-west");
        var divRight = $("<div/>").addClass("pane ui-layout-center");
        this.options.scTree = $("<div/>").css({
          left: "0px",
          top: "0px",
          right: "0px",
          height: "100%",
          "background-color": "#ffffff",
        });
        $(this.options.scParent).append(divLeft);
        $(this.options.scParent).append(divRight);
        $(divLeft).append(this.options.scTree);

        $(this.options.scTree)
          .jstree({
            json_data: { ajax: { url: TreeUrl } },
            plugins: ["themes", "json_data", "ui"],
          })
          .bind("select_node.jstree", function (e, data) {
            alert(
              data.rslt.obj.data("type") +
              ", " +
              data.rslt.obj.data("key") +
              ", " +
              data.rslt.obj.data("desc")
            );
          });

        $(this.options.scParent).layout({ enableCursorHotkey: false });
      },

      reDraw: function () {
      },

      refresh: function () {
      },

      _destroy: function () {
      },
    });
  });

  $(function () {
    $.widget("custom.scGantt", $.custom.scBase, {
      // default options
      options: {
        scContentUrl: "",
        scPortletDiv: null,
        scPortletData: null,
        piGantt: null,
      },

      // the constructor
      _create: function () {
        var self = this;
        $(this.element).on("scPortlet:forceWidth", function (e, w) {
          self.forceWidth(w);
        });

        this.prep();
        if (this.options.init != false) this.postCreate();
      },

      postCreate: function (o) {
        if (o !== undefined) this.options = o;
        this._refresh();
        this.element.addClass("scForceWidth");
      },

      _refresh: function () {
        var self = this;
        self._showProcessing();
        //this.options.scParent = this.element[0];
        if (!this.options.scPortletContentDiv)
          this.options.scPortletContentDiv = $(this.element[0]);

        var DataUrl = self.options.scContentUrl + "/" + new Date().getTime();

        //Load portlet config data.
        var pdp = this.options.scPortletData; // { scCsrf: self.options.scCsrf };
        //$.extend(this.options.scPortletData, $(this.element[0]).parent().data("scPortlet").options.scPortletData);
        //$.extend(this.options.scPortletData, this.options.scPortletData.scPortletOptions.scPortletData);

        //$.each(self.options.scPortletData, function (key, value) {
        //    if (typeof value != "function")
        //        pdp[key] = value;
        //});
        var targetWidth = $(this.element).width();

        $.post(
          DataUrl,
          pdp,
          function (data) {
            if (self.options.piGantt) $(self.options.piGantt).remove();

            self.options.piGantt = $("<div/>").width(targetWidth);
            $(self.options.scPortletContentDiv).append(self.options.piGantt);

            if (data.data.length > 0) {
              var g = $(self.options.piGantt).gantt({
                source: data.data,
                scale: "weeks",
                minScale: "days",
                maxScale: "months",
                itemsPerPage: 15,
                onItemClick: function (data) {
                  eval(data);
                  // alert("Item clicked - show some details");
                },
                onAddClick: function (dt, rowId) {
                  //alert("Empty space clicked - add an item!");
                },
                onRender: function () {
                  //alert("chart rendered");
                },
              });
            }
            else self.options.piGantt.text("No Data");
          },
          "json"
        )
          .done(function () {
          })
          .fail(function (jqXHR, s, e) {
            self.PostError(jqXHR, s, e);
          })
          .always(function () {
            self._hideProcessing();
          });
      },

      reLoad: function (data) {
        this._refresh();
      },

      // called when created, and later when changing options
      refresh: function () {
        this._refresh();
      },

      reDraw: function () {
        if (this.options.piGantt) {
          $(this.options.piGantt).width($(this.element).width());
          $(this.options.piGantt).height($(this.element).height() - 60);
        }
      },

      forceWidth: function (w) {
        if (this.options.piGantt) {
          $(this.options.piGantt).width(w);
          $(this.options.piGantt).height($(this.element).height() - 60);
        }
      },

      _destroy: function () {
        $(this.options.piGantt).remove();
      },
    });
  });

  $(function () {
    function componentToHex(c) {
      var hex = c.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    }

    function tintCalc(inputString, tintFactor) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
        inputString
      );
      if (!result) {
        return inputString;
      }
      var rgb = {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      };
      var out = {
        r: Math.round(rgb.r + (255 - rgb.r) * tintFactor),
        g: Math.round(rgb.g + (255 - rgb.g) * tintFactor),
        b: Math.round(rgb.b + (255 - rgb.b) * tintFactor),
      };
      return (
        componentToHex(out.r) + componentToHex(out.g) + componentToHex(out.b)
      );
    }

    var piColoursWithBranding = [
      "#EF5350",
      "#FF7043",
      "#FFB74D",
      "#FFFFFF",
      "#FFFFFF",
      "#FFFFFF", //Note the last 3 are overwritten in updateBranding
      "#FFD54F",
      "#FDEC56",
      "#DCE775",
      "#AED581",
      "#81C784",
      "#90A4AE",
      "#F06292",
      "#BA68C8",
      "#9575CD",
      "#7986CB",
      "#64B5F6",
      "#4FC3F7",
      "#4DD0E1",
      "#4DB6AC",
      "#A1887F",
      "#931635",
      "#53B0C9",
      "#3D3D3D",
      "#F8F8F8",
      "#F3F3F3",
      "#D9D9D9",
      "#9E9E9E",
      "#404040",
      "#B00020",
      "#990000",
      "#999900",
      "#3d993d",
      "#3D9999",
      "#3d3d99",
      "#993D99",
    ];

    oOrganisation.updateBranding = function () {
      var branding_menu =
        "#" +
        (oOrganisation.menuColour !== undefined
          ? oOrganisation.menuColour
          : "FFFFFF");
      var branding_tint =
        "#" +
        tintCalc(
          oOrganisation.menuColour !== undefined
            ? oOrganisation.menuColour
            : "FFFFFF",
          0.4
        );
      var branding_text =
        "#" +
        (oOrganisation.textColour !== undefined
          ? oOrganisation.textColour
          : "FFFFFF");
      piColoursWithBranding[3] = branding_menu;
      piColoursWithBranding[4] = branding_tint;
      piColoursWithBranding[5] = branding_text;
    };

    $.widget("custom.scColour", {
      // default options
      options: {
        scParent: null,
        piTarget: null,
        piColour: "",
        piTable: null,
        piColourRows: 6,
        piColourCols: 6,
        piExample: null,
      },

      _create: function () {
        this.options.piColours = piColoursWithBranding;
        if (this.options.init != false) this.postCreate();
      },

      postCreate: function (o) {
        if (o !== undefined) this.options = o;
        var self = this;
        this.options.scParent = this.element[0];

        this.options.piTable = $('<table style="float: left"/>').addClass(
          "scColourPicker"
        );

        var p = 0;
        var s = self.options.piTarget ? $(self.options.piTarget).val() : "";
        for (var r = 0; r < self.options.piColourRows; r++) {
          var row = $("<tr/>");
          for (var c = 0; c < self.options.piColourCols; c++) {
            $("<td/>")
              .addClass(
                this.options.piColours[p] === s ? "scColourPickerSel" : ""
              )
              .css({ "background-color": this.options.piColours[p++] })
              .appendTo(row);
          }
          this.options.piTable.append(row);
        }
        $(this.options.scParent)
          .click(function (e) {
            if ($(e.target).is("td")) {
              var A = $(e.target).css("background-color");
              if (self.options.piTarget)
                $(self.options.piTarget).val(self.rgb2hex(A));
              $(self.options.piTable)
                .find("td")
                .removeClass("scColourPickerSel");
              $(e.target).addClass("scColourPickerSel");
              if (self.options.piColpick)
                self.options.piColpick.colpickSetColor(self.rgb2hex(A), true);
            }
          })
          .append(this.options.piTable);

        this.options.piColpick = $(
          '<div style="float: left; padding-left: 4px;"/>'
        ).colpick({
          flat: true,
          layout: "rgbhex",
          submit: 0,
          color: s,
          onChange: function (hsb, hex, rgb, el, bySetColor) {
            if (self.options.piTarget) $(self.options.piTarget).val("#" + hex);
            if (self.options.piExample)
              $(self.options.piExample).css({ "background-color": "#" + hex });
          },
        });

        $(this.options.scParent).append(this.options.piColpick);
      },

      rgb2hex: function (rgb) {
        var hexDigits = [
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
        ];
        var hex = function (x) {
          return isNaN(x)
            ? "00"
            : hexDigits[(x - (x % 16)) / 16] + hexDigits[x % 16];
        };
        rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
        return "#" + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
      },

      reDraw: function () {
      },
      refresh: function () {
      },
      _destroy: function () {
      },
    });
  });

  ////http://www.darreningram.net/pages/examples/jQuery/CollapsiblePanelPlugin.aspx
  //(function ($) {
  //    $.fn.extend({
  //        ConfigureCollapsiblePanel: function () {
  //            $(this).addClass("ui-widget");
  //            if ($(this).children().length == 0) {
  //                $(this).wrapInner("<div></div>");
  //            }
  //            $(this).children().wrapAll("<div class='collapsibleContainerContent ui-widget-content ui-corner-bottom'></div>");
  //            $("<div class='collapsibleContainerTitle ui-widget-header ui-corner-all'><div>" + $(this).attr("title") + "</div></div>").prependTo($(this));
  //            $(".collapsibleContainerTitle", this).click(CollapsibleContainerTitleOnClick);
  //            return $(this);
  //        }
  //    });
  //})(jQuery);

  //function CollapsibleContainerTitleOnClick() {
  //    $(".collapsibleContainerContent", $(this).parent()).slideToggle(400, function() {
  //            //if(this.height() > 0){
  //            //
  //            //}
  //            //else
  //            //{
  //            //   alert("ouch");
  //            //        $(this).toggleClass("ui-icon-minusthick").toggleClass("ui-icon-plusthick");
  //            //}
  //        });
  //}

  $.fn.clearForm = function () {
    return this.each(function () {
      var type = this.type,
        tag = this.tagName.toLowerCase();
      if (tag === "form") return $(":input", this).clearForm();
      if (type === "text" || type === "password" || tag === "textarea")
        this.value = "";
      else if (type === "checkbox" || type === "radio") this.checked = false;
      else if (tag === "select") this.selectedIndex = -1;
    });
  };

  $(function () {
    $.widget("custom.scForm", {
      // default options
      options: {
        Form: null,
        piData: null,
        scParent: null,
        scForm: [],
        //                    [{ description: "Current Path:", control: "input", value: "Hello World 1", attr: { type: "text", name: "path", size: 20, readonly: "readonly"} },
        //                     { description: "Select File:", control: "input", value: "Hello World 2", attr: { type: "file", name: "filename"} },
        //                     { description: "Description:", control: "input", value: "Hello World 3", attr: { type: "text", name: "description", size: 20} },
        //                     { description: "List:", control: "select ", value: "1", attr: { name: "list" }, data: [{ k: "1", v: "one" }, { k: "2", v: "two" }, { k: "3", v: "three", s: true }, { k: "4", v: "four"}] },
        //                     { description: "text:", control: "span", attr: { text: " some text"}}]
      },

      // the constructor
      _create: function () {
        if (this.options.init != false) this.postCreate();
      },

      postCreate: function (o) {
        if (o !== undefined) this.options = o;
        this.options.scParent = this.element[0];
        this.options.Form = $("<form/>", this.options.formattr).addClass(
          "scJqForm"
        ); //.css({"max-height": 400, "overflow-y": "auto"});
        this.options.uiButtonSet = $("<div/>").addClass("ui-dialog-buttonset");
      },

      populateForm: function (po) {
        var self = this;

        $.extend(this.options, po);

        this.options.uiButtonSet.empty();
        this.options.Form.empty();

        this.options.scControls = {};
        this.options.scCtrlData = {};
        this.options.scRows = {};

        var LoadSelects = function (cc, t) {
          $.each(cc, function (kk, vv) {
            if (!vv.c) return;
            LoadSelect(vv.d, vv.c, t);
          });
        };

        function htmlEscape(str) {
          return String(str)
            .replace(/&/g, "&amp;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#39;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;");
        }

        var LoadSelect = function (d, c, t) {
          var v = c.val();
          $(c).empty();
          $.each(d, function (key, value) {
            var o = $(
              "<option " + (value.s ? 'selected="selected"' : "") + "></option>"
            ).attr("value", value.k);
            if (value.tag !== undefined) o.attr("scTag", value.tag);
            if (value.i > 0)
              o.html(
                Array(parseInt(value.i) + 1).join("-&nbsp;") +
                htmlEscape(value.v)
              );
            else o.text(value.v);

            if (value.hideControls)
              o.data("sc.hideControls", value.hideControls);

            if (t === undefined || t === value.tag) c.append(o);
          });
          c.val(v);
        };

        var HideControls = function (s) {
          $(s)
            .find("option:selected")
            .each(function () {
              var c = $(this).data("sc.hideControls");
              if (c) {
                $.each(self.options.scControls, function (key, value) {
                  if ($.inArray(value[0].name, c) >= 0)
                    self.options.scRows[value[0].name].hide();
                  else if ($.inArray("!" + value[0].name, c) >= 0)
                    self.options.scRows[value[0].name].show();
                });
              }
            });
        };

        var FilterControls = function (s) {
          var d = $(s).data("sc.CtrlToFilter");
          if (d) {
            var cc = $.map($(s).data("sc.CtrlToFilter"), function (n) {
              return {
                name: n,
                c: self.options.scControls[n],
                d: self.options.scCtrlData[n],
              };
            });
            $(s)
              .find("option:selected")
              .each(function () {
                LoadSelects(cc, $(this).attr("scTag"));
              });
          }
        };

        var t = $("<table/>", this.options.tableattr);
        for (var i = 0; i < this.options.scForm.length; i++) {
          var objF = this.options.scForm[i];
          if (objF.attr && !objF.attr.id) {
            objF.attr.id = objF.attr.name;
          }
          objF.css = $.extend(objF.css, {});
          var tr = $("<tr/>");
          if (objF.control === "hr") tr.append('<td colspan="3"><hr/></td>');
          else if (objF.control === "title")
            $('<th colspan="3"/>', objF.lblattr)
              .addClass(objF.lblcss)
              .css(objF.css)
              .text(objF.description)
              .appendTo(tr);
          else if (objF.control === "button") {
            var button = $('<button type="button"></button>')
              .css({ float: "right" })
              .text(objF.description)
              .attr("name", objF.name)
              .click(function () {
                self.options.onButtonClick(self.values(), $(this).attr("name"));
              })
              .appendTo(self.options.uiButtonSet);
            if ($.fn.button) {
              button.button();
            }
          }
          else {
            var id = objF.attr ? objF.attr.id : null;
            var l = $("<label/>", objF.lblattr)
              .attr("for", id)
              .addClass(objF.lblcss)
              .addClass(objF["class"])
              .css(objF.css)
              .text(objF.description);
            tr.append($("<td/>").append(l));
            var td = $("<td/>");

            if (objF.given) td.append(objF.given);
            else if (objF.control === "tree") {
              objF.scTree = $("<div/>").css({
                left: "0px",
                top: "0px",
                right: "0px",
                height: "200px",
                "background-color": "#ffffff",
              });

              var to = {
                json_data: { data: objF.data },
                dnd: { drop_target: false, drag_target: false },
                plugins: ["themes", "json_data", "ui", "crrm"],
              };
              if (objF.move != false) {
                to.crrm = {
                  move: {
                    check_move: function (m) {
                      var p = this._get_parent(m.o);
                      if (!p) return false;
                      p = p === -1 ? this.get_container() : p;
                      if (p === m.np) return true;
                      if (p[0] && m.np[0] && p[0] === m.np[0]) return true;
                      return false;
                    },
                  },
                };
                to.plugins.push("dnd");
              }
              if (objF.check != false) to.plugins.push("checkbox");

              $(objF.scTree).jstree(to);

              //                        $(objF.scTree).jstree({
              //                            "json_data": { "data": objF.data },
              //                            "crrm": {
              //                                "move": {
              //                                    "check_move": function (m) {
              //                                        var p = this._get_parent(m.o);
              //                                        if (!p) return false;
              //                                        p = p == -1 ? this.get_container() : p;
              //                                        if (p === m.np) return true;
              //                                        if (p[0] && m.np[0] && p[0] === m.np[0]) return true;
              //                                        return false;
              //                                    }
              //                                }
              //                            },
              //                            "dnd": {
              //                                "drop_target": false,
              //                                "drag_target": false
              //                            },
              //                            "plugins": ["themes", "json_data", "ui", "checkbox", "crrm", "dnd"]
              //                        });
              td.append(objF.scTree);
            }
            else {
              var objCL = null;
              if (objF.attr && objF.attr.type === "hidden") tr.hide();

              var objC = $("<" + objF.control + "/>", objF.attr);
              if (
                objF.attr &&
                objF.attr.type &&
                objF.attr.type === "checkbox"
              ) {
                objCL = $("<label/>")
                  .attr("for", objC.attr("id"))
                  .append("<span/>");
                if (self.options.piData)
                  objC.attr(
                    "checked",
                    self.options.piData[objF.attr.name] === "checked"
                  );
              }
              //if (objF.attr && objF.attr.type && objF.attr.type === "text") { td.css({ "padding-right": "12px" }); }
              if (objF.value) objC.attr("value", objF.value);
              if (self.options.piData && objF.action !== undefined) {
                objC.attr("scAction", objF.action).attr("name", objF.attr.name);
                objC.change(function (e) {
                  var d = $.extend(
                    { scCsrf: getLocalToken() },
                    self.options.piData,
                    self.values()
                  );
                  d.action = $(this).attr("scAction");
                  if (d.action === "post")
                    self.options.onButtonClick(
                      self.values(),
                      $(this).attr("name")
                    );
                  else
                    $.post(
                      self.options.piData.scContentUrl,
                      d,
                      function (data) {
                        //Update Form.
                        //alert('Ouch')
                      },
                      "json"
                    );
                });
              }
              if (self.options.piData && objF.CtrlToFilter !== undefined) {
                objC.data("sc.CtrlToFilter", objF.CtrlToFilter);
                objC.change(function (e) {
                  FilterControls(this);
                });
              }
              else if (self.options.piData && objF.hideControls)
                objC.change(function (e) {
                  HideControls(this);
                });

              if (objF.data) LoadSelect(objF.data, objC);
              if (objF.render === "date") {
                var locale = $.sc_locale(objF.locale);
                $.datepicker.setDefaults(locale);
                var objCH = $(
                  '<input style="width: 100%;" autocomplete="off"/>',
                  { type: "text" }
                )
                  .hide()
                  .datepicker({
                    onClose: function () {
                      $(this).hide();
                      $(this).prev().val($(this).val());
                      var date = $(this).datepicker("getDate");
                      $(this)
                        .prev()
                        .attr("scDate", date ? date.getTime().toString() : "");
                      $(this).prev().show();
                    },
                  })
                  .off()
                  .change(function () {
                    $(this).prev().val($(this).val());
                  });
                objC.click(function () {
                  var epochDate = new Date(parseInt($(this).attr("scDate")));
                  if (isNaN(epochDate)) {
                    epochDate = $.datepicker.parseDate(
                      "dd/mm/yy",
                      $(this).val()
                    );
                  }
                  if (epochDate > 0) {
                    $(this).next().datepicker("setDate", epochDate);
                  }
                  $(this).hide();
                  $(this).next().show().datepicker("show");
                });
              }
              else {
                var objCH = null;
              }

              if (self.options.piData && objF.attr && objF.attr.name) {
                self.options.scControls[objF.attr.name] = objC;
                self.options.scCtrlData[objF.attr.name] = objF.data;
                if (self.options.piData[objF.attr.name]) {
                  if (objF.attr.multiple === "multiple") {
                    objC.val(
                      self.options.piData[objF.attr.name].toString().split(",")
                    );
                  }
                  else {
                    objC.val(self.options.piData[objF.attr.name]);
                  }
                }
                if (self.options.piData[objF.attr.name + "_epoch"]) {
                  objC.attr(
                    "scDate",
                    self.options.piData[objF.attr.name + "_epoch"]
                  );
                }
              }
              if (objF.attr && objF.attr.name) {
                self.options.scRows[objF.attr.name] = tr;
              }

              objF.scControl = objC;
              td.append(objC, objCH, objCL);
            }
            tr.append(td);
            if (objF.help) {
              tr.append(
                $("<td/>")
                  .addClass("form-help")
                  .append(
                    $(
                      '<a class="hover ei-help" href="javascript:{}" style="vertical-align: top;"/>'
                    )
                      .attr("schelp", objF.help)
                      .mouseover(function () {
                        hover.show($(this).attr("schelp"), this, true);
                      })
                  )
              );
            }
            else {
              tr.append($("<td/>").addClass("form-help empty"));
            }
          }
          t.append(tr);
        }

        //post process form
        $.each(self.options.scControls, function (key, value) {
          FilterControls(value);
          HideControls(value);
        });

        this.options.Form.append(t);
        $(this.options.scParent).append(this.options.Form);

        if (self.options.scButtons) {
          $.each(self.options.scButtons, function (name, props) {
            var button = $('<button type="button"></button>')
              .css({ float: "right" })
              .text(name)
              .click(function () {
                props(self.values());
              })
              .appendTo(self.options.uiButtonSet);
            if ($.fn.button) {
              button.button();
            }
          });
          $(this.options.scParent).append(this.options.uiButtonSet);
        }
      },

      values: function () {
        var a = this.options.Form.serializeAnything();
        $.each(this.options.scForm, function (key, value) {
          if (value.control === "tree") {
            var A = $(value.scTree).jstree("get_checked", null, true);
            var B = $(value.scTree).jstree("get_selected");
            var checked_ids = [];
            A.each(function () {
              checked_ids.push(this.id);
            });
            a[value.name] = checked_ids.toString();
            if (B && B[0]) a[value.name + "_S"] = B[0].id;
          }
          if (value.render === "date" && value.scControl && value.attr) {
            a[value.attr.name + "_epoch"] = value.scControl.attr("scDate");
          }
        });
        return a;
      },

      reDraw: function () {
      },

      refresh: function () {
      },

      _destroy: function () {
      },
    });

    (function ($) {
      $.fn.serializeAnything = function () {
        var toReturn = {};
        var els = $(this).find(":input").get();
        $.each(els, function () {
          if (!this.disabled && this.name && this.type === "checkbox")
            toReturn[this.name] = this.checked ? "checked" : "";
          else if (
            this.name &&
            !this.disabled &&
            (this.checked ||
              /select|textarea/i.test(this.nodeName) ||
              /text|hidden|password/i.test(this.type))
          ) {
            if ($(this).val()) toReturn[this.name] = $(this).val().toString();
            else toReturn[this.name] = "";
          }
          else toReturn[this.name] = "";
        });
        return toReturn;
      };
    })(jQuery);
  });

  //
};
