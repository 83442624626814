export enum RouteNames {
  Home = 'home',
  Dashboard = 'dashboard',
  ApplicationGroup = 'application_group',
  ApplicationGroupAdd = 'application_group_add',
  ApplicationGroupEdit = 'application_group_edit',
  ApplicationGroupDelete = 'application_group_delete',
  CyberApplicationGroup = 'cyber_application_group',
  Overview = 'overview',
  Search = 'search',
  Refreshing = 'refreshing',
  Loading = 'loading',
  Settings = 'settings',
  Tasks = 'tasks',
  AddPortlets = 'dashboard_add_portlets',
  DashboardPublish = 'dashboard_publish',
  DashboardUnpublish = 'dashboard_unpublish',
  DashboardEmail = 'dashboard_email',
  DashboardDelete = 'dashboard_delete',
  DashboardFilter = 'dashboard_filter',
  DashboardReport = 'dashboardReport',
  DashboardUnsubscribe = 'dashboard_unsubscribe',
  DashboardSubscribe = 'dashboard_subscribe',
  DashboardAddPortlets = 'dashboard_add_portlets',
  DashboardAdd = 'dashboard_add',
  PortletConfig = 'portlet_config',
  PortletDelete = 'portlet_delete',
  PortletResource = 'portlet_resource',
  ChartImage = 'chart_image',
  PortletReport = 'portletReport',
  PrintForm = 'printForm',
  TemplateMigrations = 'template-migrations',
  GlobalSnippets = 'snippets',
  IntegrationHub = 'integrationHub',
  Error = 'error',
  ErrorPage = 'not-found',
  LoginSuccess = 'loginSuccess',
  OneClickResponse = 'responseOneClick',
  EmailAddressVerification = 'emailAddressVerification',
  NoticeManagement = 'notice-management',
}

export enum RouteIdents {
  Recents = 'recents',
  Favourites = 'favourites',
  Favorites = 'favorites',
  Dashboards = 'dashboards',
  Applications = 'applications',
  Tasks = 'tasks',
  Assets = 'assets',
  VulnerabilityManagement = 'vulnerabilityManagement',
  VulnerabilityAssessments = 'vulnerabilityAssessments',
  Events = 'events',
}

export enum RouteParams {
  TabContent = 'tabcontent',
  ResourceId = 'resourceId',
  ActionIdx = 'actionIdx',
  CyberApplicationGroupId = 'cyberApplicationGroupId',
}

export enum RouteTypes {
  ApplicationGroups = 'application_groups',
  ApplicationGroup = 'application_group',
  Dashboards = 'dashboards',
}
