import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Auth0Profile, AuthMethod, AuthProfile, AuthSession } from '@surecloud/api-interfaces';
import { TokenService, UserService } from '@surecloud/core-data';
import { isIE, parseReturnUrlQuery } from '@surecloud/utils';
import { ROUTE_NAMES } from '../../constants';
import { Auth0Helper } from '../../helpers/auth0.helper';
import { LoginReturnUrlHelper } from '../../helpers/login-return-url.helper';
import { LOGIN_ERR_MSG } from './login.constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  error = '';
  formData: any;
  hideEmail = false;
  hidePass = true;
  isIE?: boolean;
  userIdIsValid = false;

  constructor(
    private renderer: Renderer2,
    private tokenService: TokenService,
    private userService: UserService,
    private router: Router,
    private loginReturnUrlHelper: LoginReturnUrlHelper,
    private auth0Helper: Auth0Helper,
  ) {
  }

  ngOnInit() {
    this.isIE = isIE();
    this.initForm();
    this.setUserIdIsValid();
  }

  changedEmail() {
    this.setUserIdIsValid();
  }

  setUserIdIsValid() {
    this.userIdIsValid = this.formData.email.length > 0;
  }

  initForm() {
    const rememberMe = localStorage.getItem('remember_username_v2');
    let remember = false;

    if (rememberMe === '1') {
      remember = true;
    }

    let email = localStorage.getItem('remembered_username_v2');
    if (email && email.length) {
    }
    else {
      email = '';
    }

    this.formData = {};
    this.formData.email = email;
    this.formData.password = '';
    this.formData.remember = remember;
  }

  reEnterUsername() {
    this.hidePass = true;
    this.error = '';

    let email = localStorage.getItem('remembered_username_v2');
    if (email && email.length) {
    }
    else {
      email = '';
    }

    this.formData.email = email;

    setTimeout(
      (() => {
        this.renderer.selectRootElement('#sure-email').focus();
      }).bind(this),
      100
    );
  }

  processForm() {
    if (this.formData.remember === true) {
      localStorage.setItem('remember_username_v2', '1');
      localStorage.setItem('remembered_username_v2', this.formData.email);
    }
    else {
      localStorage.removeItem('remember_username_v2');
      localStorage.removeItem('remembered_username_v2');
    }

    const credentials = JSON.stringify(this.formData);

    if (this.hidePass == false) {
      this.surecloudLogin(credentials);
    }
    else {
      this.userService.getAuthProfile(this.formData.email).subscribe(
        (authProfile: AuthProfile | any) => {
          if (authProfile && authProfile.errorMessage) {
            this.error = LOGIN_ERR_MSG;
            return;
          }
          if (['null', null].indexOf(authProfile.auth0State) > -1) {
            throw new Error('auth0State is null. Exiting as Login won\'t work in this state.');
          }
          if (authProfile.authenticationMethod === AuthMethod.Auth0 && authProfile.auth0Connector) {
            const loginHint = authProfile.email !== undefined
              ? authProfile.email
              : this.formData.email;
            const returnUrlQuery: string = parseReturnUrlQuery(window.location.href, window.location.origin);
            this.auth0Helper.login(loginHint, authProfile as Auth0Profile, returnUrlQuery);
          }
          else {
            this.hidePass = false;
            setTimeout(
              (() => {
                this.renderer.selectRootElement('#sure-pass').focus();
              }).bind(this),
              100
            );
          }
        }
      );
    }
    return false;
  }

  surecloudLogin(credentials: string) {
    this.tokenService.login(credentials).subscribe(
      (authSession: AuthSession | any) => {
        if (authSession && authSession.errorMessage) {
          this.error = LOGIN_ERR_MSG;
          return;
        }
        const { token } = authSession;
        if (authSession && token) {
          if (authSession.expired === true) {
            this.router.navigate([ROUTE_NAMES.reset, token]);
            return;
          }
          this.loginReturnUrlHelper.navigateToLoginSuccess(token);
        }
      }
    );
  }

  loginAs(email: string, pass: string) {
    this.formData.email = email;
    this.formData.password = pass;

    const credentials = JSON.stringify(this.formData);
    this.surecloudLogin(credentials);
  }
}
