// @ts-nocheck

export function auroraStageMapping() {
}

auroraStageMapping.loadStageMappingsPopup = function (id, name, entityType) {
  let currentStageMappings;
  let $popDiv = $('#load_stage_mappings_popup');
  if ($popDiv.length === 0) {
    $popDiv = $('<div/>')
      .attr({ id: 'load_stage_mappings_popup' })
      .css({ width: '100%' });
    $(document.body).append($popDiv);
  }
  else {
    $popDiv.empty();
  }

  const stagesGrid = $('<div/>')
    .addClass('ag-theme-balham sc-ag-theme')
    .attr('id', 'stagesGrid')
    .css({ width: '100%', 'min-height': 250, height: 'calc(100% - 3px)' });

  let stagesGridApi;

  const form = $('<table/>')
    .css({ width: '100%' });

  $popDiv.append(stagesGrid)
    .append(form);


  const reloadStagesGrid = function (id) {
    const url = entityType === 'userMapping'
      ? 'restful/v1/auroraMigration/' + entityType + '/stages'
      : 'restful/v1/auroraMigration/' + entityType + '/stages/' + id;

    $.ajax(url, {
      error: function (result) {
        $.ajaxSettings.error(result);
        if (result.status != 200) {
          oCompliance.alert(
            'Sorry, an error has occurred.', 'An Error occurred while loading the stage mappings for this entity mapping.');
        }
      },
      success: function (data) {
        currentStageMappings = data;
        loadStagesGrid(currentStageMappings);
      },
    });
  };

  const getStagesGridData = function (data) {
    const gridData = {
      floatingFilter: false,
      columns: [
        {
          headerName: 'Stage',
          field: 'stageName',
          flex: 1,
          filter: false,
          floatingFilter: false,
          cellRenderer: function (params) {
            const span = $('<span/>');
            span.append(
              $('<a/>')
                .addClass('ei-edit modifyStageMapping')
                .attr({
                  title: 'Modify Stage Mapping',
                  stageName: params.data.stageName,
                  stageId: params.data.stageId,
                }));

            if (params.data.order > 0) {
              span.append(
                $('<a/>')
                  .addClass('ei-move-up moveStageMappingUp')
                  .attr({
                    title: 'Move Up',
                    stageId: params.data.stageId,
                  }));
            }

            if (params.data.order < (data.length - 1)) {
              span.append(
                $('<a/>')
                  .addClass('ei-move-down moveStageMappingDown')
                  .attr({
                    title: 'Move Down',
                    stageId: params.data.stageId,
                  }));
            }

            span.append($('<text/>').text(params.data.stageName).css({ 'margin-left': '0.5em' }));
            return span.html();
          },
        },
        {
          headerName: 'Jexl',
          field: 'jexlMapping',
          flex: 1,
          filter: false,
          floatingFilter: false,
        },
      ],
      rows: [],
    };

    if (data) {
      data.forEach(function (stage, index) {
        gridData.rows.push({
          stageId: stage.stageId,
          stageName: stage.stage,
          jexlMapping: stage.jexlMapping,
          order: index,
        })
      });
    }
    return gridData;
  };

  const initStagesGrid = function () {
    $('.modifyStageMapping').unbind('click');
    $('.modifyStageMapping').click(function (e) {
      auroraStageMapping.modifyStageMappingPopup(
        id,
        name,
        entityType,
        $(e.target).attr('stageId'),
        $(e.target).attr('stageName'),
        function () {
          reloadStagesGrid(id);
        });
    });
    $('.moveStageMappingUp').unbind('click');
    $('.moveStageMappingUp').click(function (e) {
      moveStageMapping(id, $(e.target).attr('stageId'), 'UP');
    });
    $('.moveStageMappingDown').unbind('click');
    $('.moveStageMappingDown').click(function (e) {
      moveStageMapping(id, $(e.target).attr('stageId'), 'DOWN');
    });
  };

  const getStagesGridOptions = function () {
    const agOptions = {
      overlayNoRowsTemplate: '<span>Add a Source Mapping to this entity to populate the stages available from aurora</span>',
      columnTypes: {
        myDefColumn: {
          suppressAutoSize: true,
          suppressMovable: true,
          lockPinned: true,
          sortable: false,
          suppressMenu: true,
        },
      },
      onGridReady: function (params) {
        setTimeout(function () {
          params.api.sizeColumnsToFit();
        }, 100);
      },
      onModelUpdated: function () {
        setTimeout(function () {
          initStagesGrid();
        }, 100);
      },
      onBodyScroll: function () {
        setTimeout(function () {
          initStagesGrid();
        }, 100);
      },
    };
    return agOptions;
  };

  const moveStageMapping = function (id, stageId, directionToMove) {
    jQuery.ajax({
      url: 'restful/v1/auroraMigration/' + entityType + '/stage/move',
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        entityId: id,
        stageId: stageId,
        directionToMove: directionToMove,
      }),
      success: function () {
        reloadStagesGrid(id);
      },
      error: function (error) {
        oCompliance.alert('Test Jexl', 'ERROR moving stage mapping ' + directionToMove + ' :\n\n' + error);
      },
    });
  }

  const loadStagesGrid = function (data) {
    if (stagesGridApi) stagesGridApi.destroy();

    stagesGridApi = agGridController.grid(
      '#stagesGrid',
      getStagesGridData(data),
      false,
      getStagesGridOptions()
    );
  };


  $popDiv.dialog({
    title: 'Stage Mappings',
    autoOpen: true,
    closeOnEscape: true,
    width: 1000,
    height: 500,
    modal: true,
    buttons: [
      {
        text: 'Close',
        click: function () {
          $(this).dialog('close');
        },
      },
    ],
    open: function () {
      reloadStagesGrid(id);
    },
    close: function () {
      $popDiv.remove();
    },
  });
};


auroraStageMapping.modifyStageMappingPopup = function (id, name, entityType, stageId, stageName, onSubmit) {
  let $popDiv = $('#modify_stage_mapping_popup');
  if ($popDiv.length === 0) {
    $popDiv = $('<div/>')
      .attr({ id: 'modify_stage_mapping_popup' })
      .css({ width: '100%' });
    $(document.body).append($popDiv);
  }
  else {
    $popDiv.empty();
  }


  let jexlEditor;
  const stageNameInput = $('<input/>')
    .css({ width: '100%' })
    .enable(false);
  const stageIdInput = $('<input/>')
    .css({ width: '100%' })
    .enable(false);
  let jexlDiv = $('<div/>')
    .attr('id', 'jexlEditor')
    .css({ width: '100%' })
    .addClass('code wrap scresizable ui-resizable ace_editor ace-chrome');

  const jexlTestBtn =
    $('<button/>')
      .text('Test')
      .addClass('btn')
      .css({ 'float': 'right' })
      .click(function () {
        if (jexlEditor.getValue()) {
          const jextTestData = {};
          propertiesTestGridApi.forEachNode(
            function (node) {
              if (node &&
                node.data &&
                node.data.responseValue) {
                jextTestData[node.data.responseName] = node.data.responseValue;
              }
            });

          jQuery.ajax({
            url: 'restful/v1/auroraMigration/' + entityType + '/jexlTest',
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({
              id: id,
              jexl: jexlEditor.getValue(),
              responseValues: jextTestData,
            }),
            success: function (response) {
              oCompliance.alert('Test Jexl', response.errorMsg ? ('JEXL Error:\n\n' + response.errorMsg) : response.result);
            },
            error: function (error) {
              oCompliance.alert('Test Jexl', 'ERROR:\n\n' + error);
            },
          });
        }
      });

  let propertiesTestGridApi;

  const propertiesTestGrid = $('<div/>')
    .addClass('ag-theme-balham sc-ag-theme')
    .attr('id', 'propertiesTestGrid')
    .css({ width: '100%', 'min-height': 250, height: 'calc(100% - 350px)' });


  const formRow = function (label, control) {
    return $('<tr/>').append(
      $('<td/>').css({ width: '5%', 'padding-right': 20 }).addClass('popupLabel').text(label),
      $('<td/>').addClass('popupControl').append(control));
  };

  // form
  var form =
    $('<table/>').css({ width: '100%' })
      .append(
        formRow('Stage ID:', stageIdInput),
        formRow('Stage Name:', stageNameInput),
        formRow('Jexl:', jexlDiv),
        formRow('', jexlTestBtn),
        $('<tr/>').append(
          $('<td/>').attr({ colspan: 2 }).addClass('popupLabel').text('Properties')));

  $popDiv.append(form)
    .append(propertiesTestGrid);

  const submitStageMapping = function (onSuccess) {
    jQuery.ajax({
      url: 'restful/v1/auroraMigration/' + entityType + '/stage',
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        entityId: id,
        entityName: name ? name : '',
        stageId: stageId,
        stageName: stageName,
        jexlMapping: jexlEditor.getValue(),
      }),
      success: function () {
        onSuccess();
        onSubmit();
      },
      error: function (error) {
        $.ajaxSettings.error(error);
        console.log('ERROR: saving stage mapping: ' + error);
      },
    });
  };


  $popDiv.dialog({
    title: 'Modify Stage Mapping',
    autoOpen: true,
    closeOnEscape: true,
    width: 1000,
    height: 700,
    modal: true,
    buttons: [
      {
        text: 'Submit',
        click: function () {
          submitStageMapping(function () {
            $popDiv.dialog('close');
          });
        },
      },
      {
        text: 'Cancel',
        click: function () {
          $(this).dialog('close');
        },
      },
      {
        text: 'Delete',
        style: 'float:left; background-color: #b9333d; color:white;',
        disabled: true,
        click: function () {
          popup.confirm(
            'Are you sure you want to remove this stage mapping? The stage itself will NOT be removed.',
            'Remove Stage Mapping',
            function () {
              const url = entityType === 'userMapping'
                ? 'restful/v1/auroraMigration/' + entityType + '/stage/' + stageId
                : 'restful/v1/auroraMigration/' + entityType + '/stage/' + id + '/' + stageId;

              $.ajax(url, {
                type: 'DELETE',
              }).always(function (result) {
                $popDiv.dialog('close');
                if (result.status >= 400) {
                  oCompliance.alert(
                    'Sorry, an error has occurred.',
                    'An Error occurred while removing this stage mapping.'
                  );
                }
                else {
                  if (onSubmit) onSubmit();
                  menu.refreshData();
                }
              });
            }
          );
        },
      },
    ],
    open: function () {
      jexlEditor = ace.edit('jexlEditor');
      jexlEditor.setTheme('ace/theme/chrome');
      jexlEditor.setShowPrintMargin(false);
      jexlEditor.setOptions({
        tabSize: 2,
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
      });
      $('#jexlEditor').resizable({
        handles: 'se',
        stop: function () {
          jexlEditor.resize();
        },
      });

      const url = entityType === 'userMapping'
        ? 'restful/v1/auroraMigration/' + entityType + '/stage/' + stageId
        : 'restful/v1/auroraMigration/' + entityType + '/stage/' + id + '/' + stageId;
      $.ajax(url, {
        error: function (result) {
          $.ajaxSettings.error(result);
          if (result.status != 200) {
            oCompliance.alert(
              'Sorry, an error has occurred.',
              'An Error occurred while loading this stage mapping.');
          }
        },
        success: function (data) {
          if (data) {
            stageIdInput.val(data.stageId);
            stageNameInput.val(data.stageName);
            if (data.jexlMapping) {
              jexlEditor.setValue(data.jexlMapping);
            }
            $(':button:contains("Delete")').prop('disabled', false);
          }
        },
      });

      // load source responses for entity
      const propertiesUrl = entityType === 'userMapping'
        ? 'restful/v1/auroraMigration/' + entityType + '/property'
        : 'restful/v1/auroraMigration/' + entityType + '/source/' + id;

      $.ajax(propertiesUrl, {
        error: function (result) {
          $.ajaxSettings.error(result);
          if (result.status != 200) {
            oCompliance.alert(
              'Sorry, an error has occurred.',
              'An Error occurred while loading the source responses for this entity.');
          }
        },
        success: function (data) {

          createPropertiesTestGrid(entityType === 'entityMapping' ? data.responses : data);
        },
      });
    },
    close: function () {
      $popDiv.remove();
    },
  });

  const getPropertiesTestGridData = function (properties) {
    const gridData = {
      columns: [
        {
          headerName: 'Name',
          field: 'responseName',
          flex: 1,
        },
        {
          headerName: 'Test Value',
          field: 'responseValue',
          editable: true,
          flex: 1,
        },
      ],
      rows: [],
    };

    if (properties) {
      properties.forEach(function (property) {
        gridData.rows.push({
          responseName: entityType === 'userMapping' ? property : property.name,
          responseValue: '',
        });
      });
    }
    return gridData;
  };

  const getPropertiesTestGridOptions = function () {
    const agGridOptions = {
      overlayNoRowsTemplate: '<span>No Source Responses Mappings Configured</span>',
      columnTypes: {
        myDefColumn: {
          suppressAutoSize: true,
          suppressMovable: true,
          lockPinned: true,
        },
      },
      onGridReady: function (params) {
        setTimeout(function () {
          params.api.sizeColumnsToFit();
        }, 100);
      },
    };
    return agGridOptions;
  };

  const createPropertiesTestGrid = function (properties) {
    propertiesTestGridApi = agGridController.grid(
      '#propertiesTestGrid',
      getPropertiesTestGridData(properties),
      false,
      getPropertiesTestGridOptions()
    );
  };
};
