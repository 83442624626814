import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { CreateErrorPayload } from "@surecloud/api-interfaces";
import { ErrorsService } from "@surecloud/core-data";
import * as StackTrace from "stacktrace-js";
import { ErrorModalFacade } from "./+state/error-modal.facade";
import { MSG_ADMIN_NOTIFIED, MSG_CONTINUE } from "./error-constants";
import { makeHtmlMessage } from "./http/create-message-from-error";

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
    private injector: Injector
  ) {
  }

  handleError(error: any) {
    if (error.url) {
      return;
    }
    const errorsService = this.injector.get(ErrorsService);
    const errorModalFacade = this.injector.get(ErrorModalFacade);
    StackTrace.fromError(error)
      .then((trace) => {
        const prettyPrintedTrace = trace.map((frame: StackTrace.StackFrame, index: number) => {
          return `
          / Frame ${index + 1} /------------------
            fileName: ${frame.fileName},
            functionName: ${frame.functionName},
            lineNumber: ${frame.lineNumber},
            column: ${frame.columnNumber}.
          -------------------------------------
          `;
        })
        const errorHeadline = `
        ------------------------
          GlobalErrorHandler -> ${error.message}
        ------------------------
        `;
        const fullMessage = [errorHeadline, ...prettyPrintedTrace.join('')].join('');
        console.log(fullMessage);
        const userAgent = navigator && navigator.userAgent ? navigator.userAgent : 'Unknown User Agent';
        const errorPayload: CreateErrorPayload = {
          message: error.message,
          apiURL: '<runtime JS code error>',
          error: fullMessage,
          userAgent
        };
        errorsService.create(errorPayload);
        const htmlMessage = makeHtmlMessage(MSG_ADMIN_NOTIFIED, MSG_CONTINUE);
        errorModalFacade.dispatch(htmlMessage);
      });
  }
}
