import { findBase } from "@surecloud/utils";
import { environment } from "../environments/environment";

// application is deployed to a directory
// in some environments.
let base = '';
if (environment.production) {
  base = findBase(location.href, 'login');
}
export const CORE_APP_URL = `${base}${environment.coreAppUrl}`;
export const LOGIN_APP_URL = `${base}${environment.loginAppUrl}`;

export const ROUTE_NAMES = {
  home: 'home',
  authError: 'authError',
  reset: 'reset',
  reveal: 'reveal',
  logout: 'logout',
  idpLink: 'idpLink',
  notSupported: 'notSupported',
  privacy: 'privacy',
  cookies: 'cookies'
};

export const LOGIN_HOME = `${LOGIN_APP_URL}/${ROUTE_NAMES.home}`;

export const ROUTE_DETAILS = {
  reset: {
    params: {
      resetGuid: 'resetGuid'
    }
  },
  reveal: {
    params: {
      revealToken: 'revealToken'
    }
  }
}