import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Auth0Profile,
  AuthProfile, EmailAddressVerificationResponse,
  PasswordResetDetail,
  PasswordResetPayload,
  ResetPasswordResponse,
  RevealDetail,
  RevealState,
  User,
} from '@surecloud/api-interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  USER_ENDPOINT,
  USER_ENDPOINT_AUTH_PROFILE,
  USER_ENDPOINT_IDP_AUTH_PROFILE,
  USER_ENDPOINT_RESET_DETAILS,
  USER_ENDPOINT_RESET_FROM_TOKEN,
  USER_ENDPOINT_RESET_PASSWORD,
  USER_ENDPOINT_REVEAL_DETAIL,
  USER_ENDPOINT_VERIFY_EMAIL_ADDRESS,
} from '../../url-constants';
import { getApiUrl } from '../../utils/url-helpers';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpClient
  ) {
  }

  queryUsers(query: string): Observable<User[]> {
    const url = getApiUrl(`${USER_ENDPOINT}?${query}`);
    return this.http.get<User[]>(url);
  }

  getUsersBySearch(search = ''): Observable<User[]> {
    return this.queryUsers(`search=${search}`);
  }

  getAuthProfile(userId: string): Observable<AuthProfile> {
    const url = getApiUrl(`${USER_ENDPOINT}/${userId}/${USER_ENDPOINT_AUTH_PROFILE}`);
    return this.http.get<AuthProfile>(url);
  }

  getIDPAuthProfile(personIdp: string): Observable<Auth0Profile> {
    const url = getApiUrl(`${USER_ENDPOINT}/${personIdp}/${USER_ENDPOINT_IDP_AUTH_PROFILE}`);
    return this.http.get<Auth0Profile>(url);
  }

  getResetDetail(token: string): Observable<PasswordResetDetail> {
    const url = getApiUrl(`${USER_ENDPOINT_RESET_DETAILS}?resetToken=${token}`);
    return this.http.get<PasswordResetDetail>(url);
  }

  getResetDetailMock(token: string): Observable<PasswordResetDetail> {
    return this.http.get<PasswordResetDetail>('assets/mockApi/mockPasswordReset.json')
      .pipe(map((res: any) => {
        return res[token];
      }));
  }

  resetPassword(userEmail: string): Observable<ResetPasswordResponse> {
    const url = getApiUrl(`${USER_ENDPOINT_RESET_PASSWORD}`);
    return this.http.post<ResetPasswordResponse>(url, { userEmail }, { withCredentials: true });
  }

  resetPasswordFromToken(resetPayload: PasswordResetPayload): Observable<any> {
    const url = getApiUrl(`${USER_ENDPOINT_RESET_FROM_TOKEN}`);
    return this.http.post<any>(url, resetPayload);
  }

  getRevealMock(guid: string): Observable<RevealDetail> {
    return this.http.get<RevealDetail>('assets/mockApi/mockReveal.json')
      .pipe(map((res: any) => {
        return res[guid];
      }));
  }

  getRevealDetail(guid: string): Observable<RevealDetail> {
    const url = getApiUrl(`${USER_ENDPOINT_REVEAL_DETAIL}/${guid}`);
    return this.http.get<RevealDetail>(url)
      .pipe(
        map((detail: RevealDetail) => {
          if (!detail) {
            return {
              state: RevealState.Unknown,
              key: '',
            };
          }
          return detail;
        })
      );
  }

  verifyEmailAddress(resourceId: string): Observable<EmailAddressVerificationResponse> {
    const url = getApiUrl(`${USER_ENDPOINT_VERIFY_EMAIL_ADDRESS}/${resourceId}`);

    return this.http.get<EmailAddressVerificationResponse>(url);
  }
}
