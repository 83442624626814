export class ScStorage {

  static getItem<T>(key: string): T | undefined {
    const value = localStorage.getItem(key) || undefined;
    if (!value) return undefined;
    const parsedValue: T = JSON.parse(value);
    return parsedValue;
  }

  static setItem(key: string, value: any): void {
    const jsonValue = JSON.stringify(value);
    localStorage.setItem(key, jsonValue);
  }

  static removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}
