import { TileIdentType } from '@surecloud/api-interfaces';
import { RouteIdents, RouteNames, UITile } from '@surecloud/ui-interfaces';

/*
* This method is duplicated in the backend code in HomePageService.java to facilitate setting home page via formulas
* All code changes need to be synchronised with the back end code
*/
export const makeOldWorldResourceId = (currentRoute: string, tile: Partial<UITile>): string => {
  if (!tile) return '';
  const { title, identifier } = tile;
  const { tileType } = identifier;
  const entity = tileType === TileIdentType.Standard ? 'INITIATIVE' : 'FORM';

  const drillData = {
    crumb: '',
    entity: entity,
    description: title,
    filter: {
      questionnaireKey: tile.questionnaireKey,
      standardKey: tile.standardKey || tile.uiStandardKey,
    },
  };
  const queryParams = new URLSearchParams({
    tabcontent: '1',
    drillData: base64Encode(drillData),
  });

  return `${currentRoute}?${queryParams.toString()}`;
};

export const base64Encode = (drillData: any): string => {
  return window.btoa(unescape(encodeURIComponent(JSON.stringify(drillData).replace(/[\u0250-\ue007]/g, '?'))));
};

/*
* This method is duplicated in the backend code in HomePageService.java to facilitate setting home page via formulas
* All code changes need to be synchronised with the back end code
*/
export const makeDefaultHomeResourceId = (tile: Partial<UITile>): string => {
  const { tileType, id } = tile.identifier;
  switch (tileType) {
    case TileIdentType.Dashboard: {
      return makeFullAngularRoute(`${RouteNames.Dashboard}/${id}`);
    }
    case TileIdentType.ApplicationGroup: {
      return makeFullAngularRoute(`${RouteNames.ApplicationGroup}/${id}`);
    }
    case TileIdentType.Assets:
    case TileIdentType.Events:
    case TileIdentType.PciScans:
    case TileIdentType.Vulnerability: {
      return `${id}`;
    }
    default: {
      // form query string requires a base-route to match before it is parsed.
      const rootPath = makeFullAngularRoute(`${RouteNames.Overview}/${RouteIdents.Favourites}`);
      return makeOldWorldResourceId(rootPath, tile);
    }
  }
};

export const makeFullAngularRoute = (route: string): string => {
  return `/home/(show:${route})`;
};
