import { Component, OnInit } from '@angular/core';
import { TokenService } from '@surecloud/core-data';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {

  constructor(
    private tokenService: TokenService
  ) {
  }

  ngOnInit(): void {
    this.tokenService.logout();
  }
}
