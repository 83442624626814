import { isEmpty } from '../general';

export function getUrlPathSection(url: string): string {
  const queryIndex = url.split("").indexOf("?");
  const hasQuery = queryIndex > -1;
  let path = hasQuery ? url.substring(0, queryIndex) : url;
  return path;
}

export function getPrimaryUrlPath(urlPath: string): string {
  const paths = urlPath.split("/");
  const primaryPath = paths.find((p) => !isEmpty(p)) || "";
  return primaryPath;
}

export function getPrimaryPathName(url: string): string {
  const pathSection = getUrlPathSection(url);
  return getPrimaryUrlPath(pathSection);
}

export function hasPath(path: string, routePaths: string[]): boolean {
  const actualPath = routePaths.reduce((prev: string, curr: string) => {
    return `${prev}${prev.length > 0 ? '/' : ''}${curr}`;
  }, '');
  if (actualPath === path) { return true; }
  if (routePaths.every(p => path.split('/').indexOf(p) > -1)) {
    return true;
  }
  return false;
}

export function stripQueryString(url: string): string {
  const split = url.split('?');
  return split.length > 1 ? url.split('?')[0] : url;
}

export function findBase(href: string, endpath: string): string {
  const hrefNoQuery = stripQueryString(href);
  const split = hrefNoQuery.split('/');
  const base = split.length > 2 && split[3] !== endpath ? split[3] : undefined;
  return base !== undefined ? `/${base}` : '';
}

export function makeBaseHref(endpath: string): string {
  var port = location.port;
  var urlSplit = location.href.split("/");
  if (urlSplit.length < 3) {
    console.warn("Unexpected URL found.");
    return '/';
  }
  var base = urlSplit[3];
  var endPart = "/" + endpath + "/";
  var href = '';
  if (base === endpath) {
    href = endPart;
  }
  else {
    href = "/" + base + endPart;
  }
  if (["9000", "9001"].indexOf(port) > -1) {
    href = "/";
  }
  return href;
}

export const urlContainsPaths = (href: string, partialRoutes: string[]): boolean => {
  const url = new URL(href);
  let regExp;
  const found = partialRoutes.find(partialRoute => {
    regExp = new RegExp(`${partialRoute}(?=(\/|$))`, 'g');
    return url.pathname.search(regExp) > -1;
  });
  return found ? true : false;
};

export const removeFirstSlash = (text: string): string => {
  return text ? text.replace('/', '') : text;
};
