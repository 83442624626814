import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconButtonComponent } from './icon-button.component';
import { TextButtonComponent } from './text-button.component';
import { MenuButtonComponent } from './menu-button/menu-button.component';

@NgModule({
  declarations: [
    IconButtonComponent,
    TextButtonComponent,
    MenuButtonComponent,
  ],
  exports: [
    IconButtonComponent,
    TextButtonComponent,
    MenuButtonComponent,
  ],
  imports: [
    CommonModule,
  ],
})
export class ButtonModule {}
