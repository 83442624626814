// @ts-nocheck
// [Coupled to Server]
// most of these functions seem to be only called by server code.
import { oDate } from '../modules/oDate';
import { oDhtml } from '../modules/oDhtml';
import { uploadFileWithForm } from '../modules/uploadFile';
import { getElementById } from './oUniversal';
import { fetchRequest } from '../data/fetch-request';
import { API, USER_ENDPOINT } from '@surecloud/core-data';
import { getLocalToken } from '@surecloud/utils';

const GREEN = '#0C8B65';
const RED = '#c60000';
const GREY = '#f8f9fa';
const BLACK = '#3f4043';
const BTN_BG_COLOR = GREY;
const BTN_BG_COLOR_SUCCESS = GREEN;
const BTN_BG_COLOR_ERROR = RED;
const BTN_TEXT_COLOR = BLACK;

export const entityLoad = () => {
  //-----------------------------------------------------------------------------Message Thread
  function oMessageThread() {
  }

  oMessageThread.validate = function (control, event) {
    if (!oValidate.validate(control, event)) return;
    var disabled = !oValidate.isSet("message") || !oValidate.isSet("subject");
    get("buttonOK").disabled = disabled;
    tabs.getTab["Support"].getMenu[
      "Support Tickets"
      ].breadCrumbs[0].reload = true;
  };

  //-----------------------------------------------------------------------------Change Password
  function oSettings() {
  }

  oSettings.validate = function (control, event) {
    if (!oValidate.validate(control, event)) return;
    get("cpOK").disabled =
      !oValidate.isSet("oldPassword") ||
      !oValidate.isSet("newPassword1") ||
      !oValidate.isSet("newPassword2");
  };

  oSettings.submit = function () {
    if (getV("newPassword1") != getV("newPassword2")) {
      oCompliance.alert(
        "SureCloud:",
        "The confirmation of your new password doesn't match your original version, please try again."
      );
      return false;
    }
    if (oSettings.checkPassword(getV("newPassword1"))) {
      ajax.request(
        {
          popup: { entity: "Person", part: "CHANGE_PASSWORD" },
          set: {
            oldPassword: getV("oldPassword"),
            newPassword: getV("newPassword1"),
            csrf: getLocalToken(),
          },
        },
        function (ajax) {
          popup.response(ajax);
        }
      );
    }
  };

  oSettings.checkPassword = function (password) {
    if (password.length < 10) {
      oCompliance.alert(
        "SureCloud:",
        "Please ensure your new password is at least 10 characters long."
      );
      return false;
    }
    var checked = oValidate.isPassword(password);
    if (checked !== "") {
      oCompliance.alert("SureCloud:", checked);
      return false;
    }
    return true;
  };

  //-----------------------------------------------------------------------------Alerts
  function oAlertPolicy() {
  }

  oAlertPolicy.validate = function (control, event) {
    if (!oValidate.validate(control, event)) return;
    var disabled = !oValidate.isSet("name");
    get("buttonOK").disabled = disabled;
    var standardAlert =
      $v("type") == "Vulnerability" ||
      $v("type") == "IP Address" ||
      $v("type") == "Event";
    oDhtml.setDisplay("trAlertAssetGroups", standardAlert);
    if (get("trAlertEventSubgroups"))
      oDhtml.setDisplay("trAlertEventSubgroups", $v("type") == "Event");
    if (get("trAlertEmailDetails"))
      oDhtml.setDisplay("trAlertEmailDetails", $v("type") == "Event");
    if (get("trAlertEventDefinitions"))
      oDhtml.setDisplay("trAlertEventDefinitions", $v("type") == "Event");
    oDhtml.setDisplay("trFrequency", standardAlert || $v("type") == "Wireless");
    oDhtml.setDisplay("trBRCondition", $v("type") == "Vulnerability");

    oDhtml.setDisplay(
      "tdBRTarget",
      $v("type") == "Vulnerability" &&
      $v("targetType") == "Business Risk" &&
      $v("vOperator") != ""
    );
    oDhtml.setDisplay(
      "tdBRTargetEnd",
      $v("type") == "Vulnerability" &&
      $v("targetType") == "Business Risk" &&
      $v("vOperator") == "Between"
    );
    oDhtml.setDisplay(
      "tdCVSSTarget",
      $v("type") == "Vulnerability" &&
      ($v("targetType") == "CVSS Base Score" ||
        $v("targetType") == "CVSS v3 Base Score") &&
      $v("vOperator") != ""
    );
    oDhtml.setDisplay(
      "tdCVSSTargetEnd",
      $v("type") == "Vulnerability" &&
      ($v("targetType") == "CVSS Base Score" ||
        $v("targetType") == "CVSS v3 Base Score") &&
      $v("vOperator") == "Between"
    );
    oDhtml.setDisplay(
      "tdSVTarget",
      $v("type") == "Vulnerability" &&
      $v("targetType") == "Severity" &&
      $v("vOperator") != ""
    );
    oDhtml.setDisplay(
      "tdSVTargetEnd",
      $v("type") == "Vulnerability" &&
      $v("targetType") == "Severity" &&
      $v("vOperator") == "Between"
    );
    oDhtml.setDisplay(
      "trSCondition",
      $v("type") == "Event" || $v("type") == "Wireless"
    );
    oDhtml.setDisplay(
      "tdSTarget",
      ($v("type") == "Event" || $v("type") == "Wireless") &&
      $v("sOperator") != ""
    );
    oDhtml.setDisplay(
      "tdSTargetEnd",
      ($v("type") == "Event" || $v("type") == "Wireless") &&
      $v("sOperator") == "Between"
    );

    // If multi select asset groups has no selections, then force to 'All' option, if multiple options are selected, then ensure 'All' is not selected.
    if ($("#assetGroupKeys :selected").length != 1) {
      $('#assetGroupKeys option[value=""]').prop(
        "selected",
        $("#assetGroupKeys :selected").length == 0 ? true : false
      );
    }
  };

  //-----------------------------------------------------------------------------Property Bag
  function oPropertyBag() {
  }

  oPropertyBag.validate = function (control, event) {
    if (!oValidate.validate(control, event)) return;
    get("buttonOK").disabled = !(
      oValidate.isSet("property_bag") &&
      oValidate.isSet("property_key") &&
      oValidate.isSet("property_value")
    );
  };

  //-----------------------------------------------------------------------------Actions
  function oAction() {
  }

  oAction.validate = function (control, event) {
    if (!oValidate.validate(control, event)) return;
    if (!get("actionType")) {
      get("buttonOK").disabled = false;
      return;
    }
    get("newActionType").style.display =
      get("actionType").value == "" ? "" : "none";
    get("buttonOK").disabled = !oValidate.isSet("action");
  };

  oAction.validateAssign = function (control, event) {
    if (!oValidate.validate(control, event)) return;
    get("buttonOK").disabled = false;
  };

  //-----------------------------------------------------------------------------Multiple Select
  function oMultipleSelect() {
  }

  oMultipleSelect.search = function (search, select) {
    var found = false;
    for (var i = 0; i < select.options.length; i++) {
      //get('unassigned')
      select.options[i].selected = false;
      if (
        !found &&
        search != "" &&
        select.options[i].text
          .toLowerCase()
          .search(new RegExp(search.toLowerCase())) != -1
      ) {
        select.options[i].selected = true;
        found = true;
      }
    }
  };

  oMultipleSelect.move = function (source, destination, input) {
    var selectedCount = 0,
      selectedValues = new Array(),
      selectedText = new Array();
    // Find the selected Options in reverse order and delete them from the 'from' Select.
    for (var i = source.length - 1; i >= 0; i--) {
      if (source.options[i].selected) {
        selectedText[selectedCount] = source.options[i].text;
        selectedValues[selectedCount] = source.options[i].value;
        if (source.id == "assigned")
          input.value = input.value.replace(
            new RegExp("," + escapeRegExp(source.options[i].value) + ",", "g"),
            ","
          );
        if (source.length > 0) source.options[i] = null;
        selectedCount++;
      }
    }
    // Add the selected text/values in reverse order.This will add the Options to the 'to' Select in the same order as they were in the 'from' Select.
    for (var i = selectedCount - 1; i >= 0; i--) {
      if (destination.id == "assigned") input.value += selectedValues[i] + ",";
      destination.options[destination.length] = new Option(
        selectedText[i],
        selectedValues[i]
      );
    }
  };

  function escapeRegExp(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  }

  //-----------------------------------------------------------------------------Appliance
  function oAppliance() {
  }

  oAppliance.validate = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    if (get("active") && get("trReassignTo"))
      oDhtml.setDisplay("trReassignTo", !get("active").checked);
    get("buttonOK").disabled =
      !oValidate.isSet("name") ||
      (get("hostname") != null &&
        (!oValidate.isSet("hostname") ||
          !oValidate.isIPAddress($v("ipAddress")) ||
          !oValidate.isIPAddress($v("clientIpAddress"))));
  };

  oAppliance.validateSwitch = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    get("buttonOK").disabled = !oValidate.isIPAddress($v("switch"));
  };

  //-----------------------------------------------------------------------------Organisation
  function oOrganisation() {
  }

  oOrganisation.validate = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    if (get("trPciIPAddressLimit"))
      get("trPciIPAddressLimit").style.display = get("pci_compliance").checked
        ? ""
        : "none";
    if (get("trPciHostnameLimit"))
      get("trPciHostnameLimit").style.display = get(
        "trPciIPAddressLimit"
      ).style.display;
    if (get("trPciWebsiteLimit"))
      get("trPciWebsiteLimit").style.display = get(
        "trPciIPAddressLimit"
      ).style.display;
    if (get("trPciAdminPersonKey"))
      get("trPciAdminPersonKey").style.display = get(
        "trPciIPAddressLimit"
      ).style.display;
    if (get("trSupportEmail") && get("role"))
      get("trSupportEmail").style.display =
        $v("role") == "Organisation" ? "none" : "";
    if (get("role"))
      get("trUserChildPermissions").style.display =
        $v("role") == "Organisation" ? "none" : "";
    if (get("trAllowDeletion"))
      get("trAllowDeletion").style.display =
        get("active").checked ? "none" : "";
    get("buttonOK").disabled =
      !oValidate.isSet("name") ||
      (get("pciIPAddressLimit") &&
        !oValidate.isInteger(getV("pciIPAddressLimit"))) ||
      (get("pciWebsiteLimit") &&
        !oValidate.isInteger(getV("pciWebsiteLimit"))) ||
      (get("pci_compliance") &&
        get("pci_compliance").checked &&
        getV("pciIPAddressLimit") == "0" &&
        getV("pciWebsiteLimit") == "0") ||
      (get("trSupportEmail") &&
        get("trSupportEmail").style.display != "none" &&
        !oValidate.isEmail($v("supportEmail"))) ||
      (get("trSupportEmailDisplay") &&
        get("trSupportEmailDisplay").style.display != "none" &&
        !oValidate.isSet("supportEmailDisplay"));
  };

  oOrganisation.validateLogo = function (control: HTMLInputElement, event: MouseEvent) {
    if (control && !oValidate.validate(control, event)) return;
    const uploadValue = get("upload").value;
    const fileNameLength = uploadValue.length;

    get("buttonOK").disabled = !(fileNameLength > 4 && uploadValue.substring(fileNameLength - 4) === '.png');
  };

  oOrganisation.validateBranding = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    var hostnameRegex = /^[a-zA-Z0-9\-]*$/;
    get("buttonOK").disabled = !hostnameRegex.test(getV("url"));
  };

  //NOTE: oOrganisation.updateBranding = function() is in surecloud.chart.js so that it can use a closure to set branding colours into the colour picker.

  oOrganisation.validateCopy = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    get("buttonOK").disabled = !oValidate.isSet("copyName");
  };

  oOrganisation.upload = function () {
    get("buttonOK").disabled = true;
    get("buttonCancel").disabled = true;
    return true;
  };

  oOrganisation.uploaded = function (result) {
    popup.hide(true);
    get("upload_target").src = "dummy.jsp";
  };

  //-----------------------------------------------------------------------------Person
  function oPerson() {
  }

  oPerson.validate = function (control, event, passwordRequired) {
    if (control && !oValidate.validate(control, event)) return;
    if (getV("role") != "Contributor") {
      get("personType").value = "Internal";
      get("personType").disabled = true;
    }
    else {
      get("personType").disabled = false;
      if (control.id == "role") get("personType").value = "External";
    }
    if (get("trComplianceTemplates"))
      get("trComplianceTemplates").style.display =
        getV("role") == "Manager" || getV("role") == "Administrator"
          ? ""
          : "none";
    get("buttonOK").disabled =
      !oValidate.isSet("name") || !oValidate.isEmail(getV("email"));
    oDhtml.setDisplay(
      "trIdentityDomain",
      get("identityProvider")
        ? getV("identityProvider") === "Organisation"
        : false
    );
  };

  oPerson.validateSecretRestrictions = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    get("buttonOK").disabled = false;
  };

  oPerson.originalButton = function (id, text) {
    const button = document.getElementById(id);

    if (button) {
      button.value = text;
      button.disabled = false;
      button.style.setProperty('color', BTN_TEXT_COLOR, 'important');
      button.style.setProperty('background-color', BTN_BG_COLOR, 'important');
    }
  };

  oPerson.successButton = function (id, text) {
    const button = document.getElementById(id);

    if (button) {
      button.value = text;
      button.disabled = true;
      button.style.setProperty('color', 'white', 'important');
      button.style.setProperty('background-color', BTN_BG_COLOR_SUCCESS, 'important');
    }
  };

  oPerson.errorButton = function (id, text) {
    const button = document.getElementById(id);

    if (button) {
      button.value = text;
      button.disabled = true;
      button.style.setProperty('color', 'white', 'important');
      button.style.setProperty('background-color', BTN_BG_COLOR_ERROR, 'important');
    }
  };

  oPerson.resendActivation = function (userGuid) {
    fetchRequest({
      url: `${API}/${USER_ENDPOINT}/${userGuid}/resendActivation`,
      method: 'POST',
    }).then(() => {
      oPerson.successButton('resendActivationBtn', 'Activation Sent');

      setTimeout(function () {
        oPerson.originalButton('resendActivationBtn', 'Resend Activation');
      }, 3000);
    }).catch(() => {
      oPerson.errorButton('resendActivationBtn', 'Failed To Resend');

      setTimeout(function () {
        oPerson.originalButton('resendActivationBtn', 'Resend Activation');
      }, 3000);
    });
  };

  oPerson.resetSsoSettings = function (userGuid) {
    fetchRequest({
      url: `${API}/${USER_ENDPOINT}/${userGuid}/resetSso`,
      method: 'POST',
    }).then(() => {
      oPerson.successButton('resetSsoSettingsBtn', 'Settings Were Reset');

      setTimeout(function () {
        oPerson.originalButton('resetSsoSettingsBtn', 'Reset SSO Settings');
      }, 3000);
    }).catch(() => {
      oPerson.errorButton('resetSsoSettingsBtn', 'Failed To Reset');

      setTimeout(function () {
        oPerson.originalButton('resetSsoSettingsBtn', 'Reset SSO Settings');
      }, 3000);
    });
  };

  oPerson.cancelEmailVerification = function (userGuid) {
    const cancelEmailVerificationBtn = document.getElementById('cancelEmailVerificationBtn');
    cancelEmailVerificationBtn.disabled = true;

    const resendEmailVerificationBtn = document.getElementById('resendEmailVerificationBtn');
    resendEmailVerificationBtn.disabled = true;

    fetchRequest({
      url: `${API}/${USER_ENDPOINT}/${userGuid}/cancelEmailAddressVerification`,
      method: 'POST',
    }).then(() => {
      oPerson.successButton('cancelEmailVerificationBtn', 'Verification Canceled');
    }).catch(() => {
      oPerson.errorButton('cancelEmailVerificationBtn', 'Failed To Cancel');
    });
  };

  oPerson.resendEmailVerification = function (userGuid) {
    fetchRequest({
      url: `${API}/${USER_ENDPOINT}/${userGuid}/sendEmailAddressVerificationEmail`,
      method: 'POST',
    }).then(() => {
      oPerson.successButton('resendEmailVerificationBtn', 'Verification Sent');

      setTimeout(function () {
        oPerson.originalButton('resendEmailVerificationBtn', 'Resend Verification');
      }, 3000);
    }).catch(() => {
      oPerson.errorButton('resendEmailVerificationBtn', 'Failed To Send');

      setTimeout(function () {
        oPerson.originalButton('resendEmailVerificationBtn', 'Resend Verification');
      }, 3000);
    });
  };

  oPerson.resetPassword = function (userGuid) {
    fetchRequest({
      url: `${API}/${USER_ENDPOINT}/${userGuid}/resetPassword`,
      method: 'POST',
    }).then(() => {
      oPerson.successButton('resetPasswordBtn', 'Reset Link Sent');

      setTimeout(function () {
        oPerson.originalButton('resetPasswordBtn', 'Reset Password');
      }, 3000);
    }).catch(() => {
      oPerson.errorButton('resetPasswordBtn', 'Failed To Send');

      setTimeout(function () {
        oPerson.originalButton('resetPasswordBtn', 'Reset Password');
      }, 3000);
    });
  };

  //-----------------------------------------------------------------------------Person
  function oPersonOrganisation() {
  }

  oPersonOrganisation.validate = function (control, event) {
    get("buttonOK").disabled =
      !oValidate.isSet("supportingInfo") || get("justification").value == "";
  };

  //-----------------------------------------------------------------------------Event
  function oEvent() {
  }

  oEvent.validateFilter = function (control, event, passwordRequired) {
    if (control && !oValidate.validate(control, event)) return;
    oDhtml.setDisplay("trFrom", $v("period") == "-1");
    oDhtml.setDisplay("trTo", $v("period") == "-1");
  };

  oEvent.userFilter = function () {
    oEvent.userFilterDisable(true);
    tabs.tab.menu.breadCrumb.select(undefined, {
      part: "USER_FILTER",
      userFilter: $v("userFilter"),
      key: -1,
    });
  };

  oEvent.setUserFilter = function (ajax) {
    get("divUserSelect").innerHTML = ajax.html;
    oEvent.userFilterDisable(false);
  };

  oEvent.userFilterDisable = function (disabled) {
    get("user_").disabled = disabled;
    get("userFilter").disabled = disabled;
    get("userFilterButton").disabled = disabled;
    get("buttonOK").disabled = disabled;
  };

  //-----------------------------------------------------------------------------Asset Groups
  function oAssetGroup() {
  }

  oAssetGroup.validate = function (control, event) {
    if (!oValidate.validate(control, event)) return;
    if (get("newPrefix"))
      get("newPrefix").style.display = get("prefix").value == "" ? "" : "none";
    var disabled =
      !oValidate.isSet("name") ||
      (get("prefix") &&
        get("prefix").value == "" &&
        get("newPrefix").value == "") ||
      !oValidate.isSet("applicationUrl");
    get("buttonOK").disabled = disabled;
  };

  oAssetGroup.validateBaseUrl = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    get("buttonOK").disabled = !oValidate.isBaseUrl($v("http") + $v("baseUrl"));
  };

  oAssetGroup.validateUrl = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    get("buttonOK").disabled = !oValidate.isUrlSuffix(getV("url"));
  };

  //-----------------------------------------------------------------------------IP Range
  function oIPRange() {
  }

  oIPRange.validate = function (control, event) {
    if (get("ranges")) get("buttonOK").disabled = !oValidate.isSet("ranges");
    else
      get("buttonOK").disabled =
        !(
          oValidate.isCidr(getV("range")) ||
          oValidate.isIPAddress(getV("range"))
        ) || !oValidate.isSet("name");
  };

  //-----------------------------------------------------------------------------IP Address
  function oIPAddress() {
  }

  oIPAddress.validate = function (control, event) {
    if (!oValidate.validate(control, event)) return;
    var disabled =
      get("ipAddress") && !oValidate.isIPAddress(getV("ipAddress"));
    //Hostname can be blank for IPs, otherwise must be valid
    if (
      !disabled &&
      !(
        get("isHostnames") &&
        "false" == getV("isHostnames") &&
        getV("hostname") == ""
      )
    ) {
      if (get("hostname")) disabled = !oValidate.isHostname(getV("hostname"));
    }
    //Hostname can be blank for IPs
    if (!disabled && get("hostnames")) {
      var lines = getV("hostnames").split("\n");
      if (lines.length == 0) disabled = true;
      for (var i = 0; i < lines.length; i++) {
        if (!oValidate.isHostname(lines[i])) {
          disabled = true;
          break;
        }
      }
    }
    get("buttonOK").disabled = disabled;
  };

  oIPAddress.searchValidate = function (control, event) {
    if (!oValidate.validate(control, event)) return;
    //	get('buttonOK').disabled=getV('searchServicePort')!='' && !oValidate.isInteger(getV('searchServicePort'));
    get("buttonOK").disabled = false;
  };

  oIPAddress.search = function (search) {
    var getValue = function (search, id) {
      return search ? (getV(id) == "" ? undefined : getV(id)) : undefined;
    };
    tabs.breadCrumb.arguments.offset = 0;
    tabs.breadCrumb.select({
      searchIP: getValue(search, "searchIP"),
      searchHostname: getValue(search, "searchHostname"),
      searchServiceProtocol: getValue(search, "searchServiceProtocol"),
      searchServicePort: getValue(search, "searchServicePort"),
      searchServiceName: getValue(search, "searchServiceName"),
      searchIPLocation: getValue(search, "searchIPLocation"),
    });
    popup.hide(true);
  };

  oIPAddress.validateFilter = function (control, event) {
    var disabled = false;
    if (get("maxRisk")) {
      var invalidChars = $v("maxRisk")
        .trim()
        .match(new RegExp(/[\d]|[>]|[<]|[=]/gi));
      var invalidRange = $v("maxRisk")
        .trim()
        .match(new RegExp(/((^>=)+(\d+)+($))|((^<=)+(\d+)+($))/gi));
      var invalidDigit = $v("maxRisk").trim().match(new RegExp(/[\d+]/gi));
      if (
        !invalidChars ||
        invalidChars.length != $v("maxRisk").trim().length ||
        (!invalidRange &&
          (!invalidDigit || invalidDigit.length != $v("maxRisk").trim().length))
      ) {
        disabled = true;
      }
    }
    get("buttonOK").disabled = disabled;
  };

  //-----------------------------------------------------------------------------Service
  function oService() {
  }

  oService.validate = function (control, event) {
    if (!oValidate.validate(control, event)) return;
    get("buttonOK").disabled =
      !oValidate.isSet("protocol") ||
      getV("port").search(new RegExp(/^[0-9]+$/)) == -1 ||
      !oValidate.isSet("name");
  };

  //Not implemented yet. You would also need to unbaseline all services if you unbaselined an IP
  oService.baselineIpFirst = function (control) {
    oCompliance.alert(
      "SureCloud:",
      "Sorry, you must baseline the IP Address that this service belongs to, before you can baseline this service"
    );
  };

  oService.validateFilter = function (control, event) {
    var disabled = false;
    if (get("port") && $v("port") !== "") {
      var tokens = $v("port").split(",");
      if (tokens && tokens.length > 0) {
        for (var i = 0; i < tokens.length; i++) {
          var invalidChars = tokens[i]
            .trim()
            .match(new RegExp(/[\d]|[-]|[>]|[<]|[=]/gi));
          var invalidRange = tokens[i]
            .trim()
            .match(
              new RegExp(
                /((^>=)+(\d+)+($))|((^<=)+(\d+)+($))|((\d+)+(-)+(\d+)+($))/gi
              )
            );
          var invalidDigit = tokens[i].trim().match(new RegExp(/[\d+]/gi));
          if (
            !invalidChars ||
            invalidChars.length != tokens[i].trim().length ||
            (!invalidRange &&
              (!invalidDigit || invalidDigit.length != tokens[i].trim().length))
          ) {
            disabled = true;
            break;
          }
        }
      }
      else disabled = false;
    }
    get("buttonOK").disabled = disabled;
  };

  //-----------------------------------------------------------------------------Service
  function oServices() {
  }

  oServices.validate = function (control, event) {
    if (event.keyCode != 13 && !oValidate.validate(control, event)) return;
    if (control.type == "text") get(control.id + "Button").disabled = false;
    if (control.type == "text" || control.type == "select-one") {
      tabs.breadCrumb.arguments[control.id.replace(/services_/g, "")] =
        control.value == "" ? undefined : control.value;
    }
    if (
      control.type == "button" ||
      control.type == "submit" ||
      control.type == "select-one" ||
      (event.keyCode == 13 && control.type == "text")
    ) {
      tabs.breadCrumb.select({ offset: 0 });
    }
  };

  //-----------------------------------------------------------------------------Project
  function oProject() {
  }

  oProject.validate = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    var isTemplate =
        get("templateProjectKey") && $v("templateProjectKey") != "",
      templateTargetType =
        !isTemplate || !get("templateTargetType")
          ? undefined
          : $v("templateTargetType");
    var isCheck = get("check") && get("check").checked;
    if (get("check")) {
      oDhtml.setDisplay("trCheckReference", isCheck);
      oDhtml.setDisplay("trCheckSensitivityInput", !isCheck);
      oDhtml.setDisplay("trCheckSensitivitySelect", isCheck);
    }
    get("buttonOK").disabled =
      !oValidate.isSet("name") ||
      /* DT-3535 to be relaxed until we understand the new restrictions applied by NCSC
            (isCheck &&
              getV("code") != "" &&
            getV("code").search(/^CHECK([0-9]){4,5}$/) != 0) ||
       */
      (get("projectFlex") && !oValidate.isInteger(getV("projectFlex"))) ||
      (templateTargetType == "IP Address or Range" &&
        !(
          oValidate.isIPAddress($v("templateTarget")) ||
          oValidate.isCidr($v("templateTarget"), true)
        ));
    oDhtml.setDisplay("trTemplateTargetType", isTemplate);
    oDhtml.setDisplay(
      "trTemplateAssetGroupKey",
      isTemplate && templateTargetType == "Asset Group"
    );
    oDhtml.setDisplay(
      "trTemplateTarget",
      isTemplate && templateTargetType == "IP Address or Range"
    );
    if (get("scanName")) {
      $("#name").val($v("scanName"));
    }
  };

  oProject.validateScanUpload = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    if (oValidate.isSet("name")) $("#projectName").val($v("name"));
    else $("#projectName").val("");
    get("buttonOK").disabled =
      !oValidate.isSet("upload") || !oValidate.isSet("name");
  };

  oProject.validateAssessmentUpload = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    if (oValidate.isSet("name")) $("#projectName").val($v("name"));
    else $("#projectName").val("");
    get("buttonOK").disabled =
      !oValidate.isSet("upload") || !oValidate.isSet("name");
  };

  //-----------------------------------------------------------------------------Task
  function oTask() {
  }

  oTask.validate = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    get("buttonOK").disabled =
      !oValidate.isSet("name") ||
      (get("estimated") &&
        getV("estimated") != "" &&
        !oValidate.isInteger(getV("estimated"))) ||
      (get("actual") &&
        getV("actual") != "" &&
        !oValidate.isInteger(getV("actual")));
  };

  oTask.setAllIfEmpty = function (task) {
    oTask.setIfEmpty("taskName", task, "name");
    oTask.setIfEmpty("taskDescription", task, "description");
    oTask.setIfEmpty("taskEstimated", task, "estimated");
    oTask.setIfEmpty("taskActual", task, "actual");
    oTask.validate();
  };

  oTask.setIfEmpty = function (control, task, property) {
    if (task != "" && get(control))
      get(control).value = task[property] ? task[property] : "";
  };

  oTask.search = function (search) {
    var getValue = function (search, id) {
      return search ? (getV(id) == "" ? undefined : getV(id)) : undefined;
    };
    tabs.breadCrumb.select({ searchName: getValue(search, "searchName") });
    popup.hide(true);
  };

  //-----------------------------------------------------------------------------Verify
  function oVerify() {
  }

  oVerify.validate = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    if (get("status")) {
      var cc = $v("status") == "Compensating Controls",
        cd = $v("status") == "Control Dependent";
      get("trControl").style.display = cd ? "" : "none";
      get("trVerifyStatement").style.display = cc ? "none" : "";
      get("trVerifyConstraints").style.display = cc ? "" : "none";
      get("trVerifyObjective").style.display = cc ? "" : "none";
      get("trVerifyRisk").style.display = cc ? "" : "none";
      get("trVerifyValidation").style.display = cc ? "" : "none";
      get("trVerifyMaintenance").style.display = cc ? "" : "none";
    }
    get("buttonOK").disabled = !oValidate.isSet("name");
  };

  //-----------------------------------------------------------------------------Job
  function oJob() {
  }

  oJob.onToolChange = function (control, event) {
    if (get("tool")) {
      var current = get("tool").options[get("tool").selectedIndex];
      var previous = get("tool").previousSelectedJob;
      var idx = current.text.indexOf(" (");
      if (idx === -1) idx = current.text.length;
      get("tool").previousSelectedJob = current.text.substr(0, idx);
      if (
        get("name") &&
        (get("name").value === undefined ||
          get("name").value === "" ||
          get("name").value === previous)
      ) {
        get("name").value = get("tool").previousSelectedJob;
      }
    }
  };

  oJob.validate = function (control, event, tools) {
    if (control && !oValidate.validate(control, event)) return;
    if (control.id == "tool" && get("targets"))
      get("targets").options.length = 0;
    if (tools && get("tool")) {
      get("trJobToolPolicy").style.display = tools[getV("tool")].policy
        ? ""
        : "none";
      get("trJobToolSetting").style.display = tools[getV("tool")].setting
        ? ""
        : "none";
      for (var i = 0; i < get("tool").options.length; i++) {
        var toolKey = get("tool").options[i].value;
        if (get("toolPolicy_" + toolKey))
          get("toolPolicy_" + toolKey).style.display =
            getV("tool") == toolKey ? "" : "none";
        if (get("toolSetting_" + toolKey))
          get("toolSetting_" + toolKey).style.display =
            getV("tool") == toolKey ? "" : "none";
      }
      oJob.setApplicationTarget(tools[getV("tool")].targets);
    }
    //If dependency, either both or none need to be set
    //var dependencyDisabled=get('dependentJobSelect') && ((getV('dependentJobSelect')=='' && getV('vulnerabilityId')!='') ||
    //	(getV('dependentJobSelect')!='' && getV('vulnerabilityId')==''));
    get("buttonOK").disabled =
      !oValidate.isSet("name") ||
      (get("targets") && get("targets").length == 0);
    //(get('assetGroupSelect') &&  getV('assetGroupSelect')=='') ||
    // || dependencyDisabled;
    if (get("buttonAddIpRange"))
      get("buttonAddIpRange").disabled = !oValidate.isCidr(
        getV("ipRangeTargetInput"),
        true
      );
    if (get("buttonAddIpAddress"))
      get("buttonAddIpAddress").disabled = !oValidate.isIPAddress(
        getV("ipAddressTarget")
      );
    if (get("buttonAddIpAddressSsl"))
      get("buttonAddIpAddressSsl").disabled =
        !oValidate.isIPAddress(getV("ipAddressSslTarget")) ||
        !oValidate.isInteger(getV("ipAddressSslTargetPort"));
  };

  oJob.addTarget = function (control, button, event) {
    var option;
    if (control.type == "select-one") {
      option = new Option(
        control.options[control.selectedIndex].text,
        control.id + control.value
      );
    }
    else {
      button.disabled = true;
      if (control.id == "ipAddressSslTarget") {
        var display =
          getV("ipAddressSslTargetSsl") +
          control.value +
          ":" +
          getV("ipAddressSslTargetPort");
        option = new Option(display, "ipAddressSslTarget" + display);
      }
      else {
        option = new Option(control.value, control.value);
      }
    }
    for (var i = 0; i < get("targets").options.length; i++) {
      if (
        get("targets").options[i].value == option.value ||
        get("targets").options[i].text == option.value
      ) {
        oCompliance.alert(
          "SureCloud:",
          "That item is already in the list, please try again"
        );
        return;
      }
    }
    get("targets").options[get("targets").options.length] = option;
    oJob.setTargets();
    oJob.validate(button, event);
  };

  oJob.removeTarget = function (button, event) {
    get("targets").remove(get("targets").selectedIndex);
    get("buttonRemoveTarget").disabled = true;
    oJob.setTargets();
    oJob.validate(button, event);
  };

  oJob.setTargets = function () {
    var targets = "";
    for (var i = 0; i < get("targets").options.length; i++)
      targets += get("targets").options[i].value + "|";
    get("targetList").value = targets;
    return true;
  };

  oJob.setApplicationTarget = function (targets) {
    if (!get("divASSET_GROUP")) return;
    get("divASSET_GROUP").style.display =
      targets.indexOf("|ASSET_GROUP|") == -1 ? "none" : "block";
    get("divAPPLICATION").style.display =
      targets.indexOf("|APPLICATION|") == -1 ? "none" : "block";
    get("divIP_RANGE").style.display =
      targets.indexOf("|IP_RANGE|") == -1 ? "none" : "block";
    get("divIP_ADDRESS").style.display =
      targets.indexOf("|IP_ADDRESS|") == -1 ? "none" : "block";
    get("divIP_ADDRESS_SSL").style.display =
      targets.indexOf("|IP_ADDRESS_SSL|") == -1 ? "none" : "block";
  };

  //This is also used by Project to scan now
  oJob.scanNow = function (dateSelect, timeSelect, validateField) {
    var now = new Date();
    now.setMinutes(parseInt(now.getMinutes() / 15) * 15);
    now.setSeconds = 0;
    now.setMilliseconds(0);
    get(timeSelect).value = oDate.getMinutesOfDay(now);
    calendar.control = get(dateSelect);
    if (validateField)
      eval(
        "calendar.validate=function() {get('buttonOK').disabled=get('" +
        validateField +
        "').value=='';}"
      );
    else get("buttonOK").disabled = false;
    calendar.selectFunction(now);
  };

  //-----------------------------------------------------------------------------PCI
  function oPci() {
  }

  oPci.action = function (targetAction, targetType, target) {
    tabs.reloadNonIndependent();
    tabs.breadCrumb.select(undefined, undefined, {
      targetAction: targetAction,
      targetType: targetType,
      target: target,
    });
  };

  oPci.validate = function (control, event, targetType) {
    if (control && !oValidate.validate(control, event)) return;
    if (targetType == "IP_TARGETS")
      get("addTarget").disabled = !(
        oValidate.isCidr(getV("target"), undefined, true) ||
        oValidate.isExternalIPAddress(getV("target"))
      );
    else if (targetType == "HOSTNAME_TARGETS")
      get("addTarget").disabled = !oValidate.isHostname(getV("target"));
    else
      get("addTarget").disabled =
        !oValidate.isBaseUrl(getV("target")) ||
        oValidate.isWebsiteSelected("targets", $v("target"));
  };

  function oPciScans() {
  }

  oPciScans.validate = function (control, event, ip) {
    if (control && !oValidate.validate(control, event)) return;
    get("buttonOK").disabled = !oValidate.isSet("name");
  };

  //-----------------------------------------------------------------------------Tool Policy
  function oToolPolicy() {
  }

  oToolPolicy.validate = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    if (get("tool")) {
      var tool = get("tool").options[get("tool").selectedIndex].text,
        form = get("formPopup");
      get("divToolPolicyNessus1").style.display =
        tool == "SureGuard Vulnerability Scanner" ? "" : "none";
      if (get("divToolPolicyNessus2"))
        get("divToolPolicyNessus2").style.display =
          tool == "SureGuard Vulnerability Scanner" ? "" : "none";
    }
    var disabled = !oValidate.isSet("name");
    if (get("templateToolPolicyKey"))
      get("divToolPolicyNessus1").style.display =
        getV("templateToolPolicyKey") != "" ? "none" : "";
    get("buttonOK").disabled = disabled;
  };

  oToolPolicy.selectCategories = function (selected, set) {
    let form = $('form#formPopup')[0];
    for (var i = 0; i < form.elements.length; i++) {
      if (form.elements[i].type == "checkbox") {
        if (set) {
          var category = form.elements[i].id.substring(19);
          if (set == "High")
            form.elements[i].checked = !(
              category == "destructive_attack" || category == "kill_host"
            );
          else if (set == "Medium")
            form.elements[i].checked = !(
              category == "destructive_attack" ||
              category == "flood" ||
              category == "denial" ||
              category == "kill_host"
            );
          else if (set == "Low")
            form.elements[i].checked = !(
              category == "destructive_attack" ||
              category == "flood" ||
              category == "denial" ||
              category == "kill_host" ||
              category == "attack"
            );
        }
        else form.elements[i].checked = selected;
      }
    }
    oToolPolicy.validate();
  };

  //-----------------------------------------------------------------------------Plugin Type
  function oPluginType() {
  }

  oPluginType.validate = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    //get('buttonOK').disabled=false;
  };

  oPluginType.search = function (pane, search) {
    var getValue = function (search, id) {
      return search ? (getV(id) == "" ? undefined : getV(id)) : undefined;
    };
    var filter = {
      searchId: getValue(search, "searchId"),
      searchName: getValue(search, "searchName"),
      searchDescription: getValue(search, "searchDescription"),
    };
    tabs.select(pane, { filter: filter });
  };

  //-----------------------------------------------------------------------------Tool Setting
  function oToolSetting() {
  }

  oToolSetting.validate = function (control, event, template) {
    if (control && !oValidate.validate(control, event)) return;
    if (get("templateToolSettingKey")) {
      var selectedToolKey = getV("toolKey");
      for (var i = 0; i < get("toolKey").options.length; i++) {
        var toolKey = get("toolKey").options[i].value;
        if (get("toolSettingSelect_" + toolKey))
          get("divToolSettingSelect_" + toolKey).style.display =
            selectedToolKey == toolKey ? "" : "none";
      }
      get("templateToolSettingKey").value = get(
        "toolSettingSelect_" + selectedToolKey
      )
        ? get("toolSettingSelect_" + selectedToolKey).value
        : "";
      get("toolKey").disabled = getV("templateToolSettingKey") != "";
      get("trToolSettingArguments").style.display =
        getV("templateToolSettingKey") == "" ? "" : "none";
    }
    get("buttonOK").disabled = !oValidate.isSet("name");
  };

  //-----------------------------------------------------------------------------Vulnerability List
  function oVulnerabilityList() {
  }

  oVulnerabilityList.validate = function (control, event) {
    //if (event.keyCode!=13 && !oValidate.validate(control,event)) return;
    if (control && !oValidate.validate(control, event)) return;
    if (
      get("oldCVSSBaseScore") &&
      get("oldSeverity") &&
      get("oldCVSSv3BaseScore") &&
      get("CVSSBaseScore") &&
      get("severity")
    ) {
      var showReason =
        getV("CVSSBaseScore") != getV("oldCVSSBaseScore") ||
        getV("severity") != getV("oldSeverity") ||
        getV("CVSSv3BaseScore") != getV("oldCVSSv3BaseScore");
      if (showReason) {
        get("reasonRow").setAttribute("style", "");
      }
      else {
        get("reasonRow").setAttribute("style", "display:none;");
      }
    }
    var disabled = false;
    if (!oValidate.isSet("name")) disabled = true;
    if (
      get("CVSSBaseScoreSelect") &&
      (getV("CVSSBaseScoreSelect") != "" ||
        getV("CVSSExploitSubscoreSelect") != "" ||
        getV("CVSSImpactSubscoreSelect") != "") &&
      (getV("CVSSBaseScoreSelect") == "" ||
        getV("CVSSExploitSubscoreSelect") == "" ||
        getV("CVSSImpactSubscoreSelect") == "")
    )
      disabled = true;
    disabled = showReason && getV("reason") == "";
    get("buttonOK").disabled = disabled;
  };

  oVulnerabilityList.validateFilters = function (control, event) {
    if (event.keyCode != 13 && !oValidate.validate(control, event)) return;
    if (control.type == "text") get(control.id + "Button").disabled = false;
    if (control.type == "text" || control.type == "select-one") {
      //tabs.breadCrumb.arguments[control.id.replace(/vulnerabilityList_/g,'')]=getVUndefined(control.id);
      tabs.breadCrumb.arguments[control.id.replace(/vulnerabilityList_/g, "")] =
        control.value == "" ? undefined : control.value;
    }
    if (
      control.type == "button" ||
      control.type == "submit" ||
      control.type == "select-one" ||
      (event.keyCode == 13 && control.type == "text")
    )
      tabs.breadCrumb.select({});
  };

  oVulnerabilityList.setArguments = function (args, newArguments) {
    args.calledFrom = newArguments.calledFrom;
    args.sourceFilter = newArguments.sourceFilter;
    args.idFilter = newArguments.idFilter;
    args.typeFilter = newArguments.typeFilter;
    args.categoryFilter = newArguments.categoryFilter;
    args.nameFilter = newArguments.nameFilter;
    args.descriptionFilter = newArguments.descriptionFilter;
    args.severityFilter = newArguments.severityFilter;
    args.offset = newArguments.offset;
  };

  oVulnerabilityList.find = function (find) {
    tabs.breadCrumb.popupHtml = get("divPopup").innerHTML;
    tabs.breadCrumb.popupLeft = get("divPopup").style.left;
    tabs.breadCrumb.popupTop = get("divPopup").style.top;
    popup.hide(true);
    tabs.tab.menu.addAndSelect("Vulnerability Reference", {
      entity: "Vulnerability List",
      view: "FIND",
      filter: { source: "SureCloud", display: { source: "SureCloud" } },
    });
  };

  oVulnerabilityList.add = function (id, find, severity) {
    var breadCrumb =
      tabs.tab.menu.breadCrumbs[tabs.tab.menu.breadCrumbs.length - 2];
    breadCrumb.reload = false;
    breadCrumb.select();
    if (!get("id") || !get("trAssetGroup")) {
      get("divPopup").style.left = breadCrumb.popupLeft;
      get("divPopup").style.top = breadCrumb.popupTop;
      popup.response({ popup: breadCrumb.popupHtml });
    }
    else popup.div.style.display = "block";
    if (!get("id")) return;
    get("id").value = id;
    oVulnerability.validateNew(get("id"));
  };

  oVulnerabilityList.log = function (x, restart) {
    if (oVulnerabilityList.logAllowed()) {
      if (!tabs.vlLog) tabs.vlLog = new Array();
      tabs.vlLog.push(x);
      if (tabs.vlLog.length > 100) tabs.vlLog.shift();
    }
  };

  oVulnerabilityList.logAllowed = function () {
    return (
      get("logonDetails").innerHTML.indexOf("Geoff&nbsp;(Administrator)") > 0 ||
      get("logonDetails").innerHTML.indexOf("Mark&nbsp;Lascelles") > 0 ||
      get("logonDetails").innerHTML.indexOf("Luke&nbsp;Potter") > 0
    );
  };

  oVulnerabilityList.logDisplay = function () {
    if (tabs.vlLog && oVulnerabilityList.logAllowed()) {
      for (var i = 0; i < tabs.vlLog.length; i++) {
        oSureCloud.debug(tabs.vlLog[i], i > 0);
      }
      tabs.vlLog.length = 0;
    }
  };

  oVulnerabilityList.generateCvssChangeReport = function (control, pane) {
    if (get("startDate") && get("endDate")) {
      var json = {
        entity: "Vulnerability List",
        part: "REPORT DAEMON",
        reportType: "Excel",
        report: "vulnerabilityDefinitionCvssChanges",
        startDate: getV("startDate"),
        endDate: getV("endDate"),
      };
      popup.show(control, pane, json, undefined, true);
    }
  };

  //-----------------------------------------------------------------------------Compliance Vulnerability List
  function oComplianceVulnerabilityList() {
  }

  oComplianceVulnerabilityList.validate = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    get("buttonOK").disabled = !oValidate.isSet("name");
  };

  oComplianceVulnerabilityList.find = function (find) {
    tabs.breadCrumb.popupHtml = get("divPopup").innerHTML;
    tabs.breadCrumb.popupLeft = get("divPopup").style.left;
    tabs.breadCrumb.popupTop = get("divPopup").style.top;
    popup.hide(true);
    tabs.tab.menu.addAndSelect("Compliance Vulnerability Reference", {
      entity: "Compliance Vulnerability List",
      view: "FIND",
      filter: {
        source: "Nessus_Configuration",
        display: { source: "Nessus_Configuration" },
      },
    });
  };

  oComplianceVulnerabilityList.add = function (id, find) {
    var breadCrumb =
      tabs.tab.menu.breadCrumbs[tabs.tab.menu.breadCrumbs.length - 2];
    breadCrumb.reload = false;
    breadCrumb.select();
    if (!get("id") || !get("trAssetGroup")) {
      get("divPopup").style.left = breadCrumb.popupLeft;
      get("divPopup").style.top = breadCrumb.popupTop;
      popup.response({ popup: breadCrumb.popupHtml });
    }
    else popup.div.style.display = "block";
    if (!get("id")) return;
    get("id").value = id;
    oVulnerability.validateNew(get("id"));
  };

  //-----------------------------------------------------------------------------Vulnerability
  function oVulnerability() {
  }

  oVulnerability.validate = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    var disabled = false;
    if (get("status")) {
      get("trStatusReason").style.display =
        $v("status") == $v("originalStatus") ? "none" : "";
      get("trDeferred").style.display =
        $v("status") == "Deferred" ? "" : "none";
      if (get("overrideSeverity")) {
        var changed = $v("overrideSeverity") != $v("originalOverrideSeverity");
        if (changed) {
          if (!oValidate.isSet("overrideReason")) disabled = true;
        }
        else get("overrideReason").value = "";
        get("trOverrideReason").style.display = changed ? "" : "none";
      }
    }
    get("buttonOK").disabled = disabled;
  };

  oVulnerability.validateNew = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    get("trAssetGroup").style.display =
      $v("scope") == "Asset Group" ? "" : "none";
    get("trIPAddress").style.display = $v("scope") == "Service" ? "" : "none";
    if (get("trService"))
      get("trService").style.display = $v("scope") == "Service" ? "" : "none";
    if (control.id == "ipAddressKey") {
      if (get("serviceKey")) get("serviceKey").length = 1;
      if ($v("ipAddressKey") != "") {
        get("ipAddress").value = "";
        get("ipAddressKey").disabled = true;
        if (get("tdServiceKey")) get("tdServiceKey").disabled = true;
        tabs.tab.menu.breadCrumb.subTab.select(undefined, {
          part: "SERVICES",
          ipAddressKey: $v("ipAddressKey"),
          key: -1,
        });
      }
    }
    else if (control.id == "ipAddress") {
      get("ipAddressKey").value = "";
      if (get("serviceKey")) get("serviceKey").length = 1;
    }
    else if (control.id == "serviceKey") get("service").value = "";
    else if (control.id == "service") {
      if (get("serviceKey")) get("serviceKey").value = "";
    }
    var disabled = false;
    if (!oValidate.isSet("id")) disabled = true;
    if ($v("scope") == "Service") {
      if ($v("ipAddressKey") == "" && !oValidate.isIPAddress($v("ipAddress")))
        disabled = true;
      if (
        get("serviceKey") &&
        $v("serviceKey") == "" &&
        !oValidate.isService($v("service"))
      )
        disabled = true;
    }
    if (!oValidate.isSet("information")) disabled = true;
    get("buttonOK").disabled = disabled;
    get("buttonOKPlus").disabled = disabled;
  };

  oVulnerability.pasteImage = function (e) {
    var paster = document.getElementById("paster");
    const noOfNewFiles = e.clipboardData.files.length;
    paster.count = paster.count === undefined ? noOfNewFiles : paster.count + noOfNewFiles;
    if (paster.count > 10) return;
    paster.value = `${paster.count} image${(paster.count === 1 ? "" : "s")}`;

    const extraFilesEl = getElementById<HTMLInputElement>('extraFiles');
    const newFiles: FileList = e.clipboardData.files;
    extraFilesEl.files = newFiles;
    get("buttonOK").disabled = false;
  };

  /* @ts-check */
  oVulnerability.uploadImages = function (
    pane: string,
    vulnerabilityHistoryKey: string,
    addAnotherFile: boolean,
    projectKey: number
  ) {
    if (addAnotherFile)
      popup.show(this, pane, {
        entity: "Vulnerability",
        projectKey: projectKey,
      });
    else oUpload.uploaded(pane, undefined);
  };
  /* @ts-check */

  oVulnerability.validateStatus = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    if (get("trDeferred"))
      get("trDeferred").style.display =
        $v("status") == "Deferred" ? "" : "none";
    if (get("trOverrideSeverity"))
      get("trOverrideSeverity").style.display =
        $v("status") == "Override Severity" ? "" : "none";
  };

  oVulnerability.setServices = function (ajax) {
    if (get("tdServiceKey")) get("tdServiceKey").innerHTML = ajax.html;
    get("ipAddressKey").disabled = false;
    if (get("tdServiceKey")) get("tdServiceKey").disabled = false;
  };

  oVulnerability.validateFPCC = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    get("buttonOK").disabled = !oValidate.isSet("reason");
  };

  oVulnerability.validateOverride = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    get("buttonOK").disabled =
      !oValidate.isSet("vulnerabilityReason") ||
      getV("originalVulnerabilityOverrideSeverity") ==
      getV("vulnerabilityOverrideSeveritySelect");
  };

  oVulnerability.validateUploadResultsFile = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    get("key").value = getV("jobKey");
    get("buttonOK").disabled = getV("upload") == "" || getV("jobKey") == "-1";
  };

  oVulnerability.setArguments = function (args, newArguments) {
    args.idFilter = newArguments.idFilter;
    args.SeverityFilter = newArguments.SeverityFilter;
    args.ImpactFilter = newArguments.ImpactFilter;
    args.RiskFilter = newArguments.RiskFilter;
    args.assetGroupFilter = newArguments.assetGroupFilter;
    args.projectFilter = newArguments.projectFilter;
    args.projectFilterName = newArguments.projectFilterName;
    args.ipAddressFilter = newArguments.ipAddressFilter;
    args.typeFilter = newArguments.typeFilter;
    args.StatusFilter = newArguments.StatusFilter;
    args.AcceptedFilter = newArguments.AcceptedFilter;
    args.orderBy = newArguments.orderBy;
    //args.orderByDirection=newArguments.orderByDirection;
    args.offset = newArguments.offset;
  };

  oVulnerability.setAccepted = function (vulnerabilityKey, accepted, img) {
    img.accepted = img.accepted == undefined ? accepted : !img.accepted;
    img.src = "images/icons/" + (img.accepted ? "tick" : "cross") + ".gif";
  };

  oVulnerability.setParent = function (parentVulnerabilityKey) {
    tabs.reloadNonIndependent();
    tabs.breadCrumb.menu.breadCrumbs[tabs.breadCrumb.index - 1].select(
      undefined,
      undefined,
      { parentVulnerabilityKey: parentVulnerabilityKey }
    );
  };

  oVulnerability.deleteParent = function () {
    tabs.breadCrumb.arguments["expandVulnerability Hierarchy"] = false;
    tabs.breadCrumb.arguments["expand_Vulnerability Hierarchy"] = false;
  };

  oVulnerability.validateIdFilter = function (control, event) {
    if (event.keyCode != 13 && !oValidate.validate(control, event)) return;
    if (event.keyCode == 13) get("idInputButton").click();
    else
      get("idInputButton").disabled =
        getV("idInput").search(new RegExp(/^([a-zA-Z0-9-]+)$/)) == -1;
  };

  oVulnerability.validateUrlParameter = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    var base = getSelectedOption("applicationUrlKey");
    base = base != undefined && base.startsWith("BASE: ");
    get("applicationUrl").style.visibility = base ? "" : "hidden";
    //A base URL is selected and the suffix is '' and the or Validates or (Another URL is selected and Parameter is not '')
    var disabled = true;
    if (base) {
      if (getV("applicationUrl") == "")
        disabled = getV("applicationParameter") == "";
      else disabled = !oValidate.isUrlSuffix(getV("applicationUrl"));
    }
    else if (oValidate.isSet("applicationParameter")) disabled = false;
    get("buttonOK").disabled = disabled;
  };

  oVulnerability.filterIPAddressSetFilterBy = function (filterBy) {
    var show = function (id) {
      if (get(id))
        get(id).parentNode.parentNode.style.display =
          id == filterBy ? "" : "none";
    };
    show("IPAddressKey");
    show("IPAddress");
    show("Hostname");
    show("HostnameWildcard");
  };

  oVulnerability.filterIPAddressSet = function (filter) {
    var filterBy = filter["filterBy"].value;
    return {
      IPAddress: {
        filterBy: filterBy,
        value: filter[filterBy].value,
        display: filter[filterBy].display,
      },
    };
  };

  oVulnerability.filterIpAddressValidate = function (control) {
    if (control.id == "ipAddressKey") get("ipAddressSearch").value = "";
    else if (get("ipAddressKey")) get("ipAddressKey").value = "";
  };

  oVulnerability.filterHostnameValidate = function (control) {
    if (control.id == "hostnameExact") get("hostnameSearch").value = "";
    else if (get("hostnameExact")) get("hostnameExact").value = "";
  };

  oVulnerability.filterSystemNameValidate = function (control) {
    if (control.id == "systemNameIpAddressKey")
      get("systemNameSearch").value = "";
    else if (get("systemNameIpAddressKey"))
      get("systemNameIpAddressKey").value = "";
  };

  oVulnerability.modifyActionAssignment = function (
    control,
    pane,
    csrf,
    actionKey,
    key
  ) {
    var newTask = "ASSIGN_NEW_ACTION_TO_VULNERABILITY";
    var action = get("actionKey")
      ? getV("actionKey") !== ""
        ? "ASSIGN_EXISTING_ACTION_TO_VULNERABILITY"
        : newTask
      : newTask;
    if (newTask === action) {
      var json = {
        entity: "Action",
        actionKey: actionKey,
        vulnerabilityKey: key,
        screen: action,
        action: action,
      };
      popup.show(control, pane, json, undefined, true);
    }
    else {
      oVulnerability.submitActionAssignment(action, csrf, actionKey, key);
    }
  };

  oVulnerability.submitActionAssignment = function (
    action,
    csrf,
    actionKey,
    key
  ) {
    var newActionKey = get("actionKey") ? getV("actionKey") : "";
    if ("ASSIGN_EXISTING_ACTION_TO_VULNERABILITY" === action) {
      var json = {
        popup: {
          entity: "Vulnerability",
          part: "MODIFY_ACTION_ASSIGNMENT",
          key: key,
          actionKey: actionKey,
        },
        set: {
          csrf: getLocalToken(),
          actionKey: newActionKey,
          action: action,
        },
      };
      ajax.request(json, function () {
        popup.hide();
        tabs.reloadNonIndependent();
        tabs.tab.menu.breadCrumb.select({});
      });
    }
  };

  //-----------------------------------------------------------------------------Wireless Drone
  function oWirelessDrone() {
  }

  oWirelessDrone.validate = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    get("buttonOK").disabled =
      !oValidate.isSet("drone") ||
      (get("macAddress") && !oValidate.isMACAddress(getV("macAddress")));
  };

  //-----------------------------------------------------------------------------Remediation Ticket
  function oRemediationTicket() {
  }

  oRemediationTicket.validate = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    if (get("remediationTicketKey")) {
      var display = $v("remediationTicketKey") == "" ? "" : "none";
      if (get("trName").style.display != display) {
        get("trName").style.display = display;
        get("trOwner").style.display = display;
        get("trPriority").style.display = display;
        get("trDescription").style.display = display;
      }
    }
    get("buttonOK").disabled =
      (!get("remediationTicketKey") || $v("remediationTicketKey") == "") &&
      !oValidate.isSet("name");
  };

  //-----------------------------------------------------------------------------Finding
  function oFinding() {
  }

  oFinding.validate = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    if (get("findingKey")) {
      var display = $v("findingKey") == "" ? "" : "none";
      if (get("trFinding").style.display != display)
        get("trFinding").style.display = display;
      if (get("trRemediationPriority").style.display != display)
        get("trRemediationPriority").style.display = display;
    }
    get("buttonOK").disabled =
      (!get("findingKey") || $v("findingKey") == "") &&
      !oValidate.isSet("finding");
  };

  //-----------------------------------------------------------------------------Finding
  function oProjectCommentary() {
  }

  oProjectCommentary.validate = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    if (get("sectionSelect"))
      get("trSection").style.display = $v("sectionSelect") == "" ? "" : "none";
    get("buttonOK").disabled =
      ((!get("sectionSelect") || $v("sectionSelect") == "") &&
        !oValidate.isSet("section")) ||
      !oValidate.isSet("commentary");
  };

  oProjectCommentary.insert = function (control) {
    var codemirror = document.getElementById("commentary").simplemde.codemirror;
    var pos = codemirror.getCursor();
    codemirror.setSelection(pos, pos);
    codemirror.replaceSelection(control.value + "\n");
    control.selectedIndex = 0;
  };

  //-----------------------------------------------------------------------------Vulnerability List Combination
  function oVulnerabilityListCombination() {
  }

  oVulnerabilityListCombination.validate = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    get("buttonOK").disabled = !oValidate.isSet("vulnerabilityId");
  };

  //-----------------------------------------------------------------------------Screenshot Uploads
  function oVulnerabilityHistory() {
  }

  oVulnerabilityHistory.validate = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    get("buttonOK").disabled =
      getV("upload").search(new RegExp(/^([A-Za-z0-9 \./\\:]+)\.(png|jpg)$/)) ==
      -1;
  };

  oVulnerabilityHistory.validateImage = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    get("buttonOK").disabled =
      get("upload").value.search(
        new RegExp(
          /^([a-zA-Z_\. 0-9/\\:-]+).(png|PNG|jpg|JPG|jpeg|JPEG|gif|GIF)$/
        )
      ) == -1;
  };

  oVulnerabilityHistory.uploaded = function () {
    var pane = get("pane").value;
    popup.hide(true);
    tabs.reloadNonIndependent();
    tabs.select(pane);
  };

  //Also applies to findings
  oVulnerabilityHistory.scroll = function (direction) {
    if (
      (popup.data.index == 0 && direction == -1) ||
      (popup.data.index == popup.data.images.length - 1 && direction == 1)
    )
      return;
    popup.data.index += direction;
    get("historyImage").src =
      popup.data.path +
      popup.data.images[popup.data.index].key +
      "?csrf=" +
      getLocalToken();
    get("historyImageCount").innerHTML =
      popup.data.index + 1 + " of " + popup.data.images.length;
    get("aVulnerabilityHistoryImage").href = get("historyImage").src;
    get("vulnerabilityHistoryImageKey").value =
      popup.data.images[popup.data.index].key;
    get("extension").value = popup.data.images[popup.data.index].extension;
    if (get("findingVulnerabilityKey"))
      get("findingVulnerabilityKey").value =
        popup.data.images[popup.data.index].findingVulnerabilityKey;
    if (get("findingVulnerabilityImageKey"))
      get("findingVulnerabilityImageKey").value =
        popup.data.images[popup.data.index].findingVulnerabilityImageKey;
  };

  //-----------------------------------------------------------------------------File Uploads
  function oUpload() {
  }

  oUpload.upload = function (ev: Event) {
    ev.preventDefault();
    popup.showLoadingOverlay();
    oUpload.uploadFile();
    return true;
  };

  oUpload.uploadFile = function () {
    uploadFileWithForm('formPopup')
      .then((res: UploadFileResponseBody) => {
        popup.hide(true);
        tabs.reloadNonIndependent();
        const pane = res.pane.split(',')[0];
        tabs.select(pane);
      }, (err: string) => {
        if (err) popup.setResponse(err);
      });
  }

  oUpload.uploaded = function (pane, error) {
    if (error) popup.setResponse(error);
    else {
      popup.hide(true);
      tabs.reloadNonIndependent();
      tabs.select(pane);
    }
  };

  oUpload.uploadAndNotify = function (ev: Event) {
    ev.preventDefault();
    get("divUploadProgress").innerHTML = "Uploading... &nbsp;&nbsp;";
    get("buttonOK").disabled = true;
    get("buttonCancel").disabled = true;

    uploadFileWithForm('formPopup')
      .then((res: UploadFileResponseBody) => {
        oUpload.uploadedAndNotify(res.result);
      }, (err: any) => {
        if (err) popup.setResponse(err);
      });

    return true;
  };

  oUpload.uploadedAndNotify = function (result) {
    if (get("upload")) {
      var msg = "";
      if (result == "SUCCESS") {
        msg = "<p>Upload Complete.</p><p>The file will be imported soon.</p>";
      }
      else {
        if (result == "UNKNOWN_TYPE") {
          msg =
            '<p class="warning serious">Sorry, the file you tried to upload is not a recognised XML file type.</p>';
        }
        else if (result == "FAILED") {
          msg =
            '<p class="warning serious">Sorry, an error has occurred.</p>' +
            "<p>Check the upload file and try again,</p><p>If the problem persists then please create a new support ticket.</p>";
        }
        else {
          msg =
            '<p class="warning serious">Sorry, an error has occurred.</p><p>An administrator has been notified and will investigate.</p>';
        }
      }
      get("popupContent").innerHTML =
        "<div>" +
        msg +
        "</div>" +
        '<div class="popupFooter"><input type="submit" id="buttonOK" value="OK" class="buttonOK" onclick="popup.hide(true);tabs.reloadNonIndependent();' +
        'tabs.tab.menu.breadCrumb.select({});return false;"></div>';
    }
  };

  //-----------------------------------------------------------------------------Features
  function oFeature() {
  }

  oFeature.validate = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;

    get("buttonOK").disabled =
      getV("featureType") == "" ||
      getV("featureSummary") == "" ||
      //(getV('featureEstimate')!='' &&
      getV("featureEstimate").search(new RegExp(/^([0-9]*)$/)) == -1;
  };

  //-----------------------------------------------------------------------------Document Library
  function oDocumentLibrary() {
  }

  oDocumentLibrary.uploaded = function (result) {
    oUpload.uploaded();
    tabs.breadCrumb.reload = true;
    tabs.breadCrumb.select({ "expand_Client Documents|": true });
  };

  //-----------------------------------------------------------------------------Questionnaire
  function oQuestionnaire() {
  }

  oQuestionnaire.exportTemplate = function (questionnaireKey) {
    window.open(
      "restful/v1/forms/" +
      questionnaireKey +
      "/export?csrf=" +
      getLocalToken() +
      "&contentType=application/octet-stream",
      "_blank"
    );
  };

  oQuestionnaire.importTemplate = function (standardKey) {
    //    var file = getV('upload');
    var $popup = $("<div/>");
    var $form = $("<form/>").attr({
      action: "javascript:;",
      enctype: "multipart/form-data",
      method: "post",
    });
    var $file = $('<input type="file" id="file" name="file"/>');
    var $progress = $("<div/>");
    $popup.append($form);
    $form.append($file);
    $popup.append($progress);
    var csrf = getLocalToken();
    var importing = false;

    $popup.dialog({
      title: "Import Form Template",
      autoOpen: true,
      closeOnEscape: true,
      width: 400,
      height: 200,
      modal: true,
      buttons: {
        Upload: function () {
          if (importing) return;
          importing = true;
          var self = this;
          $progress.html("Importing Form Template, Please Wait ...");
          $form.submit(function (event) {
            jQuery.ajax({
              url: "restful/v1/forms?standardKey=" + standardKey,
              data: new FormData(this),
              cache: false,
              // headers: { "X-csrf": csrf },
              contentType: false,
              processData: false,
              type: "POST",
              success: function (data) {
                $(self).dialog("close");
                oQuestionnaire.notify("Form Template Import Complete.");
                // reloads templates on import success.
                tabs.tab.menu.breadCrumb.subTab.reload = true;
                tabs.tab.menu.breadCrumb.subTab.select();
              },
              error: function (data) {
                $.ajaxSettings.error(data);
                var responseText = JSON.parse(data.responseText);
                $(self).dialog("close");
                oQuestionnaire.notify(
                  responseText.message,
                  "Error Importing Form Template"
                );
              },
            });
          });
          $form.submit();
        },
        Cancel: function () {
          $(this).dialog("close");
        },
      },
      close: function () {
        $(this).dialog("destroy").remove();
      },
    });
  };

  oQuestionnaire.previewImportJsonData = function (
    questionnaireKey,
    csrf,
    preview
  ) {
    var $popup = $("<div/>");
    var $form = $("<form/>")
      .attr({
        action: "javascript:;",
        enctype: "multipart/form-data",
        method: "post",
      })
      .css({ width: "100%" });
    var $file = $('<input type="file" id="file" name="file"/>').css({
      width: "100%",
    });
    var $progress = $("<div/>");
    var manualPreview;
    $popup.append($form);
    $form.append($file);
    $popup.append($progress);
    var importing = false;

    function uiPreview(response) {
      $("#manualButton").hide();
      $("#submitButton").hide();
      $("#manualPreviewButton").hide();
      $("#previewButton").hide();
      $("#uploadButton").show();
      $("#modifyButton").show();

      $(".ui-dialog-content").children().hide();
      $("#encryptedSourceQuestionnaireKey").hide();
      var json = response;

      var table = $("#jsonImportTable");
      var tableAlreadyExists = table && table.length > 0;
      if (!tableAlreadyExists) {
        $(".ui-dialog-content").append(
          $("<div class='scroll'/>").css({ "max-height": "699px" })
        );
        $(".ui-dialog-content > .scroll").append(
          $("<table role='presentation' id='jsonImportTable' border='1'/>").css({
            height: 300,
            width: 1200,
          })
        );
        table = $("#jsonImportTable");
        table.append(
          "<thead><tr><th>Source</th><th>Destination</th><th>Value</th></tr></thead>"
        );
        table.append("<tbody id=jsonImportTableTbody></tbody>");
      }
      table.show();
      table.parent().show();

      var tbody = $("#jsonImportTableTbody");
      if (tableAlreadyExists) {
        tbody.empty();
      }

      for (var i = 0; i < json.mappedSections.length; i++) {
        for (
          var j = 0;
          j < json.mappedSections[i].mappedResponses.length;
          j++
        ) {
          if (json.mappedSections[i].destinationSection != null) {
            var sourceSection =
              json.mappedSections[i].sourceSectionIdentifiers[0].title +
              " (Key: " +
              json.mappedSections[i].sourceSectionIdentifiers[0].templateKey +
              ")" +
              " : " +
              json.mappedSections[i].mappedResponses[j].sourceResponse
                .identifiers[0].title +
              " (" +
              json.mappedSections[i].mappedResponses[j].sourceResponse
                .identifiers[0].type +
              " Response, Key: " +
              json.mappedSections[i].mappedResponses[j].sourceResponse
                .identifiers[0].templateKey +
              ")";
            var destinationSection =
              json.mappedSections[i].destinationSection.title +
              " (Key: " +
              json.mappedSections[i].destinationSection.sectionKey +
              ")" +
              " : " +
              json.mappedSections[i].mappedResponses[j].destinationResponse
                .title +
              " (" +
              json.mappedSections[i].mappedResponses[j].destinationResponse
                .responseType +
              " Response, Key: " +
              json.mappedSections[i].mappedResponses[j].destinationResponse
                .responseKey +
              ")";
            var destinationValue =
              json.mappedSections[i].mappedResponses[j].value;
            tbody.append(
              "<tr><td>" +
              sourceSection +
              "</td><td>" +
              destinationSection +
              "</td><td>" +
              destinationValue +
              "</td></tr>"
            );
          }
        }
      }

      if (document.getElementById("logImportHeader") != null) {
        $("#logImportHeader").show();
        $("#logImportText").show();
      }
      else {
        $(".ui-dialog-content > .scroll").append(
          $("<h2 id='logImportHeader'>Log:</h2>").css({
            fontWeight: "bold",
            marginTop: 20,
          })
        );
        $(".ui-dialog-content > .scroll").append(
          $("<textArea id='logImportText'></textArea>").css({
            height: 100,
            width: 1200,
          })
        );
      }

      for (var i = 0; i < json.log.length; i++) {
        $("#logImportText").append(json.log[i] + "&#13;&#10;");
      }

      //Reposition and Resize the Popup
      $(".ui-dialog").position({ my: "center", at: "center", of: window });

      // menu.refreshData();
      oJavascript.modifyElementsAttribute(
        [
          "uploadButton",
          "modifyButton",
          "manualPreviewButton",
          "submitButton",
          "previewButton",
        ],
        "disabled",
        false
      );
    }

    function uiModify(data) {
      $("#uploadButton").hide();
      $("#modifyButton").hide();
      $("#submitButton").hide();
      $("#manualButton").hide();
      $("#previewButton").show();

      //Hide the HTML Elements in the Popup and add the results of the API Call
      $(".ui-dialog-content").children().hide();

      if (document.getElementById("sourceTextboxJSONImport") != null) {
        $("#sourceTextboxJSONImport").show();
      }
      else {
        $(".ui-dialog-content").append(
          $("<textArea id='sourceTextboxJSONImport'/>").css({
            height: 375,
            width: 500,
          })
        );
      }

      if (data != undefined) {
        try {
          $("#sourceTextboxJSONImport").text(JSON.stringify(data, null, "\t"));
        } catch (e) {
          console.log(data);
        }
      }

      //Reposition and Resize the Popup
      $(".ui-dialog").position({ my: "center", at: "center", of: window });

      //Reactivate Buttons
      oJavascript.modifyElementsAttribute(
        [
          "uploadButton",
          "modifyButton",
          "manualPreviewButton",
          "submitButton",
          "previewButton",
        ],
        "disabled",
        false
      );
    }

    function sendToServer(eventContext, isPreview, onSuccess) {
      oJavascript.modifyElementsAttribute(
        allButtonsButCancel,
        "disabled",
        true
      );
      $form.unbind();
      var ajaxObj = {
        cache: false,
        // headers: { "X-csrf": csrf },
        processData: false,
        type: "POST",
        success: function (response) {
          onSuccess(response, eventContext);
        },
        error: function (data) {
          $.ajaxSettings.error(data);
          $("#sourceTextboxJSONImport").hide();
          $(eventContext).dialog("close");
          oQuestionnaire.notify("Error Data Form Template");
        },
      };
      if (document.getElementById("sourceTextboxJSONImport") != null) {
        if (
          !oValidate.isJSON($("#sourceTextboxJSONImport").val()) ||
          $("#sourceTextboxJSONImport").val() == ""
        ) {
          alert("There is an error in your JSON.");
          return;
        }
        var json = JSON.parse($("#sourceTextboxJSONImport").val());
        $form.submit(function (event) {
          ajaxObj.url =
            "restful/v1/forms/" + questionnaireKey + "/" + isPreview + "/data";
          ajaxObj.data = JSON.stringify(json);
          ajaxObj.contentType = "application/json";
          jQuery.ajax(ajaxObj);
        });
      }
      else {
        $form.submit(function (event) {
          ajaxObj.url =
            "restful/v1/forms/" + questionnaireKey + "/data/" + isPreview;
          ajaxObj.data = new FormData(this);
          ajaxObj.contentType = false;
          jQuery.ajax(ajaxObj);
        });
      }
      $form.submit();
    }

    var allButtonsButCancel = [
      "uploadButton",
      "manualButton",
      "modifyButton",
      "submitButton",
      "previewButton",
    ];

    $popup.dialog({
      title: "Import Form Data",
      autoOpen: true,
      closeOnEscape: true,
      width: "auto",
      height: "auto",
      modal: true,
      open: function () {
        $("#uploadButton").hide();
        $("#modifyButton").hide();
        $("#submitButton").hide();
        $("#manualButton").show();
      },
      buttons: {
        Upload: {
          text: "Import",
          id: "uploadButton",
          click: function () {
            $progress.html("Importing Form Template, Please Wait ...");
            if (importing) return;
            importing = true;
            sendToServer(this, false, function (data, context) {
              $("#uploadButton").hide();
              $("#modifyButton").hide();
              $("#submitButton").hide();
              $("#previewButton").hide();

              $("#sourceTextboxJSONImport").hide();
              $(context).dialog("close");
              oQuestionnaire.notify("Form Data Import Complete.");
              // reloads templates on import success.
              tabs.tab.menu.breadCrumb.subTab.reload = true;
              tabs.tab.menu.breadCrumb.subTab.select();
              oJavascript.modifyElementsAttribute(
                [
                  "uploadButton",
                  "modifyButton",
                  "manualPreviewButton",
                  "submitButton",
                  "previewButton",
                ],
                "disabled",
                false
              );
            });
          },
        },
        Manual: {
          text: "Manual",
          id: "manualButton",
          click: function () {
            uiModify();
          },
        },
        Modify: {
          text: "Modify",
          id: "modifyButton",
          click: function () {
            oJavascript.modifyElementsAttribute(
              allButtonsButCancel,
              "disabled",
              true
            );
            console.log("Entered Modify");

            if (document.getElementById("sourceTextboxJSONImport") != null) {
              uiModify();
            }
            else {
              $form.unbind();
              $form.submit(function (event) {
                jQuery.ajax({
                  url: "restful/v1/forms/data/extractFile",
                  data: new FormData(this),
                  cache: false,
                  // headers: { "X-csrf": csrf },
                  contentType: false,
                  processData: false,
                  type: "POST",
                  success: function (data) {
                    uiModify(data);
                  },
                  error: function (data) {
                    $.ajaxSettings.error(data);
                    oQuestionnaire.notify("Error Data Form Template");
                  },
                });
              });
              $form.submit();
            }
          },
        },
        Preview: {
          text: "Preview",
          id: "previewButton",
          click: function () {
            oJavascript.modifyElementsAttribute(
              allButtonsButCancel,
              "disabled",
              true
            );
            $progress.html("Previewing Form Template, Please Wait ...");
            sendToServer(this, true, uiPreview);
          },
        },
        Cancel: function () {
          menu.refreshData();
          $(this).dialog("close");
        },
      },
      close: function () {
        $(this).dialog("destroy").remove();
      },
    });
  };

  oQuestionnaire.importJsonData = function (questionnaireKey, csrf) {
    var $popup = $("<div/>");
    var $form = $("<form/>")
      .attr({
        action: "javascript:;",
        enctype: "multipart/form-data",
        method: "post",
      })
      .css({ width: "100%" });
    var $file = $('<input type="file" id="file" name="file"/>').css({
      width: "100%",
    });
    var $progress = $("<div/>");
    $popup.append($form);
    $form.append($file);
    $popup.append($progress);
    var importing = false;

    $popup.dialog({
      title: "Import Form Data",
      autoOpen: true,
      closeOnEscape: true,
      width: 500,
      height: 200,
      modal: true,
      buttons: {
        Upload: function () {
          if (importing) return;
          importing = true;
          var self = this;
          $progress.html("Importing Form Template, Please Wait ...");
          $form.submit(function (event) {
            jQuery.ajax({
              url: "restful/v1/forms/" + questionnaireKey + "/data",
              data: new FormData(this),
              cache: false,
              // headers: { "X-csrf": csrf },
              contentType: false,
              processData: false,
              type: "POST",
              success: function (data) {
                console.log(data);

                // $(self).dialog('close');
                // oQuestionnaire.notify("Form Data Import Complete.");
                // // reloads templates on import success.
                // tabs.tab.menu.breadCrumb.subTab.reload = true;
                // tabs.tab.menu.breadCrumb.subTab.select();
              },
              error: function (data) {
                $.ajaxSettings.error(data);
                $(self).dialog("close");
                oQuestionnaire.notify("Error Data Form Template");
              },
            });
          });
          $form.submit();
        },
        Cancel: function () {
          $(this).dialog("close");
        },
      },
      close: function () {
        $(this).dialog("destroy").remove();
      },
    });
  };

  oQuestionnaire.notify = function (msg, title) {
    var $popDiv = $("<div>" + msg + "</div>");
    $(document.body).append($popDiv);
    $popDiv.dialog({
      title: title,
      autoOpen: true,
      closeOnEscape: true,
      width: "300px",
      modal: true,
      buttons: {
        OK: function () {
          $(this).dialog("close");
        },
      },
      close: function (event, ui) {
        $popDiv.remove();
      },
    });
  };

  //-----------------------------------------------------------------------------Initiative
  function oInitiative() {
  }

  oInitiative.exportTemplatesSelected = function (selectedKey) {
    var selectedKeys = "";
    var sep = "";
    if (selectedKey) {
      selectedKeys = selectedKey;
    }
    else {
      var selectedNodes = $("#hierarchy").jstree("get_selected", false);
      for (var i = 0; i < selectedNodes.length; i++) {
        var n = $("#hierarchy").jstree().get_node(selectedNodes[i]);
        if (n.type == "initiativeExportStandard") {
          selectedKeys = selectedKeys + sep + n.li_attr.standardKey;
          if (sep === "") sep = ",";
        }
      }
    }
    if (selectedKeys !== "") {
      ajax.request(
        {
          popup: {
            entity: "Standard",
            part: "EXPORT_INITIATIVE_TEMPLATES",
            standardKeys: selectedKeys,
            includeRetired: get("includeRetired").checked,
          },
        },
        function (ajax) {
          popup.response(ajax);
        }
      );
    }
    else
      oCompliance.alert(
        "SureCloud:",
        "no template folders selected for export."
      );
  };

  oInitiative.exportTemplates = function (selectedKeys) {
    if (selectedKeys !== "") {
      window.open(
        "restful/v1/standards/export?standardKeys=" +
        selectedKeys +
        "&csrf=" +
        getLocalToken() +
        "&includeRetired=" +
        getV("includeRetired") +
        "&contentType=application/octet-stream",
        "_blank"
      );
    }
    else
      oCompliance.alert(
        "SureCloud:",
        "no template folders selected for export."
      );
  };

  oInitiative.mergeAndExportDocument = function (
    questionnaireKey,
    csrf,
    isTemplate
  ) {
    window.open(
      "restful/v1/library/merge?questionnaireKey=" +
      questionnaireKey +
      "&csrf=" +
      getLocalToken() +
      "&isTemplate=" +
      isTemplate
    );
  };

  oInitiative.exportMergeXml = function (questionnaireKey, csrf, isTemplate) {
    window.open(
      "restful/v1/library/mergeXml?questionnaireKey=" +
      questionnaireKey +
      "&csrf=" +
      getLocalToken() +
      "&isTemplate=" +
      isTemplate
    );
  };

  oInitiative.importTemplate = function (standardKey) {
    //    var file = getV('upload');
    var $popup = $("<div/>");
    var $form = $("<form/>").attr({
      action: "javascript:;",
      enctype: "multipart/form-data",
      method: "post",
    });
    var $file = $('<input type="file" id="file" name="file"/>');
    var $progress = $("<div/>");
    $popup.append($form);
    $form.append($file);
    $popup.append($progress);
    var csrf = getLocalToken();
    var importing = false;

    $popup.dialog({
      title: "Import Template Folder",
      autoOpen: true,
      closeOnEscape: true,
      width: 400,
      height: 200,
      modal: true,
      buttons: {
        Upload: function () {
          $popup.parent().find("button:contains('Upload')").button("disable");
          if (importing) return;
          importing = true;
          var self = this;
          $progress.html("Importing Template Folder, Please Wait ...");
          $form.submit(function (event) {
            jQuery.ajax({
              url: "restful/v1/standards?standardKey=" + standardKey,
              data: new FormData(this),
              cache: false,
              // headers: { "X-csrf": csrf },
              contentType: false,
              processData: false,
              type: "POST",
              success: function (data) {
                $(self).dialog("close");
                oInitiative.notify("Template Folder Import Complete");
                // reloads templates on import success.
                if (tabs.tab.menu.breadCrumb.subTab) {
                  tabs.tab.menu.breadCrumb.subTab.reload = true;
                  tabs.tab.menu.breadCrumb.subTab.select();
                }
                else {
                  tabs.tab.menu.breadCrumb.reload = true;
                  tabs.tab.menu.breadCrumb.select();
                }
              },
              error: function (data) {
                $.ajaxSettings.error(data);
                var responseText = JSON.parse(data.responseText);
                $(self).dialog("close");
                oInitiative.notify(
                  responseText.message,
                  "Error Importing Template Folder"
                );
              },
            });
          });
          $form.submit();
        },
        Cancel: function () {
          $(this).dialog("close");
        },
      },
      close: function () {
        $(this).dialog("destroy").remove();
      },
    });
  };

  oInitiative.notify = function (msg, title) {
    var $popDiv = $("<div>" + msg + "</div>");
    $(document.body).append($popDiv);
    $popDiv.dialog({
      title: title,
      autoOpen: true,
      closeOnEscape: true,
      width: "300px",
      modal: true,
      buttons: {
        OK: function () {
          $(this).dialog("close");
        },
      },
      close: function (event, ui) {
        $popDiv.remove();
      },
    });
  };

  oInitiative.validate = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    if (get("newApplication"))
      get("newApplication").style.display =
        get("application").value === "" ? "" : "none";
    if (get("seedDocumentFileKey"))
      get("seedDocumentFileKey").disabled =
        get("seedQuestionnaireKey") &&
        getVUndefined("seedQuestionnaireKey") != undefined;
    if (get("seedQuestionnaireKey"))
      get("seedQuestionnaireKey").disabled =
        get("seedDocumentFileKey") &&
        getVUndefined("seedDocumentFileKey") != undefined;
    get("buttonOK").disabled = !oValidate.isSet("questionnaire");
  };

  oInitiative.validateAndHideCheckboxes = function (control, event) {
    if (get("newApplication"))
      get("newApplication").style.display =
        get("application").value === "" ? "" : "none";
    if (get("seedDocumentFileKey"))
      get("seedDocumentFileKey").disabled =
        get("seedQuestionnaireKey") &&
        getVUndefined("seedQuestionnaireKey") != undefined;
    if (get("seedQuestionnaireKey"))
      get("seedQuestionnaireKey").disabled =
        get("seedDocumentFileKey") &&
        getVUndefined("seedDocumentFileKey") != undefined;
    get("buttonOK").disabled = !oValidate.isSet("questionnaire");

    //Hide the Checkboxes based on the BASE style and which options should be avaliable for that style.
    var itemOnlyCheckbox = $("#styleItemOnlyCheckbox");
    var sectionOnlyCheckbox = $("#styleSectionOnlyCheckbox");
    var neitherStyleCheckbox = $("#styleNeitherCheckbox");
    var noBorderCheckbox = $("#styleNoBorderCheckbox");
    var removeCellCheckbox = $("#styleRemoveCellCheckbox");
    var alternativeCheckbox = $("#styleAlternate");
    var itemOnlyInner = $("#styleItemOnly");
    var sectionOnlyInner = $("#styleSectionOnly");
    var neitherOnlyInner = $("#styleNoItemSection");
    var noBorderInner = $("#styleNoBorder");
    var removeCellInner = $("#styleRemoveCellBorders");
    var alternativeInner = $("#styleAlternateInsertableColours");

    var hideColumns = $("#hideColumns").val();

    if (hideColumns == "&") {
      console.log("Entered: Item & Section");
      itemOnlyCheckbox.show();
      sectionOnlyCheckbox.show();
      neitherStyleCheckbox.show();
      noBorderCheckbox.hide();
      noBorderInner.prop("checked", false);
      removeCellCheckbox.hide();
      removeCellInner.prop("checked", false);
      alternativeCheckbox.hide();
      alternativeInner.prop("checked", false);
    }
    else if (hideColumns == "F") {
      console.log("Entered: Form Style");
      itemOnlyCheckbox.hide();
      itemOnlyInner.prop("checked", false);
      sectionOnlyCheckbox.hide();
      sectionOnlyInner.prop("checked", false);
      neitherStyleCheckbox.hide();
      neitherOnlyInner.prop("checked", false);
      noBorderCheckbox.hide();
      noBorderInner.prop("checked", false);
      removeCellCheckbox.show();
      alternativeCheckbox.show();
    }
    else if (hideColumns == "L") {
      console.log("Entered: List View");
      itemOnlyCheckbox.show();
      sectionOnlyCheckbox.show();
      neitherStyleCheckbox.hide();
      neitherOnlyInner.prop("checked", false);
      noBorderCheckbox.hide();
      noBorderInner.prop("checked", false);
      removeCellCheckbox.hide().prop("checked", false);
      alternativeCheckbox.hide().prop("checked", false);
    }
  };

  //-----------------------------------------------------------------------------Organisation Domain
  function oOrganisationDomain() {
  }

  oOrganisationDomain.validate = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;

    get("buttonOK").disabled =
      !oValidate.isSet("domain") || !oValidate.isSet("auth0Connector");
  };

  //-----------------------------------------------------------------------------Vulnerability List Reference
  function oVulnerabilityListReference() {
  }

  oVulnerabilityListReference.validate = function (control, event) {
    if (control && !oValidate.validate(control, event)) return;
    get("buttonOK").disabled =
      !oValidate.isSet("reference") || !oValidate.isSet("url");
  };

  //-----------------------------------------------------------------------------Actions
  function oEmailRelay() {
  }

  oEmailRelay.validate = function (control, event) {
    if (!oValidate.validate(control, event)) return;

    var isSmtp = getV("emailMethod") !== "AZURE_GRAPH";
    var isAzureGraph = getV("emailMethod") === "AZURE_GRAPH";

    if (isSmtp) {
      if (
        !oValidate.isSet("host") &&
        !oValidate.isSet("port") &&
        !oValidate.isSet("email") &&
        !oValidate.isSet("display") &&
        !oValidate.isSet("password") &&
        !oValidate.isSet("footer") &&
        !get("active").checked
      ) {
        get("buttonOK").disabled = false;
      }
      else {
        get("buttonOK").disabled =
          !(
            oValidate.isIPAddress(getV("host")) ||
            oValidate.isHostname(getV("host"))
          ) ||
          !oValidate.isInteger(getV("port")) ||
          !oValidate.isEmail(getV("email")) ||
          !oValidate.isSet("display") ||
          !oValidate.isSet("password");
      }
    }
    else {
      if (
        !oValidate.isSet("tenantId") &&
        !oValidate.isSet("clientId") &&
        !oValidate.isSet("clientSecret") &&
        !oValidate.isSet("emailUuid") &&
        !oValidate.isSet("display") &&
        !oValidate.isSet("footer") &&
        !get("active").checked
      ) {
        get("buttonOK").disabled = false;
      }
      else {
        get("buttonOK").disabled =
          !oValidate.isSet("tenantId") ||
          !oValidate.isSet("clientId") ||
          !oValidate.isSet("clientSecret") ||
          !oValidate.isSet("emailUuid") ||
          !oValidate.isSet("display");
      }
    }
    oDhtml.setDisplay("trHost", isSmtp);
    oDhtml.setDisplay("trPassword", isSmtp);
    oDhtml.setDisplay("trPort", isSmtp);
    oDhtml.setDisplay("trEmail", isSmtp);
    oDhtml.setDisplay("trTenantId", isAzureGraph);
    oDhtml.setDisplay("trClientId", isAzureGraph);
    oDhtml.setDisplay("trClientSecret", isAzureGraph);
    oDhtml.setDisplay("trEmailUuid", isAzureGraph);
  };

  oEmailRelay.clear = function (control, event) {
    get("active").checked = false;
    get("host").value = "";
    get("port").value = "";
    get("tenantId").value = "";
    get("clientId").value = "";
    get("clientSecret").value = "";
    get("email").value = "";
    get("emailUuid").value = "";
    get("displayAs").value = "";
    get("password").value = "";
    get("footer").value = "";
    get("buttonOK").disabled = false;
  };

  //----------------------------------------------------------------------------- Shortcuts
  function oShortcut() {
  }

  oShortcut.validate = function (control, event) {
    if (!oValidate.validate(control, event)) return;
    var valid = oValidate.isSet("title") && oValidate.isSet("standardKey");
    get("buttonOK").disabled = !valid;
  };

  //-----------------------------------------------------------------------------Form Styling
  function oFormStyle() {
  }

  oFormStyle.validate = function (control, event) {
    if (!oValidate.validate(control, event)) return;
    // colours
    get("backgroundColourExample").style.display =
      oFormStyle.isCustomStyleColour("backgroundColourSelect");
    get("textColourExample").style.display =
      oFormStyle.isCustomStyleColour("textColourSelect");
    get("topBorderColourExample").style.display =
      oFormStyle.isCustomStyleColour("topBorderColourSelect");
    get("rightBorderColourExample").style.display =
      oFormStyle.isCustomStyleColour("rightBorderColourSelect");
    get("bottomBorderColourExample").style.display =
      oFormStyle.isCustomStyleColour("bottomBorderColourSelect");
    get("leftBorderColourExample").style.display =
      oFormStyle.isCustomStyleColour("leftBorderColourSelect");
    // font style
    if (event && event.currentTarget) {
      if (
        event.currentTarget.id === "fontStyleInherit" &&
        event.currentTarget.checked
      ) {
        if (get("fontStyleItalic")) get("fontStyleItalic").checked = false;
        if (get("fontStyleBold")) get("fontStyleBold").checked = false;
        if (get("fontStyleUnderline"))
          get("fontStyleUnderline").checked = false;
        if (get("fontStyleStrikethrough"))
          get("fontStyleStrikethrough").checked = false;
      }
      else if (
        (event.currentTarget.id === "fontStyleItalic" ||
          event.currentTarget.id === "fontStyleBold" ||
          event.currentTarget.id === "fontStyleUnderline" ||
          event.currentTarget.id === "fontStyleStrikethrough") &&
        event.currentTarget.checked
      ) {
        if (get("fontStyleInherit")) get("fontStyleInherit").checked = false;
      }
    }
    get("buttonOK").disabled = false;
  };

  oFormStyle.copyValidate = function (control, event) {
    if (!oValidate.validate(control, event)) return;
    get("buttonOK").disabled = !oValidate.isSet("style");
  };

  oFormStyle.isCustomStyleColour = function (id) {
    return getV(id) && getV(id) === "6 digit hex colour" ? "" : "none";
  };

  oFormStyle.toggleColour = function (elem, event) {
    var e = $.event.fix(event || window.event);
    e.stopPropagation();
    e.preventDefault();
    e.originalEvent.stopPropagation();
    e.originalEvent.preventDefault();
    var colourPickerRow = $(elem).closest("tr").next();
    if (colourPickerRow) {
      colourPickerRow.toggle();
      if (colourPickerRow.is(":visible")) {
        var pickerDiv = colourPickerRow.find("div");
        var pickerInput = colourPickerRow.find("input");
        if (pickerDiv[0].children.length === 0) {
          oOptionList.setColour("FFFF00", pickerDiv, pickerInput, elem);
        }
      }
    }
  };

  //-----------------------------------------------------------------------------Data Migration
  function oDataMigration() {
  }

  oDataMigration.validate = function (control, event) {
    if (!oValidate.validate(control, event)) return;
    let action = $("#responseDataMigrationType :selected").val();
    oDataMigration.loadAction(action ? JSON.parse(action) : {}, false);
  };

  oDataMigration.loadAction = function (action, initialLoad) {
    console.log("loading action for " + action.type);
    let cascade = $("#responseDataMigrationCascade");
    let drillAfterClick = $("#responseDataMigrationDrillAfterClick");
    let drillAfterClickRow = $("#responseDataMigrationDrillAfterClickRow");
    let numberOfClicks = $("#responseDataMigrationNumberOfClicks");
    let numberOfClicksRow = $("#responseDataMigrationNumberOfClicksRow");
    let frameworkId = $("#responseDataMigrationFrameworkId");
    let frameworkIdRow = $("#responseDataMigrationFrameworkIdRow");
    let frameworkVersion = $("#responseDataMigrationFrameworkVersion");
    let frameworkVersionRow = $("#responseDataMigrationFrameworkVersionRow");
    let controlId = $("#responseDataMigrationControlId");
    let controlIdRow = $("#responseDataMigrationControlIdRow");
    let templateKey = $("#templateQuestionnaireKey");
    let templateKeyRow = $("#templateQuestionnaireKeyRow");

    if (action) {
      drillAfterClickRow.hide();
      numberOfClicksRow.hide();
      frameworkIdRow.hide();
      frameworkVersionRow.hide();
      controlIdRow.hide();
      templateKeyRow.hide();
      for (var key in action) {
        if (action.hasOwnProperty(key)) {
          console.log(key + " ->" + action[key]);
          if (key === "cascade") {
            if (initialLoad) cascade.prop("checked", action[key]);
          }
          else if (key === "Drill After Click") {
            if (initialLoad) drillAfterClick.prop("checked", action[key]);
            drillAfterClickRow.show();
            if (drillAfterClick.prop("checked")) {
              templateKeyRow.show();
            }
          }
          else if (key === "Number Of Clicks") {
            if (initialLoad) numberOfClicks.val(action[key]);
            numberOfClicksRow.show();
          }
          else if (key === "Framework ID") {
            if (initialLoad) frameworkId.val(action[key]);
            frameworkIdRow.show();
          }
          else if (key === "Framework Version") {
            if (initialLoad) frameworkVersion.val(action[key]);
            frameworkVersionRow.show();
          }
          else if (key === "Control ID") {
            if (initialLoad) controlId.val(action[key]);
            controlIdRow.show();
          }
        }
      }
    }
  };

  oDataMigration.exportTemplate = function (
    questionnaireKey,
    headersOnly,
    csrf
  ) {
    window.open(
      "restful/v1/forms/migrate/" +
      questionnaireKey +
      "?csrf=" +
      getLocalToken() +
      (headersOnly ? "&headersOnly=" + headersOnly : "")
    );
  };

  oDataMigration.import = function (questionnaireKey, shortcutId, csrf) {
    var $popDiv = $("#dm_import_popup");
    if ($popDiv.length === 0) {
      $popDiv = $('<div id="dm_import_popup"></div>');
      $(document.body).append($popDiv);
    }
    else {
      $popDiv.empty();
    }

    var $file = $('<input type="file" id="file" name="file"/>').attr(
      "style",
      "width: 300px;"
    );
    var $reportOnly = $(
      '<input type="checkbox" id="reportOnly" name="reportOnly"/><label for="reportOnly"><span></span></label>'
    );
    var $progress = $("<div/>");
    var $shortcutId = $(
      '<input type="hidden" id="shortcutId" value="' +
      shortcutId +
      '" name="shortcutId"/>'
    );
    var $form = $("<form/>")
      .attr({
        action: "javascript:;",
        enctype: "multipart/form-data",
        method: "post",
      })
      .append(
        $shortcutId,
        $("<table/><tr/>").append(
          $("<td/>")
            .addClass("popupLabel")
            .text("Select an excel document to import:"),
          $("<td/>").addClass("popupControl").append($file),
          $("<tr/>").append(
            $("<td/>")
              .addClass("popupLabel")
              .text("Report only mode (no data will be imported)"),
            $("<td/>").addClass("popupControl").append($reportOnly),
            $("<tr/>").append(
              $("<td/>").addClass("popupControl").append($progress)
            )
          )
        )
      );
    $popDiv.append($form);
    var importing = false;

    $popDiv.dialog({
      title: "Import From Excel",
      autoOpen: true,
      closeOnEscape: true,
      width: 600,
      height: 230,
      modal: true,
      buttons: {
        Import: function () {
          if (importing) return;
          importing = true;
          var self = this;
          $progress.html("Submitting Import, Please Wait ...");
          $form.submit(function (event) {
            jQuery.ajax({
              url: "restful/v1/forms/migrate/" + questionnaireKey,
              data: new FormData(this),
              cache: false,
              // headers: { "X-csrf": csrf },
              contentType: false,
              processData: false,
              type: "POST",
              success: function (data) {
                $(self).dialog("close");
                oQuestionnaire.notify(
                  "You have successfully submitted an import request.\nYou will be notified via email once the request has been completed.",
                  "Submit Import Request - Success"
                );
              },
              error: function (data) {
                $.ajaxSettings.error(data);
                $(self).dialog("close");
                oQuestionnaire.notify(
                  "There was a problem with your import request.\nCheck that you are submitting a spreadsheet which matches the form you wish to upload.",
                  "Submit Import Request - Failed"
                );
              },
            });
          });
          $form.submit();
        },
        Cancel: function () {
          $(this).dialog("close");
        },
      },
      close: function () {
        $(this).dialog("destroy").remove();
      },
    });
  };

  //----------------------------------------------------------------------------- Associated Template
  function oAssociatedTemplate() {
  }

  oAssociatedTemplate.validate = function (control, event) {
    if (!oValidate.validate(control, event)) return;
    get("buttonOK").disabled = false;
  };

  window.oAssociatedTemplate = oAssociatedTemplate;
  window.oDataMigration = oDataMigration;
  window.oFormStyle = oFormStyle;
  window.oShortcut = oShortcut;
  window.oEmailRelay = oEmailRelay;
  window.oVulnerabilityListReference = oVulnerabilityListReference;
  window.oOrganisationDomain = oOrganisationDomain;
  window.oInitiative = oInitiative;
  window.oQuestionnaire = oQuestionnaire;
  window.oDocumentLibrary = oDocumentLibrary;
  window.oFeature = oFeature;
  window.oUpload = oUpload;
  window.oVulnerabilityHistory = oVulnerabilityHistory;
  window.oVulnerabilityListCombination = oVulnerabilityListCombination;
  window.oProjectCommentary = oProjectCommentary;
  window.oFinding = oFinding;
  window.oRemediationTicket = oRemediationTicket;
  window.oWirelessDrone = oWirelessDrone;
  window.oVulnerability = oVulnerability;
  window.oComplianceVulnerabilityList = oComplianceVulnerabilityList;
  window.oVulnerabilityList = oVulnerabilityList;
  window.oToolSetting = oToolSetting;
  window.oPluginType = oPluginType;
  window.oToolPolicy = oToolPolicy;
  window.oPciScans = oPciScans;
  window.oPci = oPci;
  window.oJob = oJob;
  window.oVerify = oVerify;
  window.oTask = oTask;
  window.oProject = oProject;
  window.oServices = oServices;
  window.oService = oService;
  window.oIPAddress = oIPAddress;
  window.oIPRange = oIPRange;
  window.oAssetGroup = oAssetGroup;
  window.oEvent = oEvent;
  window.oPersonOrganisation = oPersonOrganisation;
  window.oPerson = oPerson;
  window.oOrganisation = oOrganisation;
  window.oAppliance = oAppliance;
  window.oMultipleSelect = oMultipleSelect;
  window.oAction = oAction;
  window.oPropertyBag = oPropertyBag;
  window.oAlertPolicy = oAlertPolicy;
  window.oSettings = oSettings;
  window.oMessageThread = oMessageThread;
};
