import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  ParsedHomeUrl,
  RouteIdents,
  RouteNames,
  StorageKeys
} from "@surecloud/ui-interfaces";
import { makeFullAngularRoute, ScStorage } from "@surecloud/utils";
import { CurrentUserService } from "../services";
import { parseHomeUrl } from "./helpers/parse-home-url";

/**
 * Whereas most facades are async, this one is
 * deliberatley synchronous due to the static nature of the value
 * and where it is used. It may make sense to turn it
 * asynchronous at a later date.
 */

@Injectable({
  providedIn: "root"
})
export class HomeUrlService {
  constructor(
    private currentUserService: CurrentUserService,
    private router: Router
  ) {
  }

  goHome(): void {
    const homeUrl = this.getHomepage();
    this.router.navigateByUrl(homeUrl);
  }

  getHomepage(): string {
    return (
      ScStorage.getItem<string>(StorageKeys.HomeUrl) ||
      this.makeDefaultHomeUrl()
    );
  }

  getParsedHomepage(): ParsedHomeUrl {
    const homeUrl = this.getHomepage();
    return parseHomeUrl(homeUrl);
  }

  setHomepage(resourceId: string): void {
    this.currentUserService.setHomepage(resourceId).subscribe();
    this.storeHomepage(resourceId);
  }

  storeHomepage(resourceId: string): void {
    ScStorage.setItem(StorageKeys.HomeUrl, resourceId);
  }

  clearStoredHomepage(): void {
    this.storeHomepage(this.makeDefaultHomeUrl());
  }

  clearHomepage(): void {
    this.currentUserService.unsetHomepage().subscribe();
    this.clearStoredHomepage();
  }

  clearLocalHomepage(): void {
    ScStorage.removeItem(StorageKeys.HomeUrl);
  }

  /** Tagged with additional queryParam from=home where required to prevent menu highlighting */
  addFromQueryParam(homeUrl: string): string {
    const urlHasQueryString = homeUrl.indexOf("?") > -1;
    return urlHasQueryString ? `${homeUrl}&from=${RouteNames.Home}` : homeUrl;
  }

  private makeDefaultHomeUrl(): string {
    return makeFullAngularRoute(
      `${RouteNames.Overview}/${RouteIdents.Favourites}`
    );
  }
}
