import { getLocalToken } from '@surecloud/utils';

// Wrapper for all fetch requests
export interface RequestConfig {
  url: string;
  method: string;
  body: any;
}

const handleResponse = (response: Response) => {
  return response.text().then(text => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
};

export function fetchRequest<T>(requestConfig: RequestConfig, contentType = 'application/json'): Promise<T> {
  const { url, method, body } = requestConfig;
  const token = getLocalToken();
  const headers: { [key: string]: string } = {
    'X-csrf': token,
  };
  if (contentType) headers['Content-Type'] = contentType;
  return fetch(url, { method, body, headers })
    .then(handleResponse);
}
