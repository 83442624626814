import { AJAX_API, API, CORE_API } from "../url-constants";

export function getSubDomain(): string {
  const testSubdomain = getParameterByName('testSubDomain');
  const host = window.location.host;
  let subdomain = host.split('.')[0];
  subdomain = testSubdomain ? testSubdomain : subdomain;
  subdomain = encodeURIComponent(subdomain)
  return subdomain;
}

export function getParameterByName(name: string, url = '') {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export function getApiUrl(endpoint: string) {
  return `${API}/${endpoint}`;
}

export function getCoreApiUrl(endpoint: string) {
  return `${CORE_API}/${endpoint}`;
}

export function getAjaxApiUrl() {
  return `${AJAX_API}`;
}

// deprecated
export function getApiCallUrl(module: any, action: any, param: any, param2?: any, param3?: any) {
  let url = 'restful/v1';  // was: '/restful/v1'
  if (module) {
    url += '/' + module;
  }
  if (action) {
    url += '/' + action;
  }
  if (param) {
    url += '/' + param;
  }
  if (param2) {
    url += '/' + param2;
  }
  if (param3) {
    url += '/' + param3;
  }

  return url;
}
