import { ActivatedRoute, ActivatedRouteSnapshot, Route, UrlSegment } from "@angular/router";

export function getRouteQueryParam(route: ActivatedRoute, key: string): string {
  const routeParams = route.snapshot.queryParams;
  const param = routeParams[key] || '';
  return param;
}

export function getRouteSnapshotQueryParam(snapshot: ActivatedRouteSnapshot, key: string): string {
  const routeParams = snapshot.queryParams;
  const param = routeParams[key] || '';
  return param;
}

export function getRouteParam(route: ActivatedRoute, key: string): string {
  const routeParams = route.snapshot.params;
  const param = routeParams[key] || '';
  return param;
}

export function getRouteSnapshotParam(snapshot: ActivatedRouteSnapshot, key: string): string {
  const routeParams = snapshot.params;
  const param = routeParams[key] || '';
  return param;
}

export const getAllSegmentsInRoute = (snapshot: ActivatedRouteSnapshot) => {
  let segments: UrlSegment[] = [];
  const getSegments = (snapshot: ActivatedRouteSnapshot): void => {
    segments = segments.concat(snapshot.url);
    if (snapshot.firstChild) {
      getSegments(snapshot.firstChild);
    }
  }
  getSegments(snapshot)
  return segments;
}
