import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthSession } from '@surecloud/api-interfaces';
import { StorageKeys } from '@surecloud/ui-interfaces';
import { LegacyLogout, makeReturnUrlFromCore, ScStorage } from '@surecloud/utils';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AUTH_ENDPOINT, INVALIDATE_ENDPOINT } from '../../url-constants';
import { handleHttpResponse } from '../../utils/http-helpers';
import { HomeUrlService } from '../home-url/home-url.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  legacyLogout$: Observable<void>;
  windowRef: Window;
  hasAlreadyAttemptedInvalidation = false;

  constructor(
    private http: HttpClient,
    private homeUrlService: HomeUrlService,
    @Inject('env') private env: any,
    @Inject(DOCUMENT) private readonly documentRef: Document,
  ) {
    this.windowRef = this.documentRef.defaultView;
    // Connect legacy code to this service.
    const legacyLogout = LegacyLogout.getInstance();
    this.legacyLogout$ = legacyLogout.getLegacyLogout();
    this.legacyLogout$
      .pipe(first())
      .subscribe(() => {
        this.logoutWithReturnUrl();
      });
  }

  login(credentials: string): Observable<AuthSession> {
    return this.http.post<AuthSession>(AUTH_ENDPOINT, credentials, {
      withCredentials: true,
    });
  }

  logout(returnUrlQuery = ''): void {
    this.localLogout();

    // Remove possibility of multiple calls to invalidate page.
    if (this.hasAlreadyAttemptedInvalidation) return;
    const url = `${INVALIDATE_ENDPOINT}${returnUrlQuery}`;
    this.hasAlreadyAttemptedInvalidation = true;
    this.windowRef.location.replace(url);
  }

  logoutWithReturnUrl(): void {
    const { href, origin } = this.windowRef.location;
    const returnUrlQuery = makeReturnUrlFromCore(this.env.production, origin, href);
    this.logout(returnUrlQuery);
  }

  getIsAuthenticated(): Observable<boolean> {
    return this.http.get<HttpResponse<string>>(AUTH_ENDPOINT, { responseType: 'json', observe: 'response' })
      .pipe(
        handleHttpResponse,
        map(isAuthenticated => {
          return isAuthenticated ? true : false;
        })
      );
  }

  getLocalToken(): string | undefined {
    return ScStorage.getItem<string>(StorageKeys.Token) || undefined;
  }

  setLocalToken(token?: string): void {
    if (!token) return;
    return ScStorage.setItem(StorageKeys.Token, token);
  }

  private localLogout() {
    ScStorage.removeItem(StorageKeys.Token);
    ScStorage.removeItem(StorageKeys.OldToken);
    this.homeUrlService.clearLocalHomepage();
  }
}
