// @ts-nocheck
import { browser } from "../modules/oBrowser";

//Draggable object - Requires oBrowser.js
export function oDhtml() {
}

oDhtml.getPosition = function (obj) {
  var position = {
    x: 0,
    y: 0,
    width: obj.offsetWidth,
    height: obj.offsetHeight,
  };
  if (obj.offsetParent) {
    position.x = obj.offsetLeft - obj.scrollLeft;
    position.y = obj.offsetTop - obj.scrollTop;
    while ((obj = obj.offsetParent)) {
      position.x += obj.offsetLeft - obj.scrollLeft;
      position.y += obj.offsetTop - obj.scrollTop;
    }
  }
  return position;
};

oDhtml.getCursor = function (event) {
  var position = new Object();
  if (browser.isIE) {
    position.x =
      window.event.clientX +
      document.documentElement.scrollLeft +
      document.body.scrollLeft;
    position.y =
      window.event.clientY +
      document.documentElement.scrollTop +
      document.body.scrollTop;
  }
  else {
    position.x = event.clientX + window.scrollX;
    position.y = event.clientY + window.scrollY;
  }
  return position;
};

//Sets the position based on another object and some offsets
oDhtml.setPosition = function (obj, otherObj, offsetX, offsetY, inView) {
  var position = oDhtml.getPosition(otherObj),
    x = position.x + (offsetX ? offsetX : 0),
    y = position.y + (offsetY ? offsetY : 0);
  obj.style.left = (x > 0 ? x : 0) + "px";
  obj.style.top = (y > 0 ? y : 0) + "px";
  if (inView) oDhtml.setInView(obj);
};

//Makes sure an object is in view on the screen (used to use document.body.scrollTop)
oDhtml.setInView = function (obj) {
  var screen = browser.getScreenProperties(),
    position = oDhtml.getPosition(obj);
  if (position.x + position.width > screen.view.x + screen.offset.x)
    position.x = screen.view.x + screen.offset.x - position.width - 5;
  if (position.y + position.height > screen.view.y + screen.offset.y - 5)
    position.y = screen.view.y + screen.offset.y - position.height - 5 - 5;
  obj.style.left = (position.x > 0 ? position.x : 0) + "px";
  obj.style.top = (position.y > 0 ? position.y : 0) + "px";
};

//Overlays an object onto another
oDhtml.overlay = function (obj, otherObj) {
  obj.style.left = otherObj.style.left;
  obj.style.top = otherObj.style.top;
  obj.style.width = otherObj.style.width
    ? otherObj.style.width
    : otherObj.offsetWidth + "px";
  obj.style.height = otherObj.style.height
    ? otherObj.style.height
    : otherObj.offsetHeight + "px";
};

//Returns true if node a contains node b. If including is true, return true if a==b
oDhtml.contains = function (a, b, including) {
  if (b == null) return false;
  if (including && a == b) return true;
  while (b.parentNode) if ((b = b.parentNode) == a) return true;
  return false;
};

oDhtml.getDimensions = function (object) {
  var dimensions = new Object();
  dimensions.left = oDhtml.getDimension("left", object);
  dimensions.top = oDhtml.getDimension("top", object);
  dimensions.width = oDhtml.getDimension("width", object);
  dimensions.height = oDhtml.getDimension("height", object);
  return dimensions;
};

oDhtml.getDimension = function (measurement, object) {
  return parseInt(object.style[measurement].replace("px", ""));
};

//Set whether a particular item is displayed or not. If true or false is not passed in that it is toggled from it's current value
oDhtml.setDisplay = function (id, displayed) {
  if (!get(id)) return;
  if (displayed == undefined) displayed = get(id).style.display == "none";
  get(id).style.display = displayed ? "" : "none";
};
