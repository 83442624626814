declare const $: any;

export const TOOLBAR_PAGINATION = 'pagination';
export const TOOLBAR_TOOLS = 'tools';
export const TOOLBAR_ID = 'headerNavToolbar';
export const TOOLBAR_TOOLS_ID = 'headerNavTools';
export const TOOLBAR_PAGINATION_ID = 'headerNavPagination';
export const BREADCRUMB_TOOLS = '$toolsEl';
export const BREADCRUMB_PAGINATE = '$paginateEl';
export const SUBTAB_FORM_DROPDOWN = '$subTabBarFormOptions';
export const APP_SHELL_CONTENT_ID = 'app-shell__content';

export const injectToolbarEl = (breadCrumb: any): void => {
  let $toolsHtml;
  let $paginateHtml;
  let $subTabBarFormOptions;

  if (!breadCrumb) return hideToolbar();

  if (breadCrumb.subTab) {
    $toolsHtml = breadCrumb.subTab[BREADCRUMB_TOOLS];
    $paginateHtml = breadCrumb.subTab[BREADCRUMB_PAGINATE];
    $subTabBarFormOptions = breadCrumb.subTab[SUBTAB_FORM_DROPDOWN];
    if ($toolsHtml && $subTabBarFormOptions) {
      appendSubTabToolbarOptions($toolsHtml, $subTabBarFormOptions);
    }
  }
  else {
    $toolsHtml = breadCrumb[BREADCRUMB_TOOLS];
    $paginateHtml = breadCrumb[BREADCRUMB_PAGINATE];
    $subTabBarFormOptions = breadCrumb[SUBTAB_FORM_DROPDOWN];
    if ($toolsHtml && $subTabBarFormOptions) {
      appendSubTabToolbarOptions($toolsHtml, $subTabBarFormOptions);
    }
  }

  $toolsHtml ? injectToolsEl($toolsHtml) : hideToolbar();
  $paginateHtml ? injectPaginationEl($paginateHtml) : hidePagination();
};

export const appendSubTabToolbarOptions = ($toolsHtml: any, $subTabBarFormOptions: any): any => {
  const $optionsEl = $subTabBarFormOptions.clone(true).removeClass().addClass('sub-header-toolbar__form-menu');
  $toolsHtml
    .find('.subtab-menu.doc-form > ul')
    .replaceWith($optionsEl);
  return $toolsHtml;
};

export const injectToolsEl = ($el: any) => {
  !$el ? hideToolbar() : showToolbar();
  $(`#${TOOLBAR_TOOLS_ID}`).html('').append($el.clone(true));
};

export const injectPaginationEl = ($el: any) => {
  !$el ? hideToolbar() : showToolbar();
  $(`#${TOOLBAR_PAGINATION_ID}`).html('').append($el.clone(true));
  showPagination();
};

// mutates breadCrumb object.
export const saveToolbarState = (breadCrumb: any, toolbarEls: { left: any, right: any }): void => {
  const { left, right } = toolbarEls;
  const selectedBreadCrumb = breadCrumb.subTab || breadCrumb;
  if (!selectedBreadCrumb) return;
  selectedBreadCrumb[BREADCRUMB_TOOLS] = left || undefined;
  selectedBreadCrumb[BREADCRUMB_PAGINATE] = right || undefined;
};

export const updateToolbarState = (breadCrumb: any, toolbarEls: { left: any, right: any }): void => {
  const { left, right } = toolbarEls;
  const selectedBreadCrumb = breadCrumb.subTab || breadCrumb;
  if (!left || !right || !selectedBreadCrumb) return;
  const $left = left.parent().clone(true);
  const $right = right.parent().clone(true);
  selectedBreadCrumb[BREADCRUMB_TOOLS] = $left;
  selectedBreadCrumb[BREADCRUMB_PAGINATE] = $right;
};

export const hidePagination = () => {
  $(`#${TOOLBAR_PAGINATION_ID}`).hide();
};

export const showPagination = () => {
  $(`#${TOOLBAR_PAGINATION_ID}`).show();
};

export const hideToolbar = () => {
  $(`#${TOOLBAR_ID}`).hide();
};

export const showToolbar = () => {
  $(`#${TOOLBAR_ID}`).show();
};

export const setFixedHeaderState = (isActive: boolean): void => {
  const DEFAULT_CLASS = 'fixedheader';
  const SELECTED_CLASS = 'fixedheader-selected';
  const $toolbar = $(`#${TOOLBAR_TOOLS_ID}`);
  if (isActive) {
    $toolbar
      .find(`.${DEFAULT_CLASS}`)
      .removeClass(DEFAULT_CLASS)
      .addClass(SELECTED_CLASS);
    return;
  }
  $toolbar
    .find(`.${SELECTED_CLASS}`)
    .removeClass(SELECTED_CLASS)
    .addClass(DEFAULT_CLASS);
};
