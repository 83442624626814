export interface LoginCredentials {
  email: string;
  password: string;
}

export interface ResetPasswordResponse {
  message: string;
  validEmail: boolean;
}

export enum AuthMethod {
  Surecloud = 'surecloud',
  Auth0 = 'auth0'
}

export interface AuthProfile {
  auth0CallbackUrl?: string;
  auth0ClientId?: string;
  auth0Connector?: string;
  auth0Domain?: string;
  auth0State?: string;
  authenticationMethod: AuthMethod;
  email: string;
}

export interface Auth0Profile extends AuthProfile {
  auth0CallbackUrl: string;
  auth0ClientId: string;
  auth0Connector: string;
  auth0Domain: string;
  auth0State: string;
}

export interface AuthSession {
  expired: boolean;
  token: string;
}

export interface RevealDetail {
  state: RevealState,
  key: string;
}

export enum RevealState {
  Ok = 'OK',
  Permission = 'PERMISSION',
  ExpiredLink = 'EXPIRED',
  Unknown = 'NOT_RECOGNISED',
  Empty = ''
}

export interface User {
  name: string;
  email: string;
  groups: string[];
}

export interface EmailAddressVerificationResponse {
  confirmationMessage: string;
}
