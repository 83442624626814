// @ts-nocheck
/* ---------------------------------------- Browser independent functions */
// Determine browser and version.
// UI Project Dependents: oPopup.ts, oDhtml.ts
export function oBrowser() {
  //Find out which Browser is being used
  var ua, s, i;
  this.isIE = false;
  this.isNS = false;
  this.version = null;
  ua = navigator.userAgent;
  s = "MSIE";
  if ((i = ua.indexOf(s)) >= 0) {
    this.isIE = true;
    this.version = parseFloat(ua.substr(i + s.length));
    return;
  }
  s = "Netscape6/";
  if ((i = ua.indexOf(s)) >= 0) {
    this.isNS = true;
    this.version = parseFloat(ua.substr(i + s.length));
    return;
  }
  // Treat any other "Gecko" browser as NS 6.1.
  s = "Gecko";
  if ((i = ua.indexOf(s)) >= 0) {
    this.isNS = true;
    this.version = 6.1;
    return;
  }
}

oBrowser.prototype.reload = function (url, force) {
  if (url == null) window.location.reload(force ? true : false);
  else {
    if (this.isIE) window.location.href = unescape(url);
    else window.location.replace(url);
  }
};

oBrowser.prototype.newWindow = function (url, aWinName) {
  var sOptions =
    "status=yes,menubar=yes,scrollbars=yes,resizable=yes,toolbar=yes,location=yes" +
    ",width=" +
    (screen.availWidth - 10).toString() +
    ",height=" +
    (screen.availHeight - 122).toString() +
    ",screenX=0,screenY=0,left=0,top=0";
  var wOpen = window.open("", aWinName, sOptions);
  wOpen.location = url;
  wOpen.focus();
};

oBrowser.prototype.cancelBubble = function (event) {
  if (this.isIE) {
    window.event.cancelBubble = true;
    window.event.returnValue = false;
  }
  else event.preventDefault();
};

oBrowser.prototype.areCookiesEnabled = function () {
  document.cookie = "__verify=1";
  var supportsCookies =
    document.cookie.length >= 1 && document.cookie.indexOf("__verify=1") !== -1;
  var thePast = new Date(1976, 8, 16);
  document.cookie = "__verify=1;expires=" + thePast.toUTCString();
  return supportsCookies;
};

//------------------------------------------------------------------------ Screen Properties
oBrowser.prototype.getScreenProperties = function () {
  return {
    view: oBrowser.prototype.getViewableDimensions(),
    offset: oBrowser.prototype.getScrollOffsets(),
  };
};

/* 	For a full explanation, see http://www.boutell.com/newfaq/creating/sizeofclientarea.html
              To use the div method, insert this into the page:
              <div id="divSize"	style="width:100%;height:100%;position:fixed;left:0;top:0;visibility:hidden;z-index: -1;"></div>	*/
oBrowser.prototype.getViewableDimensions = function () {
  var div = get("divSize");
  if (div) return { x: div.scrollWidth, y: div.scrollHeight };
  if (this.isIE)
    return { x: document.body.clientWidth, y: document.body.clientHeight };
  return { x: window.innerWidth, y: window.innerHeight }; //Does not include scrollbar width or height
};

oBrowser.prototype.getScrollOffsets = function () {
  var scrOfX = 0,
    scrOfY = 0;
  if (typeof window.pageYOffset == "number")
    return { x: window.pageXOffset, y: window.pageYOffset };
  else if (document.body && document.body.scrollLeft)
    return { x: document.body.scrollLeft, y: document.body.scrollTop };
  else
    return {
      x: document.documentElement.scrollLeft,
      y: document.documentElement.scrollTop,
    };
};

//IE is onmousemove, else mousemove, so remove the first 2 characters
oBrowser.prototype.attachEvent = function (name, f, direction) {
  if (this.isIE) document.attachEvent(name, f);
  else document.addEventListener(name.substring(2), f, direction);
};

oBrowser.prototype.detachEvent = function (name, f, direction) {
  if (this.isIE) document.detachEvent(name, f);
  else document.removeEventListener(name.substring(2), f, direction);
};

oBrowser.getEvent = function (event) {
  return event ? event : window.event;
};

oBrowser.getTarget = function (event) {
  event = oBrowser.getEvent(event);
  return event.target || event.srcElement;
};

oBrowser.getRelatedTarget = function (event) {
  event = oBrowser.getEvent(event);
  return event.relatedTarget || event.toElement;
};

oBrowser.prototype.preventBubble = function (event, stopPropagation) {
  if (this.isIE) {
    window.event.cancelBubble = true;
    window.event.returnValue = false;
  }
  else {
    event.preventDefault();
    if (stopPropagation) event.stopPropagation();
  }
};

oBrowser.focusFirst = function (formId) {
  for (var i = 0; i < get(formId).elements.length; i++) {
    if (
      (get(formId).elements[i].type === "text" ||
        get(formId).elements[i].type === "password" ||
        get(formId).elements[i].type === "textarea") &&
      !get(formId).elements[i].disabled &&
      get(formId).elements[i].style.visibility !== "hidden" &&
      get(formId).elements[i].style.display !== "none"
    ) {
      try {
        get(formId).elements[i].focus();
        get(formId).elements[i].focus();
        return;
      } catch (err) {}
    }
  }
};

oBrowser.getKeyCode = function (event) {
  return event.keyCode
    ? event.keyCode
    : event.which
      ? event.which
      : event.charCode;
};

export const browser = new oBrowser();
