import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenService } from '../../services/token/token.service';
import { getTimezoneOffset } from '../../utils/http-helpers';

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {

  constructor(
    private tokenService: TokenService,
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headers: any = {
      'Content-Type': 'application/json',
      'X-scTOffset': getTimezoneOffset(),
    };
    const localToken = this.tokenService.getLocalToken();
    if (localToken) {
      headers['X-csrf'] = localToken;
    }

    const newRequest = request.clone({
      setHeaders: headers,
    });

    return next.handle(newRequest);
  }

  handleErrorCode(status: number): any {
    switch (status) {
      case 401: {
        return {
          status: 200, data: { hasValidToken: false },
        };
      }
      default: {
        return { status };
      }
    }
  }
}
