import { Injectable } from "@angular/core";
import { Auth0Profile } from "@surecloud/api-interfaces";
import { parseReturnUrlQuery } from "@surecloud/utils";
import * as auth0 from 'auth0-js';

@Injectable({
  providedIn: 'root'
})
export class Auth0Helper {

  constructor() {
  }

  login(loginHint: string, profile: Auth0Profile, returnUrlQuery: string) {
    const callbackUrl = `${profile.auth0CallbackUrl}${returnUrlQuery}`;
    var webAuth = new auth0.WebAuth({
      domain: profile.auth0Domain,
      clientID: profile.auth0ClientId,
      redirectUri: callbackUrl,
      responseType: "code",
      scope: "openid",
    });

    webAuth.authorize({
      connection: profile.auth0Connector,
      login_hint: loginHint,
      state: profile.auth0State,
      scope: "openid user_id profile email",
    });
  }
}
