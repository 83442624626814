import { ParsedHomeUrl } from '@surecloud/ui-interfaces';

export const parseHomeUrl = (homeUrl: string): ParsedHomeUrl => {
  const clean = homeUrl.replace('(show:', '').replace(')', '');
  const [, , routeName, routeParam] = clean.split('/');
  return {
    routeName,
    routeParam,
  };
};
