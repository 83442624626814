import { NgModule } from '@angular/core';
import { SvgIconsModule } from './svg-icon-lib/svg-icons.module';
import * as scIcons from './sc-icons';

@NgModule({
  imports: [
    SvgIconsModule.forRoot({
      sizes: {
        md: '1rem',
      },
      defaultSize: 'md',
      icons: [
        ...Object.values(scIcons),
      ],
    }),
  ],
  exports: [
    SvgIconsModule,
  ],
  declarations: [],
})
export class IconModule {}
