export const RETURN_URL_PARAM = 'returnUrl';

export const makeReturnUrlQuery = (locationHref: string, locationOrigin: string): string => {
  let query = '';
  const urlSplit = locationHref.split(locationOrigin);
  const hasPath = urlSplit.length > 1 || false;
  if (!hasPath) {
    return query;
  }
  const fullpath = urlSplit[1].substring(1);
  if (fullpath.length > 0) {
    query = `?${RETURN_URL_PARAM}=${encodeURIComponent(fullpath)}`;
  }
  return query;
};

export const parseReturnUrlQuery = (locationHref: string, locationOrigin: string): string => {
  const fullpath = locationHref.split(locationOrigin)[1].substring(1);
  return parseReturnUrlFromPath(fullpath);
};

export const parseReturnUrlFromPath = (fullpath: string): string => {
  let query = '';
  if (fullpath.length > 0) {
    const pathSplit = fullpath.split('?');
    let cleanPath = '';
    if (pathSplit.length > 1) {
      cleanPath = pathSplit[1].split('core%2F').join('');
      query = `?${cleanPath}`;
    }
  }
  return query;
};

// make URL for redirect after surecloud login
export const makeReturnUrl = (origin: string, returnPath: string): string => {
  const returnPathSplit = returnPath.split(`?${RETURN_URL_PARAM}=`);
  const returnPathFull = returnPath.length > 0 ?
    `/${decodeURIComponent(returnPathSplit[1])}` :
    '';
  return `${origin}${returnPathFull}`;
};

export const makeReturnUrlFromCore = (isProd: boolean, currentLocationOrigin: string, currentLocationHref: string): string => {
  const origin = isProd ? `${currentLocationOrigin}/core` : currentLocationOrigin;
  return makeReturnUrlQuery(currentLocationHref, origin);
};

export const stripReturnUrlQueryKey = (returnPath: string): string => {
  return returnPath.split(`?${RETURN_URL_PARAM}=`)[1];
};

export const stripOutsideParentheses = (str: string): string => {
  const openParenthesisIndex = str.indexOf('(');
  const closedParenthesisIndex = str.indexOf(')', openParenthesisIndex);
  const result = str.substring(openParenthesisIndex + 1, closedParenthesisIndex);
  return result;
};

export const joinQueryParams = (paramMap: any): string => {
  return Object.keys(paramMap).reduce((prev: string, curr: string, index: number) => {
    return `${prev}${index > 0 ? '&' : ''}${curr}=${paramMap[curr]}`;
  }, '?');
};

export const joinIDPQueryParams = (paramMap: any): string => {
  const keys = Object.keys(paramMap);
  return keys.reduce((prev: string, curr: string) => {
    const suffix = curr === 'path' ? '?' : '&';
    return `${prev}${curr}=${paramMap[curr]}${suffix}`;
  }, '?').slice(0, -1);
};

export const encodeIDPReturnUrl = (returnUrl: string): string => {
  const delimiter = '?path=';
  const parts = returnUrl.split(delimiter);
  const url = parts.length > 1 ? parts[1] : '';
  const encodedUrl = encodeURIComponent(url);
  return `?${RETURN_URL_PARAM}=${encodedUrl}`;
};
