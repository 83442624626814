import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Organisation } from '@surecloud/api-interfaces';
import { isEmpty } from '@surecloud/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getOrgHasBrandingUrl, getOrgLogoUrl, ORGS_CURRENT_ENDPOINT, ORGS_ENDPOINT } from '../../url-constants';
import { getApiUrl, getSubDomain } from '../../utils/url-helpers';

@Injectable({
  providedIn: 'root',
})
export class OrganisationsService {
  constructor(
    private http: HttpClient
  ) {
  }

  getHasLogo(): Observable<any> {
    const logoSrc = this.getLogoSrc();
    return this.http.get<any>(logoSrc)
      .pipe(
        map((responseBody: any) => {
          return responseBody ? isEmpty(responseBody.errorMessage) : true;
        })
      )
  }

  getHasBranding(): Observable<boolean> {
    const subDomain = getSubDomain();
    const url = getOrgHasBrandingUrl(subDomain);
    return this.http.get<boolean>(url);
  }

  getLogoSrc(): string {
    const subDomain = getSubDomain();
    return getOrgLogoUrl(subDomain);
  }

  all(): Observable<Organisation[]> {
    const url = getApiUrl(ORGS_ENDPOINT);
    return this.http.get<Organisation[]>(url);
  }

  getCurrentOrganisation(): Observable<Organisation> {
    const url = getApiUrl(ORGS_CURRENT_ENDPOINT);
    return this.http.get<Organisation>(url);
  }
}
