import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CoreDataModule } from '@surecloud/core-data';
import { ButtonModule, IconModule } from '@surecloud/design';
import { ErrorModule, GlobalErrorHandler } from '@surecloud/error';
import { APP_NAME_TOKEN, AppName } from '@surecloud/ui-interfaces';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { ForgotPassComponent } from './components/forgot-pass/forgot-pass.component';
import { InlineLinksComponent } from './components/inline-links/inline-links.component';
import { ModalComponent } from './components/modal/modal.component';
import { CORE_APP_URL, LOGIN_APP_URL, ROUTE_DETAILS, ROUTE_NAMES } from './constants';
import { AuthErrorComponent } from './containers/auth-error/auth-error.component';
import { LoginComponent } from './containers/login/login.component';
import { LogoutComponent } from './containers/logout/logout.component';
import { AuthGuard } from './guards/auth.guard';
import { IdpLinkGuard } from './guards/idp-link.guard';

@NgModule({
  declarations: [
    AppComponent,
    ForgotPassComponent,
    LoginComponent,
    ModalComponent,
    AuthErrorComponent,
    LogoutComponent,
    InlineLinksComponent,
  ],
  imports: [
    IconModule,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CoreDataModule,
    ErrorModule,
    RouterModule.forRoot([
      {
        path: '',
        pathMatch: 'full',
        redirectTo: ROUTE_NAMES.home,
      },
      {
        path: ROUTE_NAMES.home,
        component: LoginComponent,
        canActivate: [AuthGuard],
      },
      {
        path: ROUTE_NAMES.idpLink,
        component: LoginComponent,
        canActivate: [IdpLinkGuard],
      },
      {
        path: ROUTE_NAMES.logout,
        component: LogoutComponent,
      },
      {
        path: ROUTE_NAMES.authError,
        component: AuthErrorComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${ROUTE_NAMES.reset}/:${ROUTE_DETAILS.reset.params.resetGuid}`,
        loadChildren: () => import('./containers/password-reset/password-reset.module').then(m => m.PasswordResetModule),
      },
      {
        path: `${ROUTE_NAMES.reveal}/:${ROUTE_DETAILS.reveal.params.revealToken}`,
        loadChildren: () => import('./containers/reveal/reveal.module').then(m => m.RevealModule),
      },
      {
        path: '**',
        redirectTo: 'home',
      },
    ]),
    ButtonModule,
  ],
  providers: [
    Title,
    {
      provide: 'CORE_APP_URL',
      useValue: CORE_APP_URL,
    },
    {
      provide: 'LOGIN_APP_URL',
      useValue: LOGIN_APP_URL,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: APP_NAME_TOKEN,
      useValue: AppName.Login,
    },
    {
      provide: 'env',
      useValue: environment,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
