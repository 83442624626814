// @ts-nocheck

export function auroraSectionPathRule() {
}

auroraSectionPathRule.modifySectionPathRule = function (entityType, entityId, responseKey, responseName, pathRule, onSubmit) {
  let $popDiv = $('#modify_section_path_rule_popup');
  if ($popDiv.length === 0) {
    $popDiv = $('<div/>')
      .attr({ id: 'modify_section_path_rule_popup' })
      .css({ width: '100%' });
    $(document.body).append($popDiv);
  }
  else {
    $popDiv.empty();
  }

  // controls
  const instructionSelect = $('<select/>')
    .css({ width: '100%' })
    .val(pathRule.instruction);
  const identifierInput = $('<input/>')
    .css({ width: '100%' })
    .val(pathRule.Identifier);
  const sectionInput = $('<input/>')
    .css({ width: '100%' })
    .val(pathRule.section);
  const titleInput = $('<input/>')
    .css({ width: '100%' })
    .val(pathRule.title);
  const displayInput = $('<input/>')
    .css({ width: '100%' })
    .val(pathRule.display);
  const nthInput = $('<input/>')
    .css({ width: '100%' })
    .val(pathRule.nth);

  const formRow = function (label, control) {
    return $('<tr/>').append(
      $('<td/>').css({ width: '5%', 'padding-right': 20 }).addClass('popupLabel').text(label),
      $('<td/>').addClass('popupControl').append(control));
  };

  // form
  const form =
    $('<div/>').append(
      $('<table/>').css({ width: '100%' })
        .append(
          formRow('Select Instruction:', instructionSelect),
          formRow('Identifier:', identifierInput),
          formRow('Section:', sectionInput),
          formRow('Title:', titleInput),
          formRow('Display:', displayInput),
          formRow('Nth:', nthInput)));

  const loadInstructionsSelect = function (path) {
    jQuery.ajax({
      url: 'restful/v1/auroraMigration/entityMapping/source/response/pathInstructions/' + responseKey,
      contentType: 'application/json',
      success: function (data) {
        if (data) {
          data.forEach(function (instruction) {
            $(instructionSelect).append(
              new Option(
                instruction,
                instruction,
                false,
                path && path.instruction &&
                (path.instruction === instruction))
            );
          });
        }
      },
      error: function (error) {
        $.ajaxSettings.error(error);
      },
    });
  };


  $popDiv.append(form);

  // popup dialog
  $popDiv.dialog({
    title: 'Modify Section Path Rule',
    autoOpen: true,
    closeOnEscape: true,
    width: 500,
    height: 400,
    modal: true,
    buttons: [
      {
        text: 'Submit',
        click: function () {
          if (nthInput.val() && isNaN(nthInput.val())) {
            oCompliance.alert('Validation Error', 'Nth must be a number');
          }
          jQuery.ajax({
            url: 'restful/v1/auroraMigration/' + entityType + '/source/response/pathRule',
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({
              entityId: entityId,
              responseName: responseName,
              order: pathRule.order,
              instruction: instructionSelect.val(),
              identifier: identifierInput.val(),
              title: titleInput.val(),
              section: sectionInput.val(),
              display: displayInput.val(),
              nth: nthInput.val(),
            }),
            success: function () {
              onSubmit();
              $popDiv.dialog('close');
            },
            error: function (error) {
              $.ajaxSettings.error(error);
            },
          });
        },
      },
      {
        text: 'Cancel',
        click: function () {
          $(this).dialog('close');
        },
      },
      {
        text: 'Delete',
        style: 'float:left; background-color: #b9333d; color:white;',
        disabled: true,
        click: function () {
          popup.confirm(
            'Are you sure you want to delete this source response path mapping?',
            'Remove Mapping',
            function () {
              $.ajax('restful/v1/auroraMigration/' + entityType + '/source/response/pathRule/' + entityId + '/' + responseName + '/' + pathRule.order, {
                type: 'DELETE',
              }).always(function (result) {
                $popDiv.dialog('close');
                if (result.status >= 400) {
                  oCompliance.alert(
                    'Sorry, an error has occurred.',
                    'An Error occurred while removing this source response mapping.'
                  );
                }
                else {
                  if (onSubmit) onSubmit();
                  menu.refreshData();
                }
              });
            }
          );
        },
      },
    ],
    open: function () {
      $.ajax('restful/v1/auroraMigration/' + entityType + '/source/response/pathRule/' + entityId + '/' + responseName + '/' + pathRule.order, {
        error: function (result) {
          $.ajaxSettings.error(result);
          if (result.status != 200) {
            oCompliance.alert(
              'Sorry, an error has occurred.', 'An Error occurred while loading a section path rule for this entity source mapping.');
          }
        },
        success: function (data) {
          loadInstructionsSelect(data);
          if (data) {
            identifierInput.val(data.identifier);
            sectionInput.val(data.section);
            titleInput.val(data.title);
            displayInput.val(data.display);
            nthInput.val(data.nth);
            $(':button:contains("Delete")').prop('disabled', false);
          }
        },
      });
    },
    close: function () {
      $popDiv.remove();
    },
  });
}
