export enum PageMenuKeys {
  Download = 'download',
  Rename = 'rename',
  Delete = 'delete',
  Edit = 'edit',
  Publish = 'publishe',
  Unpublish = 'unpublish',
  Email = 'email',
  AddPortlets = 'add-portlets',
  Favourite = 'favourite',
  Unsubscribe = 'unsubscribe',
  DefaultFavourite = 'default-favourite',
  RemoveDefaultFavourite = 'remove-default-favourite',
  AddHomePage = 'add-home-page',
  RemoveHomePage = 'remove-home-page',
}
