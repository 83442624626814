// @ts-nocheck
export function oHover(hoverDivId: string) {
  const el = get(hoverDivId);
  this.divId = hoverDivId;
  this.div = el;
}

oHover.prototype.show = function (html, control, noClick, maxWidth, tooltip) {
  const c = $(control);
  if (maxWidth === undefined) {
    maxWidth = 600;
  }

  const textReader = $('<div>')
    .html(html)
    .addClass('screen-reader-hidden')
    .attr({ 'aria-live': 'polite' });

  c.append(textReader);

  const textArea = $('<div>')
    .html(html)
    .attr({ 'aria-hidden': 'true' })
    .css({
      width: 'auto',
      'max-height': 'min(50vh, 400px)',
      'overflow-y': 'auto',
    });

  const h = $('#' + this.divId)
    .empty()
    .css({
      top: -2000,
      left: 0,
      width: 'auto',
      'max-width': maxWidth,
      display: 'table',
    })
    .append(textArea);

  h.position({
    my: 'left top',
    at: 'right+6 top',
    of: c,
    collision: 'flip fit',
  });
  c.off('mouseleave').mouseleave(function () {
    h.hide();
    textReader.remove();
  });

  c.on('focusout', function () {
    h.hide();
    textReader.remove();
  });

  c.off('click').click(function () {
    c.off('mouseleave');
    c.off('focusout');
    h.off('mouseleave').mouseleave(function () {
      h.hide();
      textReader.remove();
    });
  });
};

oHover.prototype.onfocus = function (html, control, noClick, maxWidth, tooltip) {
  this.show(html, control, noClick, maxWidth, tooltip);
};

oHover.prototype.hide = function () {
  $('#' + this.divId).hide();
};
