import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'login-auth-error',
  templateUrl: './auth-error.component.html',
  styleUrls: ['./auth-error.component.scss']
})
export class AuthErrorComponent implements OnInit {
  constructor(
    private titleService: Title
  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('Authentication Error | SureCloud');
  }

}
