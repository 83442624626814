export enum ModalName {
  ApplicationAdd = '[Modal] Application Add/Edit',
  ApplicationDelete = '[Modal] Application Delete',
  Config = 'config',
  DashboardAdd = 'dashboard_add',
  DashboardAddPortlets = '[Modal] Add Portlets',
  DashboardDelete = '[Modal] Delete Dashboard',
  DashboardEmail = '[Modal] Dashboard Email',
  DashboardFilter = '[Modal] Dashboard Filter',
  DashboardPublish = '[Modal] Dashboard Publish',
  DashboardRename = '[Modal] Rename Dashboard',
  DashboardSubscribe = 'dashboard_subscribe',
  DashboardUnpublish = '[Modal] Dashboard Unpublish',
  DashboardUnsubscribe = '[Modal] Unsubscribe',
  PortletConfig = '[Modal] Portlet Config',
  PortletDelete = '[Modal] Portlet Delete',
  PortletResource = '[Modal] Portlet Resource',
  PortletChartImage = '[Modal] Portlet Chart Image',
  SwitchOrg = '[Modal] Switch Org',
  Notice = '[Modal] Notice',
  UnpublishNotices = '[Modal] Unpublish Notices',
}

export enum ModalEvent {
  ModalOpen = '[Modal Event] Open',
  ModalClose = '[Modal Event] Close',
}
