import { Observable, Subject } from 'rxjs';

export class LegacyLogout {
  private static instance: LegacyLogout;
  private legacyLogout$ = new Subject<void>();

  static getInstance(): LegacyLogout {
    if (!this.instance) {
      this.instance = new LegacyLogout();
    }
    return this.instance;
  }

  logout(): void {
    this.legacyLogout$.next();
  }

  getLegacyLogout(): Observable<void> {
    return this.legacyLogout$.asObservable();
  }
}
