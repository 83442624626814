import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IntegrationPanelMap } from '@surecloud/api-interfaces';
import { Observable, of } from 'rxjs';
import { INTEGRATION_PANELS_ENDPOINT } from '../../url-constants';
import { getApiUrl } from '../../utils/url-helpers';
import { integrationSettingsLoad } from '@surecloud/old-js';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsService {
  panelMap: IntegrationPanelMap;

  constructor(
    private http: HttpClient
  ) {
  }

  all(): Observable<IntegrationPanelMap> {
    const url = getApiUrl(`${INTEGRATION_PANELS_ENDPOINT}`);
    return this.http.get<IntegrationPanelMap>(url);
  }

  getPanelMapPromise(): Promise<IntegrationPanelMap> {
    if (this.panelMap) {
      return of(this.panelMap).toPromise();
    }
    return this.all()
      .toPromise()
      .then((panels: IntegrationPanelMap) => {
        // old world js load .ts file.
        integrationSettingsLoad(panels);
        this.panelMap = panels;
        return panels;
      })
      .catch((err: any) => {
        const panels = {};
        integrationSettingsLoad(panels);
        return panels;
      });
  }

}
