import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppName, APP_NAME_TOKEN } from '@surecloud/ui-interfaces';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorCodeHandler } from './error-code.handler';

@Injectable()
export class GlobalResponseInterceptor implements HttpInterceptor {

  constructor(
    private errorCode: ErrorCodeHandler,
    @Inject(APP_NAME_TOKEN) private appName: string,
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const isCoreApp = this.appName === AppName.Core;
          const responseData = this.errorCode.handle(error, isCoreApp);
          const httpResponse = new HttpResponse(responseData);
          return of(httpResponse);
        })
      );
  }
}
