import { MenuItem } from '@surecloud/api-interfaces';
import { ModalName, RouteNames } from '..';
import { IconConfig } from './design';
import { NavItem } from './navigation';

export interface VersionDetails {
  version: string;
  revision: string;
  branch: string;
}

export interface DropMenuOption {
  label: string;
  id: string;
  icon?: IconConfig;
  iconKey?: string;
  breakAfter?: boolean;
  routeName?: RouteNames;
  modalName?: ModalName;
  orderBy?: number;
}

export interface PageMenuConfig {
  title: string;
  canDelete: boolean;
  canEmail: boolean;
  canDownload: boolean;
  canUnsubscribe: boolean;
  canEdit: boolean;
  isFavourited: boolean;
  isPublished: boolean;
}

export enum OrgMenuOption {
  OrgSettings = 'org-settings',
  MySettings = 'my-settings',
  SwitchOrg = 'switch-org',
  AssignOrg = 'assign-org',
  Logout = 'logout',
}

export interface NavItemMap {
  [key: string]: NavItem;
}

export interface UIMenuItem extends MenuItem {
  config: NavItem;
}

export interface ToolbarOptions {
  links: {
    linkCommands: string[],
    label: string,
  }[]
}

export interface TabsBarOptions {
  tabs: {
    label: string,
    linkCommands: string[],
    active?: boolean,
  }[]
}

export interface BreadcrumbOptions {
  items: string[],
}

export interface ToolbarButtonsOptions {
  buttons: ToolbarButton[],
}

export interface ToolbarButton {
  label: string,
  active: boolean,
  theme: 'subtle-menu' | 'none',
  linkCommands: string[],
}
