// @ts-nocheck
// UI Project Dependents: entity.ts, oTabs.ts
export function oDate() {
  this.dayName = new Array(
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  );
  this.monthName = new Array(
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  );
}

oDate.clone = function (date) {
  var clone = new Date();
  if (date != undefined) clone.setTime(date.getTime());
  return clone;
};

oDate.daysInMonth = function (date) {
  return 32 - new Date(date.getYear(), date.getMonth(), 32).getDate();
};

oDate.prototype.getMonth = function (monthName) {
  for (var i = 0; i < this.monthName.length; i++) {
    if (this.monthName[i].indexOf(monthName) == 0) return i;
  }
};

oDate.getDate = function (dateString, timeString, dateFunctions) {
  var date = new Date();
  date.setDate(dateString.substring(0, dateString.indexOf("-")));
  dateString = dateString.substring(dateString.indexOf("-") + 1);
  var month = dateString.substring(0, dateString.indexOf("-"));
  date.setMonth(dateFunctions.getMonth(month));
  date.setYear(dateString.substring(dateString.indexOf("-") + 1));
  date.setHours(timeString == "" ? 0 : timeString.substring(0, 2));
  date.setMinutes(timeString == "" ? 0 : timeString.substring(3));
  date.setSeconds = 0;
  date.setMilliseconds(0);
  return date;
};

oDate.setDateOnly = function (date) {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

oDate.getMinutesOfDay = function (date) {
  return date.getHours() * 60 + date.getMinutes();
};

export function oCalendar(div, name) {
  this.div = div;
  this.name = name;
  this.dateFunctions = new oDate();
  this.exclusions = new Object();
}

oCalendar.prototype.show = function (event, control, validate) {
  this.date = oDate.clone();
  if (control.options.length == 3) {
    //var date=control.options[2].value;
    //date=date.substring(date.indexOf('-')+1);
    //var month=date.substring(0,date.indexOf('-'));
    //this.date.setMonth(this.dateFunctions.getMonth(month));
    //this.date.setYear(date.substring(date.indexOf('-')+1));
    this.date.setTime(parseInt(control.options[2].value));
  }
  this.setMenuCalendar();
  this.control = control;
  this.validate = validate;

  $.sc_position($(this.div), $(control), 0, 50);
};

oCalendar.prototype.addMonth = function (increment) {
  this.setMonth(this.date.getMonth() + increment);
};

oCalendar.prototype.setMonth = function (month) {
  this.date.setMonth(month);
  this.setMenuCalendar();
};

oCalendar.prototype.addYear = function (increment) {
  this.setYear(this.date.getFullYear() + increment);
};

oCalendar.prototype.setYear = function (year) {
  this.date.setYear(year);
  this.setMenuCalendar();
};

oCalendar.prototype.setMenuCalendar = function () {
  var monthStart = oDate.clone(this.date);
  monthStart.setDate(1);
  var firstMonth = 0,
    lastMonth = 11;
  var html = "";
  var omd = "onmousedown=\"dragStart(event, 'divCalendar')\"";
  html +=
    '<table cellspacing="0px"><tr><td class="calendarHeader" ' + omd + ">";
  html += '<table style="margin: 0 auto;"><tr><td style="padding:0px;">';
  html +=
    '<input type="button" id="dateLeft" style="width:25px" value="<" onclick="' +
    this.name +
    '.addMonth(-1);">';
  html += '</td><td class="calendarMonthName">';
  html +=
    this.dateFunctions.monthName[this.date.getMonth()].substring(0, 3) +
    " " +
    this.date.getFullYear();
  html += '</td><td style="padding:0px;">';
  html +=
    '<input  id="dateRight" type="button" style="width:25px" value=">" onclick="' +
    this.name +
    '.addMonth(1);">';
  html += "</td></tr></table>";
  html += '</td><td class="calendarCross"> ';

  html +=
    '<input id="findCancel" type="button" value=" x " onClick="' +
    this.name +
    '.select()">';
  html +=
    '</td></tr><tr><td class="popupContent" colspan="2" style="padding:0px;"><table style="width:100%;"><tr>';
  for (i = 1; i < 8; i++)
    html +=
      '<td class="calendarDay">' +
      this.dateFunctions.dayName[i == 7 ? 0 : i].substring(0, 1) +
      "</td>";
  html += "</tr><tr>";
  var blanks = monthStart.getDay() - 1;
  if (blanks == -1) blanks = 6;
  if (blanks > 0)
    html += '<td class="calendarDate" colspan="' + blanks + '"></td>';
  var i = blanks,
    row = 0,
    daysInMonth = oDate.daysInMonth(monthStart);
  while (row == 0 || monthStart.getDate() > 1) {
    var lastRow = monthStart.getDate();
    if (row > 0 && i == 0) html += "<tr>";
    html += '<td class="calendarDate">';
    html += '<a class="calendarDate"';
    html +=
      'href="javascript:' +
      this.name +
      ".select(" +
      monthStart.getTime() +
      ')"';
    html += ">";
    html += monthStart.getDate();
    html += "</a>";
    html += "</td>";
    monthStart.setDate(monthStart.getDate() + 1);
    i++;
    if (i == 7) {
      i = 0;
      row++;
      html += "</tr>";
    }
    else if (row > 0 && monthStart.getDate() == 1)
      html += '<td class="calendarDate" colspan="' + (7 - i) + '"></td></tr>';
  }
  html += '<tr><td colspan="7" class="calendarFooter">';
  html += "</td></tr></table></td></tr></table>";
  this.div.innerHTML = html;
};

oCalendar.prototype.select = function (date) {
  this.div.style.display = "none";
  if (date != undefined) {
    if (date == -1) this.date.setTime(new Date().getTime());
    else this.date.setTime(date);
    this.selectFunction(this.date);
  }
};

oCalendar.prototype.selectFunction = function (date) {
  var dateString =
    date.getDate() +
    " " +
    this.dateFunctions.monthName[date.getMonth()].substring(0, 3) +
    " " +
    date.getFullYear();
  var index = this.control.options[1].value == "Select" ? 2 : 1;
  this.control.options[index] = new Option(
    dateString,
    oDate.setDateOnly(date).valueOf(),
    false,
    true
  );
  this.control.previouslySelectedIndex = index;
  if (this.validate) this.validate();
};
