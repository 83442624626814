export interface PasswordResetDetail {
  auth0: boolean;
  setUserId: boolean;
  type: PasswordResetType;
  set: boolean;
}

export enum PasswordResetType {
  Reset = 'RESET',
  Change = 'CHANGE',
  LinkExpired = 'EXPIRED',
  Unknown = 'NOT RECOGNISED'
}

export interface PasswordResetPayload {
  resetToken: string;
  password: string;
  userId: string;
}

export enum PasswordResetResponseType {
  NotMet = 'NOT_MET_REQUIREMENTS',
  Same = 'SAME',
  Duplicate = 'DUPLICATE',
  DuplicateId = 'DUPLICATE_USER_ID',
  Unknown = 'NOT RECOGNISED',
  Empty = ''
}

export interface PasswordResetResponse {
  reset: PasswordResetResponseType;
  exception: string;
}
